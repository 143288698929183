import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {Popconfirm, message, Tooltip } from 'antd';
import { ToastContainer, toast } from 'react-toastify';

function formatDate(strDate) {
  const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  const date = new Date(strDate);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().substr(-2);

  return `${day} ${month} ${year}`;
}


function FlyerTemplates() {
  const { getAccessTokenSilently } = useAuth0();
  const [flyerTemplates, setFlyerTemplates] = useState([]);

  
  const navigate = useNavigate();

  const handleEditClick = (flyerTemplate) => {
    navigate('/flyertemplatemanager', { state: { flyerTemplate: flyerTemplate } });
  };
  
  const handleDeleteClick = async (flyerTemplateId) => {
    const token = await getAccessTokenSilently();
    try {

      const response = await axios.delete(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyertemplates/${flyerTemplateId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if(response.status === 200) {
          // Check if the template being deleted is the same one being edited
          
          toast.success('Template was successfully deleted.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 30000,
            style: {
              background: '#fff',
              color: '#605dba'
            }
          }); 
          // Remove the deleted flyer template from the state
        setFlyerTemplates(prevFlyerTemplates => prevFlyerTemplates.filter(flyerTemplate => flyerTemplate.id !== flyerTemplateId));
      }
  
    }  catch (error) {
      toast.error('Error occurred while deleting the template.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  }

  useEffect(() => {
    const fetchFlyerTemplates = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyertemplates/retailer/777`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }); 
        setFlyerTemplates(response.data);
      } catch (error) {
        console.error('Failed to fetch templates:', error);
      }
    };

    fetchFlyerTemplates();
  }, []);

  return (
    <div className='dashboard-body-wrapper'>
    <ToastContainer />
      <div className='main-column'>
        <div className='div-block'>
        <span className='div-block-title'>All Flyer Templates</span>
          <div className="products-table-div">
            <table>
              <thead>
                <tr>
                  <th>Flyer Template</th>
                  <th>Last Modified</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {flyerTemplates.map(flyerTemplate => (
                  <tr key={flyerTemplate.id}>
                    <td><img className="flyer-template-preview" src={flyerTemplate.preview_image_url} alt="" /> </td>
                    <td>{formatDate(flyerTemplate.updated_at)}</td>
                    <td>
                    <div className="flyer-buttons">
                    <EditOutlined key="edit" onClick={() => handleEditClick(flyerTemplate)} style={{ marginRight: '10px' }} />
                    <Tooltip>
                        <Popconfirm 
                          title="Are you sure to delete this template?" 
                          onConfirm={() => handleDeleteClick(flyerTemplate.id)}
                          onCancel={() => message.error('Delete Cancelled')}
                          okText="Yes" 
                          cancelText="No"
                        >
                          <DeleteOutlined key="delete" />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlyerTemplates;
