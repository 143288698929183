import React from 'react';
import PropTypes from 'prop-types';
import FormInputText from './FormInputText';
import './FormInputEmail.css';

function FormInputEmail({ label, value, onChange, name, width , readOnly}) {
  const isEmailValid = (email) => {
    // Regular expression to validate email
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const inputStyle = {
    width: width,
  };

  const handleChange = (event) => {
    onChange({
      target: {
        value: event.target.value,
        name: event.target.name,
        valid: isEmailValid(event.target.value),
      },
    });
  };

  return (
    <div className="form-input-email" style={{ width }}>
      <label htmlFor={label}>{label}</label>
      <FormInputText
        type="email"
        name={name}
        value={value}
        onChange={handleChange}
        className={isEmailValid(value) ? '' : 'invalid'}
        style={inputStyle}
        readOnly={readOnly}
        required
      />
    </div>
  );
}

FormInputEmail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  width: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default FormInputEmail;
