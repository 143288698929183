import React, { useState, useEffect, useRef } from 'react';
import { Text, Transformer } from 'react-konva';

const TextShape = ({ 
  id,
  x: initialX,
  y: initialY,
  width: initialWidth,
  text: initialText,
  fontFamily,
  fontSize: initialFontSize,
  fontStyle,
  textDecoration, 
  align,
  verticalAlign,
  padding,
  lineHeight,
  wrap,
  ellipsis,
  fill,
  linkedProductNumber,
  linkedProductProperty,
  draggable,
  selected,
  onSelect,
  onChange,
  shadowColor,
  shadowBlur,
  shadowOffsetX,
  shadowOffsetY,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const [text, setText] = useState(initialText);
  const [x, setX] = useState(initialX);
  const [y, setY] = useState(initialY);
  const [width, setWidth] = useState(initialWidth);
  const [fontSize, setFontSize] = useState(initialFontSize);

  useEffect(() => {
    setText(initialText);
    setX(initialX);
    setY(initialY);
    setWidth(initialWidth);
    setFontSize(initialFontSize);
  }, [initialText, initialX, initialY, initialWidth, initialFontSize]);

  const handleDragEnd = (e) => {
    const node = e.target;
    const newX = node.x();
    const newY = node.y();
      
    onChange({
      id: id,
      type: 'text',
      x: newX,
      y: newY,
      text: text,
      width: width,
      fontFamily: fontFamily,
      fontSize: fontSize,
      fontStyle: fontStyle,
      textDecoration: textDecoration,
      align: align,
      verticalAlign: verticalAlign,
      padding: padding,
      lineHeight: lineHeight,
      wrap: wrap,
      ellipsis: ellipsis,
      fill: fill,
      linkedProductNumber: linkedProductNumber,
      linkedProductProperty: linkedProductProperty,
      draggable: draggable,
      shadowColor:shadowColor,
      shadowBlur: shadowBlur,
      shadowOffsetX: shadowOffsetX,
      shadowOffsetY: shadowOffsetY
    });
    setX(newX);
    setY(newY);
  };

  useEffect(() => {
    if (selected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  const handleTransformerChange = () => {
    const node = shapeRef.current;
    const newWidth = Math.max(node.width() * node.scaleX(), 20);
    
    node.scaleX(1);
    node.scaleY(1);

    setWidth(newWidth);

    // Notify parent component of the change
    onChange({
      ...shapeRef.current.attrs,
      width: newWidth,
    });
  };

  return (
    <React.Fragment>
      <Text
        text={text}
        x={x}
        y={y}
        width={width}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontStyle={fontStyle}
        textDecoration={textDecoration}
        align={align}
        verticalAlign={verticalAlign}
        padding={padding}
        lineHeight={lineHeight}
        wrap={wrap}
        ellipsis={ellipsis}
        fill={fill}
        draggable={draggable}
        onClick={onSelect}
        onTap={onSelect}
        onDragEnd={handleDragEnd}
        ref={shapeRef}
        shadowBlur={selected ? 2 : shadowBlur}
        shadowColor={selected ? 'black' : shadowColor}
        shadowOffsetX={shadowOffsetX}
        shadowOffsetY={shadowOffsetY}
      />
      {selected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 20 || newBox.height < 20) {
              return oldBox;
            }
            return newBox;
          }}
          onTransformEnd={handleTransformerChange}
        />
      )}
    </React.Fragment>
  );
};

export default TextShape;
