import React, {useState} from 'react';
import { SketchPicker } from 'react-color';



const TextProperties = ({ shape, onChange }) => {
 const [isColorPickerVisible, setColorPickerVisible] = useState(false);
 const [isShadowColorPickerVisible, setShadowColorPickerVisible] = useState(false);

  // include the properties specific to Text here

  const handleWidthChange = (event) => {
    onChange({ ...shape, width: parseInt(event.target.value) });
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!isColorPickerVisible);
  };

  const toggleShadowColorPicker = () => {
    setShadowColorPickerVisible(!isShadowColorPickerVisible);
  };

  const handleTextChange = (event) => {
    onChange({ ...shape, text: event.target.value });
  };

  const handleFontFamilyChange = (event) => {
    onChange({ ...shape, fontFamily: event.target.value });
  };

  const handleFontSizeChange = (event) => {
    // Ensure minimum font size of 10 (or any other value you find suitable)
    const newFontSize = Math.max(parseInt(event.target.value), 5);
    onChange({ ...shape, fontSize: newFontSize });
};

  const handleFontStyleChange = (event) => {
    onChange({ ...shape, fontStyle: event.target.value });
  };

  const handleTextDecorationChange = (event) => {
    onChange({ ...shape, textDecoration: event.target.value });
  };

  const handleAlignChange = (event) => {
    onChange({ ...shape, align: event.target.value });
  };

  const handleVerticalAlignChange = (event) => {
    onChange({ ...shape, verticalAlign: event.target.value });
  };

  const handlePaddingChange = (event) => {
    onChange({ ...shape, padding: parseInt(event.target.value) });
  };

  const handleLineHeightChange = (event) => {
    onChange({ ...shape, lineHeight: parseInt(event.target.value) });
  };

  const handleWrapChange = (event) => {
    onChange({ ...shape, wrap: event.target.value });
  };

  const handleEllipsisChange = (event) => {
    onChange({ ...shape, ellipsis: event.target.value });
  };

  const handleColorChange = (color) => {
    onChange({ ...shape, fill: color.hex });
  };

  const handleShadowColorChange = (color) => {
    onChange({ ...shape, shadowColor: color.hex });
  };

  const handleShadowBlurChange = (event) => {
    onChange({ ...shape, shadowBlur: parseInt(event.target.value) });
  };

  const handleShadowOffsetXChange = (event) => {
    onChange({ ...shape, shadowOffsetX: parseInt(event.target.value) });
  };

  const handleShadowOffsetYChange = (event) => {
    onChange({ ...shape, shadowOffsetY: parseInt(event.target.value) });
  };
  
  const handleLinkedProductNumberChange = (event) => {
    const newLinkedProductNumber = event.target.value;
    onChange({ ...shape, linkedProductNumber: newLinkedProductNumber.toString() });
  };

  const handleLinkedProductPropertyChange = (event) => {
    const newLinkedProductProperty = event.target.value;
    onChange({ ...shape, linkedProductProperty: newLinkedProductProperty });
  };

  

  return (
    <div className="shape-specific-properties">
      <div className="shape-property">
        <label className="shape-property-label">Text</label>
        <textarea value={shape.text || ''} onChange={handleTextChange} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label" onClick={toggleColorPicker}>Text Color</label>
        {isColorPickerVisible && (
          <SketchPicker color={shape.fill || '#000000'} onChangeComplete={handleColorChange} />
        )}
      </div>
             {/* Shadow Color */}
             <div className="shape-property">
        <label className="shape-property-label" onClick={toggleShadowColorPicker}>Shadow Color</label>
        {isShadowColorPickerVisible && (
          <SketchPicker color={shape.shadowColor || '#000000'} onChangeComplete={handleShadowColorChange} />
        )}
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Width</label>
        <input type="number" min="40" max="1000" value={shape.width || ''} onChange={handleWidthChange}    style={{width: '30px'}}  />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Font Family</label>
        <input type="text" value={shape.fontFamily || ''} onChange={handleFontFamilyChange} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Font Size</label>
        <select 
          value={shape.fontSize || 5} 
          onChange={handleFontSizeChange} 
          style={{width: '60px'}}
        >
          {[...Array(196)].map((_, index) => (
            <option key={index} value={index + 5}>
              {index + 5}
            </option>
          ))}
        </select>
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Font Style</label>
        <input type="text" value={shape.fontStyle || ''} onChange={handleFontStyleChange} />
      </div>  
      <div className="shape-property">
        <label className="shape-property-label">Text Decoration</label>
        <input type="text" value={shape.textDecoration || ''} onChange={handleTextDecorationChange} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Align</label>
        <input type="text" value={shape.align || ''} onChange={handleAlignChange} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Vertical Align</label>
        <input type="text" value={shape.verticalAlign || ''} onChange={handleVerticalAlignChange} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Padding</label>
        <input type="number" value={shape.padding || ''} onChange={handlePaddingChange}   style={{width: '30px'}} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Line Height</label>
        <input type="number" value={shape.lineHeight || ''} onChange={handleLineHeightChange}   style={{width: '30px'}} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Wrap</label>
        <input type="text" value={shape.wrap || ''} onChange={handleWrapChange} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Ellipsis</label>
        <input type="text" value={shape.ellipsis || ''} onChange={handleEllipsisChange} />
      </div>
       {/* Shadow Blur */}
       <div className="shape-property">
        <label className="shape-property-label">Shadow Blur</label>
        <input type="number" value={shape.shadowBlur} onChange={handleShadowBlurChange} style={{width: '60px'}} />
      </div>    
      {/* Shadow Offset X */}
      <div className="shape-property">
        <label className="shape-property-label">Shadow Offset X</label>
        <input type="number" value={shape.shadowOffsetX} onChange={handleShadowOffsetXChange} style={{width: '60px'}} />
      </div>
      {/* Shadow Offset Y */}
      <div className="shape-property">
        <label className="shape-property-label">Shadow Offset Y</label>
        <input type="number" value={shape.shadowOffsetY} onChange={handleShadowOffsetYChange} style={{width: '60px'}} />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Linked Product</label>
        <input type="number" min="1" max="10" value={shape.linkedProductNumber || ''} onChange={handleLinkedProductNumberChange}    style={{width: '30px'}}  />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Linked Property</label>
        <select value={shape.linkedProductProperty || ''} onChange={handleLinkedProductPropertyChange}   style={{width: '150px'}}>
          <option value="product_name">Product Name</option>
          <option value="product_description">Product Description</option>
          <option value="price_unit">Price Unit</option>
          <option value="original_price">Original Price</option>
          <option value="promo_price_dollars">Promo Price Dollar Amount</option>
          <option value="promo_price_cents">Promo Price Cents Amount</option>
          <option value="promo_start_date">Promo Start Date</option>
          <option value="promo_end_date">Promo End Date</option>
          <option value="savings">Savings</option>
          <option value="limited_qty">Limited Quantity</option>
          <option value="promo_type">Promo Type</option>
          <option value="inventory">Inventory</option>
          <option value="category">Category</option>
          <option value="sub_category_1">Sub Category 1</option>
          <option value="sub_category_2">Sub Category 2</option>
          <option value="sub_category_3">Sub Category 3</option>
        </select>
      </div>
    </div>
  );
};

export default TextProperties;
