import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import CellTemplate from '../CellTemplates/CellTemplate';
import RectangleShape from '../Shapes/Rectangle/RectangleShape';
import CircleShape from '../Shapes/Circle/CircleShape';
import ArcShape from '../Shapes/Arc/ArcShape';
import ArrowShape from '../Shapes/Arrow/ArrowShape';
import ImageShape from '../Shapes/Image/ImageShape';
import TextShape from '../Shapes/Text/TextShape';
import LineShape from '../Shapes/Line/LineShape';
import TagShape from '../Shapes/Tag/TagShape';
import StarShape from '../Shapes/Star/StarShape';
import RingShape from '../Shapes/Ring/RingShape';

const CELL_SIZE = 100; // The size of each cell in the grid
const ORIGINAL_TEMPLATE_SIZE = 400; // Original size of the templates


const GridItem = ({index, cell, cellSize, cell_width, cell_height, spanWidth, spanHeight, originalTemplateWidth, originalTemplateHeight, assignTemplate, selectedTemplate, handleCellUpdate, nextProductSlot, updateLayerRefs}) => {
    const [borderStyle, setBorderStyle] = useState('none');
    const [isTemplateAssigned, setIsTemplateAssigned] = useState(false);
    const layerRef = React.useRef();

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      
        accept: ['template', 'product'], // now it can also accept 'product'
        drop: async (item, monitor) => {
          let hasCellBeenUpdated = false;
    
          if (item.type === 'product' && cell && Array.isArray(cell)) {
            // Product is dropped, find and update the appropriate TextShape
            let newCell = [...cell]; // make a copy to avoid mutating the prop directly
            let availableProductSlots = [
              ...new Set(
                newCell
                  .filter(shape => shape.linkedProductProperty) // Only include shapes with linkedProductProperty
                  .map(shape => shape.linkedProductNumber)
              )
            ].sort(); 
  
            // Reset product number if it is not valid anymore
            let newProductNumber;
            let currentProductNumber;
            const [pageNumber, idxNumber] = index.split('-').map(num => parseInt(num));

            if (availableProductSlots.length && availableProductSlots.includes(nextProductSlot[idxNumber].toString())) {
              currentProductNumber = nextProductSlot[idxNumber];
              let currentProductIndex = availableProductSlots.indexOf(nextProductSlot[idxNumber]);
              if (currentProductIndex < availableProductSlots.length - 1) {
                newProductNumber = availableProductSlots[currentProductIndex + 1].toString();
              } else {
                newProductNumber = availableProductSlots[0].toString();
              }
              nextProductSlot[idxNumber] = newProductNumber;
              newCell.forEach((shape, i) => {

                if (shape.type === 'text' && shape.linkedProductNumber && shape.linkedProductNumber.toString() === currentProductNumber.toString()) {
                  if (shape.linkedProductProperty === 'promo_price_dollars') {
                    newCell[i].text = item.promoPrice ? `${Math.floor(item.promoPrice)}` : `${Math.floor(item.originalPrice)}`;
                  } else if (shape.linkedProductProperty === 'promo_price_cents') {
                    let price = item.promoPrice ? item.promoPrice : item.originalPrice;
                    let numPrice = Number(price);
                    if (!isNaN(numPrice)) {
                      let [dollars, cents] = numPrice.toFixed(2).split('.');
                      newCell[i].text = cents;
                    }else {
                      console.error('price is not a number:', price);
                      // Handle this case as needed
                    }
  
                  } else if(shape.linkedProductProperty === 'original_price') {
                    newCell[i].text = item.originalPrice.toString();
                  } else if (shape.linkedProductProperty === 'product_name') {
                    newCell[i].text = item.name;
                  } else if (shape.linkedProductProperty === 'price_unit') {
                    newCell[i].text = item.priceUnit ?  "per " + item.priceUnit : "";
                  } else if (shape.linkedProductProperty === 'product_description') {
                    newCell[i].text = item.productDescription;
                  } else if (shape.linkedProductProperty === 'savings') {
                    newCell[i].text = (item.originalPrice - item.promoPrice).toFixed(2).toString();
                  } else if (shape.linkedProductProperty === 'limited_qty') {
                    if(item.limit_qty) {
                      newCell[i].text = "LIMIT OF " + item.limit_qty.toString();
                    } else {
                      newCell[i].text = "";
                    }
                  } else if (shape.linkedProductProperty === 'promo_type') {
                    if(item.promoType === 'BOGO') {
                      newCell[i].text = "Buy 1 Get 1 Free"
                    } else {
                      newCell[i].text = item.promoType;
                    }
                  }
                }


                if (shape.type === 'image' && shape.linkedProductNumber && shape.linkedProductNumber.toString() === currentProductNumber.toString()) {
                  if (shape.linkedProductProperty === 'cleaned_img_large_src') {
                    const img = new window.Image();
                    img.crossOrigin = 'anonymous';
                    img.src = item.cleanedImgLargeSrc;
                    img.onload = () => {
                      newCell[i].imageObj = img;
                      newCell[i].imageUrl = item.cleanedImgLargeSrc;
                      console.log(newCell[i]);
                      handleCellUpdate(index, newCell);
                      hasCellBeenUpdated = true;
                    };
                    img.onerror = () => {
                      console.error(`Failed to load image for shape ${i}: ${item.cleanedImgLargeSrc}`); // Debugging log
                    };
                  }
                }
                
              });
              // use handleCellUpdate prop to request the cell update in the parent component
            } 

            if(!hasCellBeenUpdated) handleCellUpdate(index, newCell);
          } else if (item.type === 'template') {
            // It's a template, assign it as before
            assignTemplate(item.id);
            setBorderStyle('none');
            setIsTemplateAssigned(true);
          }
        },
        collect: (monitor) => ({
          canDrop: monitor.canDrop(),
          isOver: monitor.isOver(),
        }),
    }));

    // Effect to reset border style when a template is assigned
    useEffect(() => {
        if (isTemplateAssigned) {
            setBorderStyle('none');
        }
    }, [isTemplateAssigned]);

    // Effect to update layer references
    useEffect(() => {
        if (layerRef.current) {
            updateLayerRefs(index, layerRef.current);
        }
    }, [layerRef.current, updateLayerRefs, index]);

    // Function to scale shapes based on cell size
    const scaleShape = (shape, cell_width, cell_height) => {

    const scale_x = cell_width / originalTemplateWidth;
    const scale_y = cell_height / originalTemplateHeight;
    
        const scaledShape = {...shape};
    // For all shapes
    if (shape.x) scaledShape.x *= scale_x;
    if (shape.y) scaledShape.y *= scale_y;

    if (shape.strokeWidth) scaledShape.strokeWidth *= scale_y;

  
    // For shapes with width and height (rectangle, image)
    if  (shape.width) scaledShape.width *= scale_x;
    if (shape.height) scaledShape.height *= scale_y;
  
    // For circular shapes (circle, arc)
    if (shape.radius) scaledShape.radius *= scale_x;

    // For rounded rectangles
    if (shape.cornerRadius) scaledShape.cornerRadius *= scale_x;

    // For arc
    if (shape.innerRadius) scaledShape.innerRadius *= scale_x;
    if (shape.outerRadius) scaledShape.outerRadius *= scale_x;
  
    // For arrow
    if (shape.points) {
      scaledShape.points = shape.points.map(point => point * scale_x);
    }
  
    // For text
    if (shape.fontSize) {
      scaledShape.fontSize *=  scale_x;
    }  
    // New scaling properties for text  
    if (!shape.fontSize && shape.lineHeight) scaledShape.lineHeight *= scale_y;
    if (!shape.fontSize && shape.padding) scaledShape.padding *= scale_x;

    scaledShape.draggable = false;
    return scaledShape;
    };

    return (
        <div 
            className='grid-item'
            ref={drop}
            style={{ display: cell_width === 0 ? 'none' : 'block', width: cell_width, height: cell_height, gridColumn: 'span ' + spanWidth, gridRow: 'span ' + spanHeight, border: borderStyle, backgroundColor: isOver ? '#f0f0f0' : 'white' }}
            key={index}
            onContextMenu={(e) => {
                e.preventDefault();
                assignTemplate(index, selectedTemplate);
            }}
        >
            {cell && (
                <CellTemplate
                    width={cell_width}
                    height={cell_height}
                    ref={layerRef}
                >
                    {cell.map((shape) => {
                        const scaledShape = scaleShape(shape, cell_width, cell_height);

                        switch (shape.type) {
                            case 'rectangle':
                                return <RectangleShape {...scaledShape} key={shape.id} />;
                            case 'circle':
                                return <CircleShape {...scaledShape} key={shape.id} />;
                            case 'arc':
                                return <ArcShape {...scaledShape} key={shape.id} />;
                            case 'arrow':
                                return <ArrowShape {...scaledShape} key={shape.id} />;
                            case 'image':
                              return <ImageShape {...scaledShape} imageObj={shape.imageObj} key={shape.id} />;
                            case 'text':
                                return <TextShape {...scaledShape} key={shape.id} />;
                            case 'line':
                                return <LineShape {...scaledShape} key={shape.id} />;
                            case 'tag':
                                return <TagShape {...scaledShape} key={shape.id} />;
                            case 'star':
                                return <StarShape {...scaledShape} key={shape.id} />;
                            case 'ring':
                                return <RingShape {...scaledShape} key={shape.id} />;
                            default:
                                return null;
                        }
                    })}
                </CellTemplate>
            )}
        </div>
    );
    
};

export default GridItem;