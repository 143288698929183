import React, { useState, useEffect } from 'react';  // Added useEffect
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HomeOutlined, FileOutlined, ShopOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import Sidebar from './Sidebar';
import FormCircularButton from './stories/FormCircularButton';

const ProtectedLayout = ({children}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
    

  let currentNav;
  if (location.pathname.startsWith('/dashboard')) {
    currentNav = "DASHBOARD";
  } else if (location.pathname.startsWith('/products')
  || location.pathname.startsWith('/addproduct')
  || location.pathname.startsWith('/scanproduct')
  || location.pathname.startsWith('/bulkuploadproducts')) {
    currentNav = "PRODUCTS";
  } else if (location.pathname.startsWith('/flyers')
  || location.pathname.startsWith('/addflyer')
  || location.pathname.startsWith('/flyertemplatemanager')
  || location.pathname.startsWith('/flyertemplates')
  || location.pathname.startsWith('/adtemplatemanager')
  || location.pathname.startsWith('/adtemplates')
  ) {
    currentNav = "FLYERS";
  }



  return (
    <div className={`protected-layout ${isMenuOpen ? 'menu-open' : ''}`}>
      {!isMenuOpen && <div className="mobile-top-nav-logo">
          <img src="/logo-no-background.svg"  alt="" className="mobile-top-nav-logo-image"></img>
      </div>}        
      <div className="mobile-logged-in-menu">
        <div className="mobile-menu-icon" onClick={() => setMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? <span style={{color:"white"}}>&#10005;</span> : <span >&#9776;</span>}
        </div> 
      </div>     
      <div className="left-column">
        <Sidebar navItems={currentNav} />
      </div>
      <div className="main-content">
        {children}
      </div>
      <div className="mobile-footer-menu">
        <div className="mobile-footer-menu-option">
          
          <Link to="/dashboard"><HomeOutlined />Home</Link>
        </div>
        <div className="mobile-footer-menu-option">
          <Link to="/flyers"><FileOutlined />Flyers</Link>
        </div>
        <div className="mobile-footer-menu-option">
        <Link to="/addflyer"><FormCircularButton>+</FormCircularButton></Link>
        </div>
        <div className="mobile-footer-menu-option">
          <Link to="/products"><ShopOutlined />Products</Link>
        </div>
        <div className="mobile-footer-menu-option">
          <Link to="/flyertemplates"><AppstoreAddOutlined />Templates</Link>
        </div>
      </div>
    </div>
  );
}

export default ProtectedLayout;
