import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import GridItem from './GridItem';
import FormButton from '../../stories/FormButton';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // for grid layout in jsPDF
import { ToastContainer, toast } from 'react-toastify';
import {Popconfirm, message, Tooltip } from 'antd';

const CELL_SIZE_REDUCTION_FACTOR = 0.25;
const CELL_SIZE = 100; // The size of each cell in the grid

function Flyer({ templateId, createdFlyer, setCreatedFlyer, style }) {
  const { getAccessTokenSilently } = useAuth0();
  const [shapesLayerRefs, setShapesLayerRefs] = useState({});
  const initialUpdateRef = useRef(true);
  const [nextProductSlots, setNextProductSlots] = useState([new Array(16).fill('1')]);
  const navigate = useNavigate();
  const [grids, setGrids] = useState([Array(12).fill(null)]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [gridDimensions, setGridDimensions] = useState(['4x3']);
  const overlay = document.querySelector('.overlay');


  function showOverlay() {
      overlay.style.display = 'flex';
  }

  function hideOverlay() {
      overlay.style.display = 'none';
  }

  // Moved template assignment logic to its own function
  const assignTemplate = async (selectedTemplate) => {
    if (!selectedTemplate) {
      alert('No template selected');
      return;
    }
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/api/flyertemplates/${selectedTemplate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    if (response.data && response.data.data) {
      const flyerTemplateData = JSON.parse(response.data.data);
      if(flyerTemplateData) {

       setGrids(flyerTemplateData || [Array(12).fill(null)])
       setCurrentPageIndex(0);

      // Determine nextProductSlots for each grid_data element
      const nextSlots = flyerTemplateData.map(grid => {
        if (grid.length > 12) {
          return Array(16).fill('1');
        } else {
          return Array(12).fill('1');
        }
      });
      setNextProductSlots(nextSlots);

      const dimensions = flyerTemplateData.map(grid => {
        if (grid.length > 12) {
          return '4x4';
        } else {
          return '4x3';
        }
      });
      setGridDimensions(dimensions);
      }
    } else {
      console.error(`No template found with ID: ${selectedTemplate}`);
      // Handle error accordingly
    }
  };

  useEffect(() => {
    if (templateId) {
        assignTemplate(templateId);
    }
  }, [templateId]);

  useEffect(() => {
    if (initialUpdateRef.current) {
      if(createdFlyer.grid_data){
        setGrids(createdFlyer.grid_data || [Array(12).fill(null)]);
        setCurrentPageIndex(0);

      // Determine nextProductSlots for each grid_data element
      const nextSlots = createdFlyer.grid_data.map(grid => {
        if (grid.length > 12) {
          return Array(16).fill('1');
        } else {
          return Array(12).fill('1');
        }
      });
      setNextProductSlots(nextSlots);

      const dimensions = createdFlyer.grid_data.map(grid => {
        if (grid.length > 12) {
          return '4x4';
        } else {
          return '4x3';
        }
      });
      setGridDimensions(dimensions);
      } else {
        assignTemplate(createdFlyer.template_id);
      }
      initialUpdateRef.current = false;
    }
  }, [createdFlyer]);

  const handleCellUpdate = (index, updatedCell) => {
    setGrids(prevGrids => {
        const [updatePageNumberString, updateIndexString] = index.split('-');
        const updatePageNumber = parseInt(updatePageNumberString);
        const updateIndex = parseInt(updateIndexString);
        const updatedGrids = [...prevGrids];
        const currentGrid = [...updatedGrids[updatePageNumber]];
        currentGrid[updateIndex].content = updatedCell;
        updatedGrids[updatePageNumber] = currentGrid;
        return updatedGrids;
    });
  };

  useEffect(() => {
    setCreatedFlyer(prevFlyer => ({
      ...prevFlyer,
      grid_data: grids
    }));
  }, [grids]);

  const generatePreviewImageBlob = async (shapesLayerRefs, grids, gridDimensions) => {
    // Generate the image data for page 1
    const { imageUrls, fileData } = await generateImageData(shapesLayerRefs);
    
    return new Promise((resolve, reject) => {
      const pageNumber = 0; // Assuming page numbers are string type
      const pageImages = imageUrls.filter(img => img.pageNumber === pageNumber);
  
      const currentPageDimensions = gridDimensions[pageNumber];
      let numCols = currentPageDimensions ? parseInt(currentPageDimensions.split('x')[1]) : 3;
      const pageWidth = 1200;
      const pageHeight = 1600;
      const horizontalMargin = 15;
      const verticalMargin = 20;
  
      const numRows = 4;
      const cellWidth = (pageWidth - 2 * horizontalMargin) / numCols;
      const cellHeight = (pageHeight - 2 * verticalMargin) / numRows;
  
      const canvas = document.createElement('canvas');
      canvas.width = 1200;
      canvas.height = 1600;
      const ctx = canvas.getContext('2d');

  
      const imageLoadPromises = pageImages.map(imageObj => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            let imgWidth, imgHeight;
            if (img.width > img.height) {
              imgWidth = cellWidth * grids[imageObj.pageNumber][imageObj.idxNumber].spanWidth;
              imgHeight = imgWidth / aspectRatio;
            } else {
              imgHeight = cellHeight * grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight;
              imgWidth = imgHeight * aspectRatio;
            }
  
            const img_col = Math.floor(imageObj.idxNumber % numCols);
            const img_row = Math.floor(imageObj.idxNumber / numCols);
            const img_x = img_col * cellWidth + horizontalMargin;
            const img_y = img_row * cellHeight + verticalMargin;

            ctx.drawImage(img, img_x, img_y, imgWidth, imgHeight);
            resolve();
          };
          img.src = URL.createObjectURL(imageObj.blob);
        });
      });
  
      Promise.all(imageLoadPromises).then(() => {
        canvas.toBlob(blob => {
          resolve(blob);
        }, 'image/png');
      }).catch((error) => {
        reject(error);
      });
    });
  };  

  const handleFlyerPreviewImageUpload = (file, fileName) => {
    return new Promise(async (resolve, reject) => {
      const token = await getAccessTokenSilently();
     
      if (!file) {
        resolve(null);
        return;
      }
    
      const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/s3/signed-url?type=template&fileName=${encodeURIComponent(fileName)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const { data: { signedUrl } } = response;
      if (signedUrl) {
        const result = await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type
          }
        });
    
        if (result.status === 200) {
          const imageUrl = signedUrl.split("?")[0];
          resolve(imageUrl); // Resolve the promise with imageUrl
        } else {
          console.error('Error uploading file:', result);
          reject('Error uploading file.');
        }
      } else {
        console.error('Server did not return a signed URL for upload.');
        reject('Server did not return a signed URL for upload.');
      }
    });
  };
  
  const getMultipleSignedUrls = async (files) => {
    const token = await getAccessTokenSilently();

    // Construct the payload as an array of objects.
    const payload = files.map(file => ({
        fileName: file.fileName,
        fileType: file.fileType // 'image/png' or 'application/pdf' etc.
    }));

    const response = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/api/s3/multiple-signed-urls`, {
        files: payload
        }, 
        {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    // Expecting response.data to be an array of {signedUrl, fileName} objects.
    return response.data;
  };
  
  const uploadMultipleToS3 = async (signedUrlsData, files) => {
    const uploadPromises = signedUrlsData.map((data, index) => {
        const blob = files[index].blob;
        return axios.put(data.signedUrl, blob, {
            headers: {
                'Content-Type': blob.type
            }
        }).then(() => {
            return { originalName: files[index].fileName, url: data.signedUrl.split("?")[0] };
        });
    });

    const results = await Promise.all(uploadPromises);
    return results;
  };

  const uploadFiles = (files) => {
    return new Promise(async (resolve, reject) => {
        try {
            const signedUrlsData = await getMultipleSignedUrls(files);
            //const blobs = files.map(file => file.blob);
            const uploadedFilesData = await uploadMultipleToS3(signedUrlsData, files);

            resolve(uploadedFilesData);
            toast.success('Flyer changes have been successfully published.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 30000,
                style: {
                    background: '#fff',
                    color: '#605dba'
                }
            });
        } catch (error) {
            toast.error('Error occurred publishing the flyer.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false,
                style: {
                    background: '#fff',
                    color: '#ff0000'
                }
            });
            reject(error);
        }
    });
  };

  const generateImageData = (shapesLayerRefs) => {
    const imagePromises = [];
    const imageUrls = [];
    const fileData = [];

    for (const [index, stageRef] of Object.entries(shapesLayerRefs)) {
      if (stageRef) {
          const validIndexPattern = /^\d+-\d+$/;
          if (!validIndexPattern.test(index)) {
              console.warn(`Invalid ref index: ${index}`);
              continue;
          }

          const imagePromise = new Promise((resolve, reject) => {
              stageRef.toImage({
                  callback: function(img) {
                      const [pageNumber, idxNumber] = index.split('-').map(num => parseInt(num));
                      const canvas = document.createElement('canvas');
                      canvas.width = img.width;
                      canvas.height = img.width * (grids[pageNumber][idxNumber].dimensions.height / grids[pageNumber][idxNumber].dimensions.width);
                      const ctx = canvas.getContext('2d');
                      ctx.drawImage(img, 0, 0);
                      canvas.toBlob((blob) => {
                          if (!blob) {
                              console.error(`Failed to convert image ${index} to blob`);
                              reject(new Error(`Failed to convert image ${index} to blob`));
                              return;
                          }
                          const url = URL.createObjectURL(blob);
                          const imageObj = {
                              pageNumber: pageNumber,
                              idxNumber: idxNumber,
                              url: url,
                              blob: blob,
                              fileName: `Flyer_${index}.png`,
                              fileType: 'image/png'
                          };
                          imageUrls.push(imageObj);
                          fileData.push(imageObj);
                          resolve(url);
                      }, 'image/png');
                  },
                  error: function(err) {
                      console.error(`Image creation for layer ${index} failed with error:`, err);
                      reject(err);
                  },
                  mimeType: 'image/png',
                  quality: 1,
                  pixelRatio: 7,
              });
          });
          imagePromises.push(imagePromise);
      }
  }

  return Promise.all(imagePromises).then(() => {
      return { imageUrls, fileData };
  });
  };
  
  const saveFlyer = async (callFromSaveButton = true) => {
    if(callFromSaveButton) {
      showOverlay();
    }

    try {

      // Save that image to the server as previewImage
      const previewImageBlob = await generatePreviewImageBlob(shapesLayerRefs, grids, gridDimensions);
      const previewImageFileName = `preview_${createdFlyer.flyer_id}.png`; // Ensure to have a valid ID or name here
      const previewImageUrl = await handleFlyerPreviewImageUpload(previewImageBlob, previewImageFileName);

      // Add the URL to createdFlyer
      createdFlyer.preview_image_url = previewImageUrl;

      // Update the flyer object in the backend
      const token = await getAccessTokenSilently();
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/flyers/${createdFlyer.flyer_id}`,
        JSON.stringify(createdFlyer),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const startDate = new Date(response.data.result.start_date);
      const endDate = new Date(response.data.result.end_date);

      setCreatedFlyer((prevFlyer) => ({
        ...prevFlyer,
        ...response.data.result,
        start_date: startDate,
        end_date: endDate,
      }));

      if(callFromSaveButton) {
        hideOverlay();
        toast.success('Flyer has been successfully updated.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 30000,
          style: {
            background: '#fff',
            color: '#605dba',
          },
        });
      }
    } catch (error) {
      if(callFromSaveButton) {
        hideOverlay();
      }
      console.error('Error occurred while saving the flyer:', error);
      toast.error('Error occurred while saving the flyer.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000',
        },
      });
    }
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2);
    return `${day}_${monthName}_${year}`;
  }

  const downloadFlyer = async () => {
    showOverlay();

    await saveFlyer(false);

    const pdf = new jsPDF('p', 'mm', 'a4');
    const imagePromises = [];

    const { imageUrls, fileData } = await generateImageData(shapesLayerRefs);

    // Generate a unique folder name
    const flyerName = createdFlyer.flyer_title; // Replace with actual flyer name
    const startDate = new Date(createdFlyer.start_date); // Replace with actual start date
    const formattedStartDate = formatDate(startDate);
    const mainFolderName = `${flyerName}_${formattedStartDate}_${Date.now()}`;

    Promise.all(imagePromises)
        .then(() => {
          const horizontalMargin = 15;
          const verticalMargin = 20;
          // const pageWidth = pdf.internal.pageSize.getWidth();
          // const pageHeight = pdf.internal.pageSize.getHeight();
          
          const pageWidth = 210;
          const pageHeight = 280;

          let p = Promise.resolve();
            imageUrls.sort((a, b) => {
                if (a.pageNumber === b.pageNumber) {
                    return a.idxNumber - b.idxNumber;
                }
                return a.pageNumber - b.pageNumber;
            });

            let currentRow = 0;
            let currentCol = 0;
            let currentPage = -1;
            let currentRowMinHeight = 0;

            imageUrls.forEach((imageObj, absoluteIndex) => {
                if (imageObj.pageNumber !== currentPage) {
                    currentPage = imageObj.pageNumber;
                    currentRow = 0;
                    currentCol = 0;
                    currentRowMinHeight = 0;
                }

                const currentPageDimensions = gridDimensions[imageObj.pageNumber];
                const numCols = currentPageDimensions ? parseInt(currentPageDimensions.split('x')[1]) : 3;
                const numRows = 4;
                const cellWidth = (pageWidth - 2 * horizontalMargin) / numCols;
                const cellHeight = (pageHeight - 2 * verticalMargin) / numRows;

              
                p = p.then(() => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = function() {
                            const aspectRatio = this.width / this.height;
                            let imgWidth, imgHeight;

                            if (this.width > this.height) {
                                imgWidth = cellWidth * grids[imageObj.pageNumber][imageObj.idxNumber].spanWidth;
                                imgHeight = imgWidth / aspectRatio;
                            } else {
                                imgHeight = cellHeight * grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight;
                                imgWidth = imgHeight * aspectRatio;
                            }

                            const img_col = Math.floor(imageObj.idxNumber % numCols);
                            const img_row = Math.floor(imageObj.idxNumber / numCols);
                            const img_x = img_col * cellWidth + horizontalMargin;
                            const img_y = img_row * cellHeight + ((1.5) * verticalMargin);


                            pdf.addImage(imageObj.url, 'PNG', img_x, img_y, imgWidth, imgHeight);

                            if (currentCol === 0) {
                                currentRowMinHeight = grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight;
                            } else {
                                currentRowMinHeight = Math.min(currentRowMinHeight, grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight);
                            }
                            currentCol = Math.floor(imageObj.idxNumber % numCols) + grids[imageObj.pageNumber][imageObj.idxNumber].spanWidth;
                            if (currentCol >= numCols) {
                                currentRow += currentRowMinHeight;
                                currentCol = 0;
                            }

                            if (currentRow >= numRows && absoluteIndex !== imageUrls.length - 1) {
                                pdf.addPage();
                                currentRow = 0;
                                currentCol = 0;
                            }

                            resolve();
                        };

                        img.onerror = (errorEvent) => {
                            console.error("Error loading image:", errorEvent);
                            reject(new Error(`Failed to load image at URL: ${imageObj.url}`));
                        };
                        img.src = imageObj.url;
                    });
                });
            });
            return p;
        })
        .then(() => {
            pdf.save(`${mainFolderName}/_for_web/final_flyer.pdf`);
            imageUrls.forEach((imageObj) => URL.revokeObjectURL(imageObj.url));

            const pages = {};
            fileData.forEach(fileObj => {
                if (!pages[fileObj.pageNumber]) {
                    pages[fileObj.pageNumber] = [];
                }
                pages[fileObj.pageNumber].push(fileObj);
            });

            return Promise.all(Object.entries(pages).map(([pageNumber, pageImages]) => {
                return new Promise((resolve) => {
                    pageImages.sort((a, b) => a.idxNumber - b.idxNumber);

                    const currentPageDimensions = gridDimensions[pageNumber];
                    let numCols = currentPageDimensions ? parseInt(currentPageDimensions.split('x')[1]) : 3;

                    const pageWidth = 1200;
                    const pageHeight = 1600;

                    const horizontalMargin = 15;
                    const verticalMargin = 20;

                    const numRows = 4;
                    const cellWidth = (pageWidth - 2 * horizontalMargin) / numCols;
                    const cellHeight = (pageHeight - 2 * verticalMargin) / numRows;

                    const canvas = document.createElement('canvas');
                    canvas.width = 1200;
                    canvas.height = 1600;
                    const ctx = canvas.getContext('2d');

                    let currentRow = 0;
                    let currentCol = 0;
                    let currentRowMinHeight = 0;

                    const imageLoadPromises = pageImages.map(imageObj => {
                        return new Promise((resolve) => {
                            const img = new Image();
                            img.onload = () => {
                                const aspectRatio = img.width / img.height;
                                let imgWidth, imgHeight;
                                if (img.width > img.height) {
                                    imgWidth = cellWidth * grids[imageObj.pageNumber][imageObj.idxNumber].spanWidth;
                                    imgHeight = imgWidth / aspectRatio;
                                } else {
                                    imgHeight = cellHeight * grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight;
                                    imgWidth = imgHeight * aspectRatio;
                                }

                                const img_col = Math.floor(imageObj.idxNumber % numCols);
                                const img_row = Math.floor(imageObj.idxNumber / numCols);
                                const img_x = img_col * cellWidth + horizontalMargin;
                                const img_y = img_row * cellHeight + verticalMargin;
                                
                                ctx.drawImage(img, img_x, img_y, imgWidth, imgHeight);
                                if (currentCol === 0) {
                                    currentRowMinHeight = grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight;
                                } else {
                                    currentRowMinHeight = Math.min(currentRowMinHeight, grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight);
                                }
                                currentCol += grids[imageObj.pageNumber][imageObj.idxNumber].spanWidth;
                                if (currentCol >= numCols) {
                                    currentRow += currentRowMinHeight;
                                    currentCol = 0;
                                }
                                resolve();
                            };
                            img.src = URL.createObjectURL(imageObj.blob);
                        });
                    });

                    Promise.all(imageLoadPromises).then(() => {
                        canvas.toBlob(blob => {
                            const url = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            let printPageNumber = parseInt(pageNumber) + 1;
                            link.download = `${mainFolderName}/_for_social_ads/flyer_page_${(printPageNumber)}.png`;
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            resolve();
                        }, 'image/png');
                    });
                });
            }));
        })
        .then(() => {
            hideOverlay();
            toast.success('All Flyer files have been successfully downloaded.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 30000,
                style: {
                    background: '#fff',
                    color: '#605dba',
                },
            });
        })
        .catch((error) => {
            console.error("Error:", error);
            hideOverlay();
        });
  };

  const publishFlyer = async () => {
    showOverlay();

    await saveFlyer(false);

    const pdf = new jsPDF('p', 'mm', 'a4');
    const imagePromises = [];
    const {imageUrls, fileData } = await generateImageData(shapesLayerRefs);

    Promise.all(imagePromises)
        .then(() => {
            const pages = {};
            fileData.forEach(fileObj => {
                if (!pages[fileObj.pageNumber]) {
                    pages[fileObj.pageNumber] = [];
                }
                pages[fileObj.pageNumber].push(fileObj);
            });

            return Promise.all(Object.entries(pages).map(([pageNumber, pageImages]) => {
                return new Promise((resolve) => {
                    pageImages.sort((a, b) => a.idxNumber - b.idxNumber);

                    const currentPageDimensions = gridDimensions[pageNumber];
                    const numCols = currentPageDimensions ? parseInt(currentPageDimensions.split('x')[1]) : 3;
                    const pageWidth = 1200;
                    const pageHeight = 1600;
                    const horizontalMargin = 15;
                    const verticalMargin = 20;
                    const numRows = 4;
                    const cellWidth = (pageWidth - 2 * horizontalMargin) / numCols;
                    const cellHeight = (pageHeight - 2 * verticalMargin) / numRows;

                    const canvas = document.createElement('canvas');
                    canvas.width = 1200;
                    canvas.height = 1600;
                    const ctx = canvas.getContext('2d');

                    const imageLoadPromises = pageImages.map(imageObj => {
                        return new Promise((resolve) => {
                            const img = new Image();
                            img.onload = () => {
                                const aspectRatio = img.width / img.height;
                                let imgWidth, imgHeight;
                                if (img.width > img.height) {
                                    imgWidth = cellWidth * grids[imageObj.pageNumber][imageObj.idxNumber].spanWidth;
                                    imgHeight = imgWidth / aspectRatio;
                                } else {
                                    imgHeight = cellHeight * grids[imageObj.pageNumber][imageObj.idxNumber].spanHeight;
                                    imgWidth = imgHeight * aspectRatio;
                                }
                                const x = (imageObj.idxNumber % numCols) * cellWidth + horizontalMargin;
                                const y = Math.floor(imageObj.idxNumber / numCols) * cellHeight + verticalMargin;
                                ctx.drawImage(img, x, y, imgWidth, imgHeight);
                                resolve();
                            };
                            img.src = URL.createObjectURL(imageObj.blob);
                        });
                    });

                    Promise.all(imageLoadPromises).then(() => {
                        canvas.toBlob(blob => {
                            fileData.push({
                                blob: blob,
                                fileName: `_for_social_ads/flyer_page_${pageNumber}.png`,
                                fileType: 'image/png'
                            });
                            resolve();
                        }, 'image/png');
                    });
                });
            }));
        })
        .then(() => {
            // Continue with the existing PDF generation and upload logic
            const horizontalMargin = 15;
            const verticalMargin = 20;

            // const pageWidth = pdf.internal.pageSize.getWidth();
            // const pageHeight = pdf.internal.pageSize.getHeight();
        
            const pageWidth = 210;
            const pageHeight = 280;
            
            let p = Promise.resolve();
            fileData.sort((a, b) => {
                if (a.pageNumber === b.pageNumber) {
                    return a.idxNumber - b.idxNumber;
                }
                return a.pageNumber - b.pageNumber;
            });

            let currentRow = 0;
            let currentCol = 0;
            let currentPage = -1;
            let currentRowMinHeight = 0;

            fileData.forEach((fileObj, absoluteIndex) => {
                if (fileObj.fileType === 'image/png' && fileObj.pageNumber !== undefined) {
                    if (fileObj.pageNumber !== currentPage) {
                        currentPage = fileObj.pageNumber;
                        currentRow = 0;
                        currentCol = 0;
                        currentRowMinHeight = 0;
                    }

                    const currentPageDimensions = gridDimensions[fileObj.pageNumber];
                    const numCols = currentPageDimensions ? parseInt(currentPageDimensions.split('x')[1]) : 3;
                    const numRows = 4;
                    const cellWidth = (pageWidth - 2 * horizontalMargin) / numCols;
                    const cellHeight = (pageHeight - 2 * verticalMargin) / numRows;

                    p = p.then(() => {
                        return new Promise((resolve, reject) => {
                            const img = new Image();
                            img.onload = function() {
                                const aspectRatio = this.width / this.height;
                                let imgWidth, imgHeight;

                                if (this.width > this.height) {
                                    imgWidth = cellWidth * grids[fileObj.pageNumber][fileObj.idxNumber].spanWidth;
                                    imgHeight = imgWidth / aspectRatio;
                                } else {
                                    imgHeight = cellHeight * grids[fileObj.pageNumber][fileObj.idxNumber].spanHeight;
                                    imgWidth = imgHeight * aspectRatio;
                                }

                                const x = currentCol * cellWidth + horizontalMargin;
                                const y = currentRow * cellHeight + (1.5)*verticalMargin;

                                const reader = new FileReader();
                                reader.readAsDataURL(fileObj.blob);
                                reader.onloadend = function() {
                                    const base64data = reader.result;
                                    pdf.addImage(base64data, 'PNG', x, y, imgWidth, imgHeight);

                                    if (currentCol === 0) {
                                      currentRowMinHeight = grids[fileObj.pageNumber][fileObj.idxNumber].spanHeight;
                                  } else {
                                      currentRowMinHeight = Math.min(currentRowMinHeight, grids[fileObj.pageNumber][fileObj.idxNumber].spanHeight);
                                  }
                                  currentCol = Math.floor(fileObj.idxNumber % numCols) +  grids[fileObj.pageNumber][fileObj.idxNumber].spanWidth;
                                  if (currentCol >= numCols) {
                                      currentRow += currentRowMinHeight;
                                      currentCol = 0;
                                  }
        
                                  if (currentRow >= numRows && absoluteIndex !== imageUrls.length - 1) {
                                      pdf.addPage();
                                      currentRow = 0;
                                      currentCol = 0;
                                  }
                                    resolve();
                                };
                            };
                            img.onerror = function(e) {
                                console.error("Failed to load image with URL:", img.src);
                                reject(e);
                            };
                            img.src = URL.createObjectURL(fileObj.blob);
                        });
                    });
                }
            });

            return p;
        })
        .then(() => {
            const pdfBlob = pdf.output('blob');
            fileData.push({
                blob: pdfBlob,
                fileName: `_for_web/final_flyer.pdf`,
                fileType: 'application/pdf'
            });

            // Adjusting fileData for cell images to be in '_for_digital_ads' subfolder
            fileData.forEach(fileObj => {
              if (fileObj.fileType === 'image/png' && !fileObj.fileName.startsWith('_for_social_ads')) {
                fileObj.fileName = `_for_digital_ads/${fileObj.fileName}`;
              }
            });

            return uploadFiles(fileData);
        }).then((uploadedFilesData) => {
          // Use uploadedFilesUrls here.
          const pdfData = uploadedFilesData.find(data => data.originalName.includes('final_flyer.pdf'));

          if (!pdfData) {
              throw new Error('PDF URL not found in uploaded files');
          }

          createdFlyer.pdf_url = pdfData.url;
          return saveFlyer(false);
        })
        .then(() => {
            hideOverlay();
        })
        .catch((error) => {
            console.error("Error uploading:", error);
            hideOverlay();
        });
  };

  const deleteFlyer = async () => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyers/${createdFlyer.flyer_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // After successful deletion, navigate to the /flyers page
      navigate('/flyers');
    } catch (error) {
      toast.error('Error occurred while deleting the flyer.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  };

  const updateLayerRefs = useCallback((combinedIndex, ref) => {
    const validIndexPattern = /^\d+-\d+$/;
      if (!validIndexPattern.test(combinedIndex)) {
          console.warn(`Invalid ref index: ${combinedIndex}`);
          return;
      }
    setShapesLayerRefs(prevRefs => ({
        ...prevRefs,
        [combinedIndex]: ref,
    }));
  }, []);

  const renderMainContent = () => {
    return (
      <main>
      
        <div className="div-block" style={{alignContent: 'center' }}>
          <div className="flyer-canvas" style={{width: ((CELL_SIZE * 3) + (20*3))}}>
          {grids.map((grid, gridIndex) => {
            let gridCellSize;
            let cols;
            if(gridDimensions[gridIndex] === '4x4') {
              gridCellSize = 75;  
              cols = 4;
            } else {
              gridCellSize = 100;
              cols = 3;
            }
            return (
              <div className="flyer-grid" style={style}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(' + cols + ', 1fr)',
                    columnGap:'0',
                    rowGap: '0', 
                    border: '1px dashed #ccc',
                    width: gridCellSize * cols
                  }}
                >
                    {grid.map((cell, cellIndex) => {
                    const combinedIndex = `${gridIndex}-${cellIndex}`;
                    return (
                      cell ? (
                      <GridItem
                        key={combinedIndex}
                        index={combinedIndex}
                        cell={cell.content}
                        cellSize={gridCellSize}
                        cell_width={cell.dimensions.width * CELL_SIZE_REDUCTION_FACTOR} 
                        cell_height={cell.dimensions.height * CELL_SIZE_REDUCTION_FACTOR} 
                        spanWidth={cell.spanWidth}
                        spanHeight={cell.spanHeight}
                        originalTemplateWidth={cell.templateWidth}
                        originalTemplateHeight={cell.templateHeight}
                        assignTemplate={assignTemplate}
                        handleCellUpdate={handleCellUpdate}
                        nextProductSlot={nextProductSlots[gridIndex]}
                        updateLayerRefs={updateLayerRefs}
                      />
                    ) : null
                    );
                  })} 
                </div>
              </div>
            );
          })}   
          </div>
        </div>
        <div className="div-block">
            <div className="form-buttons-flyer">
            <ToastContainer />
            <FormButton className="Button" type="button" onClick={saveFlyer} >
              Save
            </FormButton>
            <FormButton className="Button hide-on-mobile" type="button" onClick={downloadFlyer}>
              Download
            </FormButton>
            <FormButton className="Button" type="button" onClick={publishFlyer} >
              Publish
            </FormButton>
            <Tooltip>
              <Popconfirm  
                title="Are you sure to delete this flyer?" 
                onConfirm={() => deleteFlyer()}
                onCancel={() => message.error('Delete Cancelled')}
                okText="Yes" 
                cancelText="No"
              >
                <FormButton className="Button" type="button" backgroundColor={"red"} >
                  Delete
                </FormButton>
              </Popconfirm>
            </Tooltip>
            </div>
            
        </div>  
      </main>
    )
  };

  return renderMainContent();
}

export default Flyer;
