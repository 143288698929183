import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PdfViewer, { Worker, SpecialZoomLevel } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

const CustomPDFViewer = () => {
    const [flyers, setFlyers] = useState([]);
    const [selectedFlyer, setSelectedFlyer] = useState(null);

    useEffect(() => {
        const fetchFlyers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyers/retailer/777`);
                setFlyers(response.data);
                setSelectedFlyer(response.data[0]);
            } catch (error) {
                console.error('Failed to fetch flyers:', error);
            }
        };

        fetchFlyers();
    }, []);

    const onFlyerChange = (e) => {
        const flyerId = parseInt(e.target.value, 10);
        const flyer = flyers.find(f => f.flyer_id === flyerId);
        setSelectedFlyer(flyer);
    };

    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const { ZoomInButton, ZoomOutButton } = slots;
                return (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexGrow: 1,
                            }}
                        >
                            <ZoomOutButton />
                            <ZoomInButton />
                            {/* Other custom buttons */}
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    return (
      <div>
          <select onChange={onFlyerChange}>
              {flyers.map(flyer => (
                  <option key={flyer.flyer_id} value={flyer.flyer_id}>
                      {flyer.flyer_title}
                  </option>
              ))}
          </select>
          {selectedFlyer && (
              <div style={{ height: '65vh', overflowY: 'scroll', border: '1px solid #ccc' }}>
                  <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                      <PdfViewer
                          fileUrl={selectedFlyer.pdf_url}
                          renderToolbar={renderToolbar}
                      />
                  </Worker>
              </div>
          )}
      </div>
  );
};

export default CustomPDFViewer;
