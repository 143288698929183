import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ProtectedRoute = ({ component: Component, userHasFilledProfile, handleProfileFilled, userHasSetupLocation, handleUserLocationSetup, userCountry, setUserCountry, userOrganizationId, setUserOrganizationId }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // Get country from redux state
  const countryLanguage = useSelector((state) => state.country);

  // Translation setup
  const { i18n } = useTranslation();

  // Map countries to languages
  const countryLanguageMap = {
    US: 'US-EN',
    Canada: 'CA-EN',
    // Add more mappings as necessary
  };

  // Change language when the country changes
  useEffect(() => {
    const language = countryLanguageMap[countryLanguage];
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [countryLanguage, i18n]);

  useEffect(() => {
    const fetchProfileFilledStatus = async () => {
      setLoading(true);
      try {
        if (user && isAuthenticated) {
          const token = await getAccessTokenSilently();
          const res = await fetch(
            process.env.REACT_APP_SERVER_DOMAIN + "/api/users/profile/" + user.sub,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await res.json();
          if (data.hasFilledProfile) {
            setUserCountry(data.userData.country);
            setUserOrganizationId(data.userData.organization_id);
            handleProfileFilled();
            if (data.hasStoreLocationSetup) {
              handleUserLocationSetup();
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch profile filled status:", error);
      }
      setLoading(false);
    };

    if (isAuthenticated && user) {
      fetchProfileFilledStatus();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, getAccessTokenSilently, user, setUserCountry, setUserOrganizationId, handleProfileFilled, handleUserLocationSetup]);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      if (!userHasFilledProfile || !userHasSetupLocation) {
        // Redirect to welcome only if the current page is not already welcome
        if (location.pathname !== '/welcome') {
          navigate("/welcome");
        }
      } else {
        // Redirect to dashboard only if the current page is welcome (or another specific page if needed)
        if (location.pathname === '/welcome') {
          navigate("/dashboard");
        }
      }
    }
  }, [loading, isAuthenticated, userHasFilledProfile, userHasSetupLocation, navigate, location.pathname]);

  if (loading || !isAuthenticated || !user || userHasFilledProfile === null) {
    return null;
  }

  return <Component userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} />;
};

export default ProtectedRoute;