import React, {useState} from 'react';
import { SketchPicker } from 'react-color';


const ImageProperties = ({ shape, onChange }) => {
  const [isBorderColorPickerVisible, setBorderColorPickerVisible] = useState(false);
  // include the properties specific to Rectangle here

  const handleAlignChange = (event) => {
    onChange({ ...shape, align: event.target.value });
  };

  const handleVAlignChange = (event) => {
    onChange({ ...shape, vAlign: event.target.value });
  };

  const toggleBorderColorPicker = () => {
    setBorderColorPickerVisible(!isBorderColorPickerVisible);
  };
  // include the properties specific to Image here

  const handleImageUrlChange = (event) => {
    const newImageUrl = event.target.value;
    onChange({ ...shape, imageUrl: newImageUrl});
  }

  const handleBorderColorChange = (color) => {
    onChange({ ...shape, stroke: color.hex });
  };

  const handleBorderThicknessChange = (event) => {
    const newStrokeWidth = parseInt(event.target.value);
    onChange({ ...shape, strokeWidth: newStrokeWidth });
  };

  const handleBorderCornerRadiusChange = (event) => {
    const newCornerRadius = parseInt(event.target.value);
    onChange({ ...shape, cornerRadius: newCornerRadius });
  };

  const handleLinkedProductNumberChange = (event) => {
    const newLinkedProductNumber = parseInt(event.target.value);
    onChange({ ...shape, linkedProductNumber: newLinkedProductNumber.toString() });
  };

  const handleLinkedProductPropertyChange = (event) => {
    const newLinkedProductProperty = event.target.value;
    onChange({ ...shape, linkedProductProperty: newLinkedProductProperty });
  };

  return (
    <div className="shape-specific-properties">
      <div className="shape-property">
        <label className="shape-property-label">Image Url</label>
        <input type="input" value={shape.imageUrl || ''} onChange={handleImageUrlChange} />
      </div>
      {/* Horizontal Align */}
      <div className="shape-property">
        <label className="shape-property-label">Horizontal Align</label>
        <select value={shape.align || 'center'} onChange={handleAlignChange} style={{width: '100px'}}>
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
        </select>
      </div>

      {/* Vertical Align */}
      <div className="shape-property">
        <label className="shape-property-label">Vertical Align</label>
        <select value={shape.vAlign || 'middle'} onChange={handleVAlignChange} style={{width: '100px'}}>
          <option value="top">Top</option>
          <option value="middle">Middle</option>
          <option value="bottom">Bottom</option>
        </select>
      </div>
      <div className="shape-property">
        <label className="shape-property-label" onClick={toggleBorderColorPicker}>Border color</label>
        {isBorderColorPickerVisible && (
          <SketchPicker color={shape.stroke || ''} onChangeComplete={handleBorderColorChange} />
        )}
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Border thickness</label>
        <input type="number" value={shape.strokeWidth || ''} onChange={handleBorderThicknessChange}   style={{width: '30px'}}   />
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Border radius</label>
        <input type="number" value={shape.cornerRadius || ''} onChange={handleBorderCornerRadiusChange}   style={{width: '30px'}}   />
      </div>
      <div  className="shape-property">
        <label className="shape-property-label">Linked Product</label>
        <input type="number" min="1" max="10" value={shape.linkedProductNumber || ''} onChange={handleLinkedProductNumberChange}   style={{width: '30px'}}  />

      </div>
      <div  className="shape-property">
        <label className="shape-property-label">Linked Property</label>
        <select value={shape.linkedProductProperty || ''} onChange={handleLinkedProductPropertyChange}  style={{width: '150px'}}>
          <option value="cleaned_img_large_src">Cleaned Large Image</option>
          <option value="cleaned_img_small_src">Cleaned Small Image</option>
          <option value="original_img_large_src">Original Large Image</option>
          <option value="original_img_small_src">Original Small Image</option>
        </select>
      </div>
    </div>
  );
};

export default ImageProperties;
