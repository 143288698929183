import React, { useState, useEffect, useRef, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDrag } from 'react-dnd';
import axios from 'axios';
import Flyer from './components/Flyers/Flyer';
import { Tabs, Slider } from 'antd';
import FormInputText from './stories/FormInputText';
import FormInputDatePicker from './stories/FormInputDatePicker';
import FormButton from './stories/FormButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from './stories/Button';
import {useLocation} from 'react-router-dom';
import ProductRow from './components/Products/ProductRow';
import { Step1, Step2} from './components/Flyers/FlyerCreationSteps';
import DragContext from './context/DragContext';
import './AddFlyer.css';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';



const { TabPane } = Tabs;

function AddFlyer() {
    const { getAccessTokenSilently } = useAuth0();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [flyerTemplates, setFlyerTemplates] = useState([]);
    const [searchKeywords, setSearchKeywords] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsCount, setSearchResultsCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(100);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [flyerDetails, setFlyerDetails] = useState(null);
    const [highlightedTemplateId, setHighlightedTemplateId] = useState(null);
    const [createdFlyer, setCreatedFlyer] = useState(null);
    const [key, setKey] = useState(0);
    const location = useLocation();
    const flyerRef = useRef();
    const nextStep = () => setCurrentStep(currentStep + 1);
    const [zoomLevel, setZoomLevel] = useState(100); // 100% is the default zoom level
    const [isProductDragging, setIsProductDragging] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
  
    /*Loading the page for Editing if Flyer is set in the State*/
    useEffect(() => {
    if (location.state && location.state.flyer) {
        const startDate = new Date(location.state.flyer.start_date);
        const endDate = new Date(location.state.flyer.end_date);

        setSelectedTemplate(location.state.flyer.templateId);        

        setCreatedFlyer(prevFlyer => ({
          ...prevFlyer,
          ...location.state.flyer,
          start_date: startDate,
          end_date: endDate
        }));
        setCurrentStep(3); // Navigate to Step 3
      }
    }, [location]);

    const toggleSearch = () => {
      setIsSearchActive(!isSearchActive);
    };  

    const handleSearchKeywordsChange = (e) => {
      setSearchKeywords(e.target.value);
    };
    
    const handleSearchSubmit = async (event) => {
      event.preventDefault();
      searchProducts(event);
    };

    const searchProducts = async(e) => {  
      if (searchKeywords.trim() === '') return;
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/products/search/${searchKeywords}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    
        if (response.ok) {
          const searchResults = await response.json();
          setSearchResults(searchResults);
          setSearchResultsCount(searchResults.length);
          setCurrentPage(1); // Reset current page to the first page
        } else {
          toast.error('Error occurred while searching products.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            style: {
              background: '#fff',
              color: '#ff0000'
            }
          });
        }
      } catch (error) {
        toast.error('Error occurred while searching products.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          style: {
            background: '#fff',
            color: '#ff0000'
          }
        });
      }
        
    };

    const promoTypeLabels = {
      'PRICE_DISCOUNT': '$ off on Original Price',
      'PERCENTAGE_DISCOUNT': '% off on Original Price',
      'BOGO': 'Buy 1 Get 1 Free',
    };

    const getCurrentProducts = () => {
      const indexOfLastProduct = currentPage * productsPerPage;
      const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      return searchResults.slice(indexOfFirstProduct, indexOfLastProduct);
    };

    useEffect(() => {
        const fetchTemplates = async () => {
          try {
            const token = await getAccessTokenSilently();
            const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyertemplates/retailer/777`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }); 
            setFlyerTemplates(response.data);
          } catch (error) {
            console.error('Failed to fetch flyer templates:', error);
          }
        };

        fetchTemplates();
      }, [getAccessTokenSilently]);

    return (
      <DragContext.Provider value={setIsProductDragging}>
        <div className='dashboard-body-wrapper'>
          <div className="overlay">
            <div className="spinner">
          </div>
          </div>
          <div className={`main-column ${isSearchActive ? 'shift-left' : ''}`}>
              {currentStep === 1 && (
                <Step1 onNext={nextStep} setFlyerDetails={setFlyerDetails} />
              )}

              {currentStep === 2 && (
                <Step2
                onNext={nextStep}
                getAccessTokenSilently={getAccessTokenSilently}
                flyerTemplates={flyerTemplates}
                setSelectedTemplate={setSelectedTemplate}
                highlightedTemplateId={highlightedTemplateId} 
                setHighlightedTemplateId={setHighlightedTemplateId}
                flyerDetails={flyerDetails}
                setCreatedFlyer={setCreatedFlyer}
                />
              )}
              {currentStep === 3 && (
                <div className="flyer-maker">
                  <div className="flyer-details">
                    <div className="shape-property">
                      <label className="shape-property-label">Flyer Title</label>
                      <FormInputText
                          placeholder="Flyer Title*" 
                          value={createdFlyer.flyer_title} 
                          key={key}
                          onChange={e => setCreatedFlyer(prevFlyer => ({ ...prevFlyer, flyer_title: e.target.value }))}                          isRequired={true}
                          width="90%"
                        />
                    </div>
                    <div className="shape-property">
                      <label className="shape-property-label">Start Date</label>    
                      {createdFlyer.start_date && <FormInputDatePicker
                        placeholder="Start Date*"
                        type="date"
                        value={createdFlyer.start_date}
                        onChange={date => setCreatedFlyer(prevFlyer => ({ ...prevFlyer, start_date: date }))}
                        isRequired={true} 
                        width="90%"
                      />}
                    </div>
                    <div className="shape-property">
                      <label className="shape-property-label">End Date</label>    
                      {createdFlyer.end_date && <FormInputDatePicker
                        placeholder="End Date*"
                        type="date"
                        value={createdFlyer.end_date}
                        onChange={date => setCreatedFlyer(prevFlyer => ({ ...prevFlyer, end_date: date }))}
                        isRequired={true}
                        width="90%"
                      />}
                    </div>
                    <div className="shape-property">
                      <FormButton className="Button--transparent Button--noborder search-button-mobile" onClick={toggleSearch}>
                        {isSearchActive? <DoubleRightOutlined style={{color:'#605dba' ,fontSize:'24px', border:'none'}}/>: <DoubleLeftOutlined style={{color:'#605dba' ,fontSize:'24px', border:'none'}}/>}
                      </FormButton>
                    </div>
                    {/* <div className="shape-property">
                    <label className="shape-property-label">Zoom Level</label>    
                    <div > 
                      <Slider
                        min={50} // Minimum zoom level, e.g., 50% of the original size
                        max={300} // Maximum zoom level, e.g., 200% of the original size
                        value={zoomLevel}
                        onChange={(value) => setZoomLevel(value)}
                        style={{ width: '90%' }}
                      />
                      </div> 
                    </div> */}
                  </div>
                  <Flyer ref={flyerRef} templateId={selectedTemplate} createdFlyer={createdFlyer} setCreatedFlyer={setCreatedFlyer} style={{ transform: `scale(${zoomLevel / 100})`,zIndex: isProductDragging ? 1 : -1  }}/> 
                </div>
              )}
          </div>
          <div className={`right-column ${isSearchActive ? 'shift-right' : ''}`}>
            {currentStep === 3 && (            
            <Tabs defaultActiveKey="1">
                <TabPane tab="Product Search" key="1">
                  <div className="product-search-form">
                    <form id="product-search-form" name="product-search-form" data-name="Product Search Form" method="get" onSubmit={handleSearchSubmit}>
                      <FormInputText 
                        id="search-keywords" 
                        name="search-keywords" 
                        maxlength="256" 
                        width="90%" 
                        placeholder="Enter SKU ID, Product Name, Brand, or part of Product Description to Search" 
                        required="" 
                        value={searchKeywords}
                        onChange={handleSearchKeywordsChange}
                      />
                      <FormButton className="Button">Search</FormButton>
                    </form>
                  </div>
                  <div className="div-block">
                    {searchResultsCount !== null && (
                      <div className="search-results-notification">
                    
                        <div>{`${searchResultsCount} Products found.`}</div>
                      </div>
                      )}
                  </div>
                  <div className='div-block'>
                  {searchResultsCount !== null && searchResultsCount > 0 && !isSearchActive&& (
                    <div className="products-table-div">
                        <table>
                          <thead>
                            <tr>
                              <th>SKU</th>
                              <th>Product Name</th>
                              <th>Original Price</th>
                              <th>Promo Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getCurrentProducts().map((product, index) => (
                              <ProductRow
                                key={index}
                                product={product}
                                selectedProduct={selectedProduct}
                                promoTypeLabels={promoTypeLabels}
                              />
                            ))}
                          </tbody>
                        </table>
                      <div className="pagination">
                          <Button 
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                          Previous
                          </Button>
                        <span>{`${currentPage} / ${Math.ceil(searchResults.length / productsPerPage)}`}</span>
                          <Button 
                            disabled={currentPage === Math.ceil(searchResults.length / productsPerPage)}
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                          Next
                          </Button>
                      </div>
                    </div>
                  )}
                  {searchResultsCount !== null && searchResultsCount > 0 && isSearchActive&& (
                    <div className="products-table-div">
                        <table>
                          <thead>
                            <tr>
                              <th>SKU</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getCurrentProducts().map((product, index) => (
                              <ProductRow
                                key={index}
                                product={product}
                                selectedProduct={selectedProduct}
                                promoTypeLabels={promoTypeLabels}
                                isSearchActive={isSearchActive}
                              />
                            ))}
                          </tbody>
                        </table>
                      <div className="pagination">
                          <Button 
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                          Previous
                          </Button>
                        <span>{`${currentPage} / ${Math.ceil(searchResults.length / productsPerPage)}`}</span>
                          <Button 
                            disabled={currentPage === Math.ceil(searchResults.length / productsPerPage)}
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                          Next
                          </Button>
                      </div>
                    </div>
                  )}
                  </div>
                </TabPane>
            </Tabs>
          )}
          </div>
        </div>
      </DragContext.Provider>
    );
}

export default AddFlyer;
