import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './FormInputText.css';

function FormInputText({
  label,
  value,
  onChange,
  name,
  placeholder,
  width,
  maxLength,
  isRequired,
  inputType,
  errorMessage,
  setErrorMessage,
  readOnly
}) {
  const [error, setError] = useState('');

  const validateInputType = (value) => {
    let regex;
    switch (inputType) {
      case 'Integer':
        regex = /^-?\d+$/;
        return regex.test(value);
      case 'Float':
        regex = /^-?\d+(\.\d+)?$/;
        return regex.test(value);
      case 'Natural Number':
        regex = /^\d+$/;
        return regex.test(value);
      case 'Alphanumeric':
        regex = /^[a-zA-Z0-9]*$/;
        return regex.test(value);
      case 'String':
        return true;
      default:
        return true;
    }
  };

  const validateInputLength = (value) => {
    return value.length <= maxLength;
  };

  const validateInputRange = (value) => {
    return parseFloat(value) < 99999.9999;
  };

  const handleInputChange = (e) => {
    if (isRequired && e.target.value.trim() === '') {
      setError(`${label} is required.`);
    } else if (!validateInputLength(e.target.value)) {
      setError(`${label} must be shorter than ${maxLength} characters.`);
    } else if (!validateInputType(e.target.value)) {
      setError(`Invalid input type for ${label}. Expected ${inputType}.`);
    } else if (inputType === 'Float' && !validateInputRange(e.target.value)) {
      setError(`Invalid input range for ${label}. Must be less than 99999.9999.`);
    } else {
      setError('');
    }
    onChange(e);
  };

  useEffect(() => {
    if (setErrorMessage) {
      setErrorMessage(error);
    }
  }, [error, setErrorMessage]);

  return (
    <div className="form-input" style={{ width }}>
      <label htmlFor={label}>{label}</label>
      <input
        type="text"
        id={label}
        value={value}
        onChange={handleInputChange}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        readOnly={readOnly}
      />
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
    </div>
  );
}

FormInputText.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  inputType: PropTypes.oneOf([
    'Integer',
    'Float',
    'Natural Number',
    'Alphanumeric',
    'String',
  ]),
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
};

FormInputText.defaultProps = {
  maxLength: 255,
  isRequired: false,
  inputType: 'String',
};

export default FormInputText;
