import React, { useState, useRef, useEffect } from 'react';
import { Line, Transformer, Group, Rect } from 'react-konva';

const ArrowShape = ({ 
  id, 
  points: initialPoints, 
  fill: initialFill, 
  selected, 
  onSelect, 
  onChange,
  draggable
 }) => {
  const groupRef = useRef();
  const lineRef = useRef();
  const arrowHeadRef = useRef();
  const trRef = useRef();
  const [fill, setFill] = useState(initialFill);

  const handleRightClick = (event) => {
    event.evt.preventDefault();

    const newFill = prompt("Enter a color:");
    if (newFill) {
      setFill(newFill);
    }
  };

  useEffect(() => {
    if (selected) {
      trRef.current.nodes([groupRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  const handleDragEnd = () => {
    const node = groupRef.current;

    onChange({
      id: id,
      type: 'arrow',
      points: [
        ...lineRef.current.absolutePoints().map(p => [p.x, p.y]).flat(),
        ...arrowHeadRef.current.absolutePoints().map(p => [p.x, p.y]).flat()
      ],
      fill: fill,
    });
  };

  const handleTransformEnd = () => {
    const node = groupRef.current;

    const linePoints = lineRef.current.absolutePoints().map(p => [p.x, p.y]).flat();
    const arrowPoints = arrowHeadRef.current.absolutePoints().map(p => [p.x, p.y]).flat();

    // Reset the transformations
    node.rotation(0);
    node.scaleX(1);
    node.scaleY(1);

    onChange({
      id: id,
      type: 'arrow',
      points: [...linePoints, ...arrowPoints],
      fill: fill,
    });
  };

  const arrowHeadLength = 20; 
  const [x1, y1, x2, y2] = initialPoints;
  const angle = Math.atan2(y2 - y1, x2 - x1);

  return (
    <Group
      onClick={onSelect}
      onTap={onSelect}
      ref={groupRef}
      draggable={draggable}
      onDragEnd={handleDragEnd}
      onTransformEnd={handleTransformEnd}
      onContextMenu={handleRightClick}
    >
      <Rect
        x={Math.min(x1, x2)}
        y={Math.min(y1, y2)}
        width={Math.abs(x2 - x1)}
        height={Math.abs(y2 - y1)}
        fill="transparent"
      />
      <Line
        points={[x1, y1, x2, y2]}
        stroke={fill}
        strokeWidth={2}
        ref={lineRef}
      />
      <Line
        points={[
          x2 - arrowHeadLength * Math.cos(angle - Math.PI / 6), y2 - arrowHeadLength * Math.sin(angle - Math.PI / 6),
          x2, y2,
          x2 - arrowHeadLength * Math.cos(angle + Math.PI / 6), y2 - arrowHeadLength * Math.sin(angle + Math.PI / 6)
        ]}
        fill={fill}
        closed
        ref={arrowHeadRef}
      />
      {selected && <Transformer ref={trRef} />}
    </Group>
  );
};

export default ArrowShape;
