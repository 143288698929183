import React from 'react';

const ImageIcon = ({ size = 50, color = "black" }) => (
  <svg width={size} height={size}>
    <rect width="100%" height="70%" stroke={color} strokeWidth="3" fill="transparent" />
    <line x1="10%" y1="70%" x2="90%" y2="70%" stroke={color} strokeWidth="3" />
    <circle cx="50%" cy="35%" r="15%" stroke={color} strokeWidth="3" fill="transparent" />
  </svg>
);

export default ImageIcon;
