import React, { useEffect } from 'react';
import { Label, Tag, Text, Transformer } from 'react-konva';

const TagShape = ({ 
  id, 
  x, 
  y, 
  text,
  fill,
  stroke, 
  strokeWidth,
  pointerDirection,
  pointerWidth,
  pointerHeight,
  selected, 
  onSelect, 
  onChange, 
  onDelete, 
  onMoveShapeDown, 
  onMoveShapeUp,
  cornerRadius,
  draggable 
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  const handleRightClick = (event) => {
    event.evt.preventDefault(); // Prevent the default context menu from appearing

    const action = prompt("Enter '1' to change color, '2' to delete the shape, '3' to move shape down, '4' to move shape up, '5' to add border:"); // Prompt the user for action
    switch(action) {
      case '1':
        const newColor = prompt("Enter a color:"); // Prompt the user for a color
        if (newColor) {
          onChange({ id, type: 'tag', x, y, text, fill: newColor, stroke, strokeWidth, pointerDirection, pointerWidth, pointerHeight, cornerRadius });
        }
        break;
      case '2':
        onDelete(id);
        break;
      case '3':
        onMoveShapeDown(id);
        break;
      case '4':
        onMoveShapeUp(id);
        break;
      case '5': // New case for adding a border
        const newStroke = prompt("Enter a border color:");
        const newStrokeWidth = parseInt(prompt("Enter border thickness:"));
        const newCornerRadius = parseInt(prompt("Enter corner radius for rounded edges:"));
        if(newStroke && !isNaN(newStrokeWidth)){
            onChange({ id, type: 'tag', x, y, text, fill, stroke: newStroke, strokeWidth: newStrokeWidth, pointerDirection, pointerWidth, pointerHeight, cornerRadius: newCornerRadius });
        }
        break;
      default:
        alert("Invalid option");
        break;
    }
  };

  useEffect(() => {
    if (selected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  return (
    <>
      <Label 
        ref={shapeRef}
        id={id}
        x={x} 
        y={y}
        draggable={draggable}
        onDragEnd={e => {
  const newAttrs = {
    id,
    type: 'tag',
    x: e.target.x(),
    y: e.target.y(),
    text,
    fill,
    stroke,
    strokeWidth,
    pointerDirection,
    pointerWidth,
    pointerHeight,
    cornerRadius,
  };
  onChange(newAttrs);
}}

        onContextMenu={handleRightClick}
        onClick={onSelect}
        onTap={onSelect}
      >
        <Tag
          fill={fill} 
          stroke={stroke}
          strokeWidth={strokeWidth}
          pointerDirection={pointerDirection}
          pointerWidth={pointerWidth}
          pointerHeight={pointerHeight}
          cornerRadius={cornerRadius}
        />
        <Text
          text={text}
          padding={20}
        />
      </Label>
      {selected && <Transformer ref={trRef} />}
    </>
  );
};

export default TagShape;
