import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FormInputText from './stories/FormInputText';
import FormInputEmail from './stories/FormInputEmail';
import FormInputDropDown from './stories/FormInputDropDown';
import FormInputPhoneNumber from './stories/FormInputPhoneNumber';
import FormInputCheckbox from './stories/FormInputCheckbox';
import FormButton from './stories/FormButton';

const ProfileForm = ({ onProfileSubmit }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    company: '',
    email: user.email,
    phone: '',
    country: '',
    number_of_stores: '',
    terms_accepted: false,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' }); // Clear previous errors
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setErrors({ ...errors, [name]: '' }); // Clear previous errors
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  function validatePhoneNumber(phoneNumber) {
    const pattern = /^(\d{3}-\d{3}-\d{4})$/;
    return pattern.test(phoneNumber);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    // Check if all fields are filled and valid
    if (!formData.firstname) validationErrors.firstname = 'First Name is required';
    if (!formData.lastname) validationErrors.lastname = 'Last Name is required';
    if (!formData.company) validationErrors.company = 'Company Name is required';
    if (!formData.email || !validateEmail(formData.email)) validationErrors.email = 'A valid Email is required';
    if (!formData.phone || !validatePhoneNumber(formData.phone)) validationErrors.phone = 'Phone Number must be in the format xxx-xxx-xxxx';
    if (!formData.country) validationErrors.country = 'Country is required';
    if (!formData.number_of_stores) validationErrors.number_of_stores = 'Number of Stores is required';
    if (!formData.terms_accepted) validationErrors.terms_accepted = 'You must accept the Terms & Conditions';

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // No validation errors
      const token = await getAccessTokenSilently();
      const userId = decodeURIComponent(user.sub);

      // Update Auth0 user_metadata
      await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_AUTH0_MGMT_API_TOKEN}`,
        },
        body: JSON.stringify({
          user_metadata: formData,
        }),
      });
  
      // Update user profile in your backend
      const response =   await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...formData,
          user_id: userId,
        }),
      });

      const updatedUser = await response.json();
      onProfileSubmit(updatedUser.result.country, updatedUser.result.organization_id);     }
  };

  return (
    <div className="div-block">
      <form className="profile-form" onSubmit={handleSubmit}>
        {/* Form fields with error messages */}
        <div className="form-row">
          <FormInputText
            label="First Name"
            value={formData.firstname}
            onChange={handleInputChange}
            name="firstname"
            width="40%"
            errorMessage={errors.firstname}
          />
          <FormInputText
            label="Last Name"
            value={formData.lastname}
            onChange={handleInputChange}
            name="lastname"
            width="40%"
            errorMessage={errors.lastname}
          />
        </div>
        <div className="form-row">
          <FormInputText
            label="Company Name"
            value={formData.company}
            onChange={handleInputChange}
            name="company"
            width="40%"
            errorMessage={errors.company}
          />
        </div>
        <div className="form-row">
          <FormInputEmail
            label="Email"
            value={formData.email}
            onChange={handleInputChange}
            name="email"
            width="40%"
            readOnly
            errorMessage={errors.email}
          />
          <FormInputPhoneNumber
            label="Phone Number"
            value={formData.phone}
            onChange={handleInputChange}
            name="phone"
            width="40%"
            errorMessage={errors.phone}
          />
        </div>
        <div className="form-row">
          <FormInputDropDown
            label="Country of Operation"
            value={formData.country}
            onChange={handleInputChange}
            name="country"
            options={[
              { value: 'Canada', label: 'Canada' },
              { value: 'USA', label: 'USA' },
            ]}
            width="40%"
            errorMessage={errors.country}
          />
          <FormInputDropDown
            label="Number of Stores"
            value={formData.number_of_stores}
            onChange={handleInputChange}
            name="number_of_stores"
            options={[
              { value: '1', label: '1 Store' },
              { value: '1-5', label: '1 - 5 Stores' },
              { value: '6-10', label: '6 - 10 Stores' },
              { value: '11-20', label: '11 - 20 Stores' },
              { value: '20-50', label: '20 - 50 Stores' },
              { value: '50+', label: 'More than 50 Stores' },
            ]}
            width="40%"
            errorMessage={errors.number_of_stores}
          />
        </div>
        <div className="form-row">
          <FormInputCheckbox
            label="I agree to Terms & Conditions and Privacy Policy"
            checked={formData.terms_accepted}
            onChange={handleCheckboxChange}
            name="terms_accepted"
            errorMessage={errors.terms_accepted}
          />
        </div>
        <div className="form-row">
          <FormButton className="Button" type="submit">Submit Profile</FormButton>
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
