import React, { useEffect } from 'react';
import { Rect, Transformer } from 'react-konva';

const RectangleShape = ({ 
  id, 
  x, 
  y, 
  width, 
  height, 
  fill, 
  stroke, 
  strokeWidth, 
  cornerRadius,
  rotation,
  draggable,  
  selected,
  onSelect, 
  onChange
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  useEffect(() => {
    if (selected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  return (
    <>
      <Rect 
        ref={shapeRef}
        id={id}
        x={x} 
        y={y} 
        width={width} 
        height={height} 
        fill={fill} 
        draggable={draggable}
        stroke={stroke}
        strokeWidth={strokeWidth}
        cornerRadius={cornerRadius}
        shadowBlur={selected ? 10 : 0} // Apply shadow effect when selected
        shadowColor={selected ? 'black' : null} // The shadow color when selected
        onDragEnd={e => {
          onChange({ id, type: 'rectangle', x: e.target.x(), y: e.target.y(), width, height, fill, stroke, strokeWidth, cornerRadius, rotation,  draggable });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const newRotation = node.rotation();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            id,
            type: 'rectangle',
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            fill,
            stroke,
            strokeWidth,
            cornerRadius,
            rotation: newRotation,
            draggable
          });
        }}
        onClick={onSelect}
        onTap={onSelect}
      />
      {selected && <Transformer ref={trRef} />}
    </>
  );
};

export default RectangleShape;
