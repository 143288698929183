import React from 'react';

const Terms = () => {
  return (
    <div>
      <h1>Terms of Use</h1>
      <p className="legal-paragraph">TERMS OF USE ("TERMS OF USE") GOVERNING YOUR USE OF THE SERVICES AND SITE ("adgro.app") OWNED BY RHOMBUS LABS INC. ("Rhombus Labs," "US," "WE," AND "OUR"), A COMPANY INCORPORATED IN ONTARIO, CANADA.</p>
      <p className="legal-paragraph">BY USING THE SITE OR SERVICES IN ANY WAY, YOU HEREBY AGREE TO THESE TERMS OF USE, AS WELL AS OUR PRIVACY POLICY AND COOKIES POLICY. IF YOU ARE USING THE SERVICES ON BEHALF OF A CUSTOMER OF RHOMBUS LABS, THEN USE OF THE SERVICES SHALL ALSO BE SUBJECT TO THE SERVICES AGREEMENT ENTERED INTO BETWEEN RHOMBUS LABS AND THE CUSTOMER.</p>
      <p className="legal-paragraph">Article 1: Website and Services</p>
      <p className="legal-paragraph">1.1 Use of the Site</p>
      <p className="legal-paragraph">Subject to your compliance with these Terms of Use, Rhombus Labs provides you with access to and use of our website located at www.adgro.app (the "Site").</p>
      <p className="legal-paragraph">1.2 Use of the Services</p>
      <p className="legal-paragraph">Subject to your compliance with these Terms of Use, Rhombus Labs provides you with access to our proprietary AI-powered ad platform services ("Services"). By using the Services, you consent to our communication with you through various channels, including instant messaging, email, and SMS, as necessary for the provision of the Services.</p>
      <p className="legal-paragraph">1.3 Product Evaluation or Sandbox Usage</p>
      <p className="legal-paragraph">Rhombus Labs may make available a free or discounted version of the Site, Services, or other Services on a trial basis. If granted access, you have a limited, non-transferrable, non-sub-licensable license to use the Services for non-production, evaluation purposes during the trial period. Access to the trial version of the Services is provided on an "as-is" basis, and any data or content uploaded may be permanently lost upon expiration of the trial period.</p>
      <p className="legal-paragraph">Article 2: Service Restrictions</p>
      <p className="legal-paragraph">2.1 Service Restrictions</p>
      <p className="legal-paragraph">You shall use the Services solely as contemplated in these Terms of Use and shall not directly or indirectly license, sublicense, sell, resell, lease, transfer, assign, distribute, time share or otherwise make the Services available to any third party, except as expressly permitted by these Terms of Use.</p>
      <p className="legal-paragraph">Article 3: Proprietary Rights</p>
      <p className="legal-paragraph">3.1 Reservation of Rights</p>
      <p className="legal-paragraph">Rhombus Labs owns all right, title, and interest, including Intellectual Property Rights, in and to the Services, Site, and any changes or enhancements thereto. Except for the limited rights expressly granted to you, all other rights in and to the Services are reserved by Rhombus Labs and its licensors.
      <p className="legal-paragraph"></p>3.2 Feedback</p>
      <p className="legal-paragraph">Rhombus Labs may use any suggestions, feedback, or ideas provided by you for various purposes, including modifying and improving the Site and Services, without any further obligation to you.
      <p className="legal-paragraph"></p>3.3 End User Data</p>
      <p className="legal-paragraph">You grant Rhombus Labs a worldwide, royalty-free, and non-exclusive license to access any data relating to you that we receive, in order to provide the Services, perform our obligations, improve the Services, and create aggregated and de-identified information.</p>
      <p className="legal-paragraph">Article 4: Term and Termination</p>
      <p className="legal-paragraph">4.1 Term and Termination</p>
      <p className="legal-paragraph">These Terms of Use will commence upon your first use of our Site or Services and will continue until terminated by either party in accordance with the terms and conditions set out in these Terms of Use. We may terminate these Terms of Use at any time by providing written notice to you.
      <p className="legal-paragraph"></p>4.2 Surviving Provisions</p>
      <p className="legal-paragraph">The following provisions shall survive any termination or expiration of these Terms of Use: Article 3, Article 5, Article 6, Article 7.</p>
      <p className="legal-paragraph">Article 5: Warranties, Disclaimers, & Indemnification</p>
      <p className="legal-paragraph">5.1 Warranties</p>
      <p className="legal-paragraph">You represent and warrant that you have the right and authority to enter into these Terms of Use and to grant all rights granted by you in these Terms of Use. Your use of the Site and Services will comply at all times with our Acceptable Use Policy and all applicable laws and regulations.
      <p className="legal-paragraph"></p>5.2 Disclaimer</p>
      <p className="legal-paragraph">THE SITE AND SERVICES ARE PROVIDED "AS IS" AND "WHERE-IS," WITHOUT ANY REPRESENTATION, CONDITION, AND/OR WARRANTY OF ANY KIND. RHOMBUS LABS AND ITS LICENSORS MAKE NO REPRESENTATIONS, WARRANTIES, OR CONDITIONS, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE REGARDING THE SITE OR SERVICES PROVIDED UNDER THESE TERMS OF USE. WE DO NOT WARRANT THAT THE SERVICES WILL BE PROVIDED WITHOUT INTERRUPTION OR BE ERROR-FREE.
      <p className="legal-paragraph"></p>5.3 Indemnification</p>
      <p className="legal-paragraph">You agree to indemnify, defend, and hold Rhombus Labs and its officers, directors, employees, and representatives harmless from any claims, complaints, demands, investigations, actions, suits, and proceedings by any third party arising from your use of the Site or Services or any data, information, or materials you provide.</p>
      <p className="legal-paragraph">Article 6: Limitation of Liability</p>
      <p className="legal-paragraph">6.1 No Customer Relationship with Rhombus Labs; Resolution of Disputes</p>
      <p className="legal-paragraph">You acknowledge and agree that, unless you have entered into a separate services agreement with us, you are not a customer of ours, and any disputes should be resolved directly with the appropriate third parties or organizations.</p>
      <p className="legal-paragraph">6.2 Limitations of Liability</p>
      <p className="legal-paragraph">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL RHOMBUS LABS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, OR LOST PROFITS, ARISING FROM OR RELATED TO THE SERVICES OR THESE TERMS OF USE. OUR TOTAL AGGREGATE LIABILITY SHALL NOT EXCEED $100 USD.</p>
      <p className="legal-paragraph">Article 7: Miscellaneous</p>
      <p className="legal-paragraph">7.1 Interpretation</p>
      <p className="legal-paragraph">For all purposes of these Terms of Use, references to designated sections, paragraphs, and articles are references to the designated sections, paragraphs, and articles of these Terms of Use.</p>
      <p className="legal-paragraph">7.2 Governing Law and Jurisdiction</p>
      <p className="legal-paragraph">These Terms of Use shall be governed by the laws of the Province of Ontario without reference to its choice of law rules. Any disputes shall be resolved in the courts of the Province of Ontario located in Toronto, Ontario.</p>
      <p className="legal-paragraph">7.3 Assignment</p>
      <p className="legal-paragraph">You may not assign any rights or obligations under these Terms of Use without the prior written consent of Rhombus Labs. Rhombus Labs may assign its rights or obligations without your consent.</p>
      <p className="legal-paragraph">7.4 Binding Nature of Agreement</p>
      <p className="legal-paragraph">These Terms of Use shall bind and benefit the parties and their respective successors and permitted assigns.</p>
      <p className="legal-paragraph">7.5 Amendments</p>
      <p className="legal-paragraph">Rhombus Labs may amend these Terms of Use by providing notice to you.</p>
      <p className="legal-paragraph">7.6 Further Assurances</p>
      <p className="legal-paragraph">You agree to do what is necessary to carry out the terms and conditions of these Terms of Use.</p>
      <p className="legal-paragraph">7.7 Provisions Severable</p>
      <p className="legal-paragraph">If any provision of these Terms of Use is held to be invalid, unenforceable, or illegal, the remaining provisions shall remain valid and enforceable.</p>
      <p className="legal-paragraph">7.8 Rights and Remedies Cumulative</p>
      <p className="legal-paragraph">The rights, powers, remedies, and privileges provided in these Terms of Use are cumulative and not exclusive.</p>
      <p className="legal-paragraph">7.9 Survival of Obligations</p>
      <p className="legal-paragraph">The obligations, rights, and remedies of the parties, and all rights and obligations that may have arisen or accrued prior to termination or expiry of these Terms of Use, shall survive termination or expiry.</p>
      <p className="legal-paragraph">7.10 Force Majeure</p>
      <p className="legal-paragraph">Rhombus Labs shall not be liable for any delays caused by events beyond its reasonable control, including acts of God.</p>
      <p className="legal-paragraph">7.11 Entire Agreement</p>
      <p className="legal-paragraph">These Terms of Use constitute the entire agreement between you and Rhombus Labs, superseding all prior agreements and understandings.</p>
      <p className="legal-paragraph">7.12 Language</p>
      <p className="legal-paragraph">The parties have requested that these Terms of Use and all related documents be written in the English language.</p>
      <p className="legal-paragraph">Effective Date: 15 May 2023</p>
    </div>
  );
};

export default Terms;