import React, {useState} from 'react';
import { SketchPicker } from 'react-color';


const CircleProperties = ({ shape, onChange }) => {
  const [isFillColorPickerVisible, setFillColorPickerVisible] = useState(false);
  const [isBorderColorPickerVisible, setBorderColorPickerVisible] = useState(false);
  // include the properties specific to Rectangle here


  const toggleFillColorPicker = () => {
    setFillColorPickerVisible(!isFillColorPickerVisible);
  };

  const toggleBorderColorPicker = () => {
    setBorderColorPickerVisible(!isBorderColorPickerVisible);
  };

  // include the properties specific to Circle here

  const handleColorChange = (color) => {
    onChange({ ...shape, fill: color.hex });
  };

  const handleBorderColorChange = (color) => {
    onChange({ ...shape, stroke: color.hex });
  };

  const handleBorderThicknessChange = (event) => {
    const newStrokeWidth = parseInt(event.target.value);
    onChange({ ...shape, strokeWidth: newStrokeWidth });
  };

  return (
    <div className="shape-specific-properties">
      <div className="shape-property">
        <label className="shape-property-label" onClick={toggleFillColorPicker}>Fill Color</label>
        {isFillColorPickerVisible && (
          <SketchPicker color={shape.fill || ''} onChangeComplete={handleColorChange} />
        )}     
      </div>
      <div className="shape-property">
        <label className="shape-property-label" onClick={toggleBorderColorPicker}>Border color</label>
        {isBorderColorPickerVisible && (
          <SketchPicker color={shape.stroke || ''} onChangeComplete={handleBorderColorChange} />
        )}
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Border thickness</label>
        <input type="number" value={shape.strokeWidth || ''} onChange={handleBorderThicknessChange}   style={{width: '30px'}}/>
      </div>
    </div>
  );
};

export default CircleProperties;
