import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileForm from './ProfileForm';
import StoreLocationForm from './StoreLocationForm';


const Welcome = ({ onProfileFilled, onUserLocationSetup, userHasFilledProfile, userHasSetupLocation, userCountry, setUserCountry, userOrganizationId, setUserOrganizationId }) => {
  const { t } = useTranslation();
  const [showProfileForm, setShowProfileForm] = useState(true);


  useEffect(() => {
    if (userHasFilledProfile && !userHasSetupLocation) {
      console.log(userHasFilledProfile);
      console.log(userHasSetupLocation);
      setShowProfileForm(false);
    }
  }, [userHasFilledProfile, userHasSetupLocation]);

  const handleProfileSubmit = (profileCountry, profileOrganizationId) => {
    onProfileFilled();
    setShowProfileForm(false);
    setUserCountry(profileCountry);
    setUserOrganizationId(profileOrganizationId);
  };
const handleUserLocationSetup = () => {
  onUserLocationSetup();
}



  return (
    <div>
      <main>
        <section id="signup-form" className="section background-light">
          <div className="container">
            <div className="signup-wrapper">
              <div className="signup-headline">
                <h2 className="primary-text">{t('welcome.sign-up-form.heading')}</h2>
                <h4 className='secondary-text'>{t('welcome.sign-up-form.body-copy')}</h4>
              </div>
              {showProfileForm ? (
                <ProfileForm onProfileSubmit={handleProfileSubmit} />
              ) : (
                <StoreLocationForm onUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} userOrganizationId={userOrganizationId}/>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Welcome;
