import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const LineProperties = ({ shape, onChange }) => {
  const [isLineColorPickerVisible, setLineColorPickerVisible] = useState(false);

  const toggleLineColorPicker = () => {
    setLineColorPickerVisible(!isLineColorPickerVisible);
  };

  const handleLineColorChange = (color) => {
    onChange({ ...shape, stroke: color.hex });
  };

  const handleLineThicknessChange = (event) => {
    const newStrokeWidth = parseInt(event.target.value);
    onChange({ ...shape, strokeWidth: newStrokeWidth });
  };

  const handleLineRotationChange = (event) => {
    const newRotation = parseFloat(event.target.value);
    onChange({ ...shape, rotation: newRotation });
  };

  return (
    <div className="shape-specific-properties">
      <div className="shape-property">
        <label className="shape-property-label" onClick={toggleLineColorPicker}>Line Color</label>
        {isLineColorPickerVisible && (
          <SketchPicker color={shape.stroke || ''} onChangeComplete={handleLineColorChange} />
        )}
      </div>
      <div className="shape-property">
        <label className="shape-property-label">Line Thickness</label>
        <select value={shape.strokeWidth || ''} onChange={handleLineThicknessChange}>
          {[...Array(30).keys()].map(i => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
    {/*  <div className="shape-property">
        <label className="shape-property-label">Line Orientation (degrees)</label>
        <input type="number" value={shape.rotation || 0} onChange={handleLineRotationChange} style={{ width: '60px' }} />
      </div> */}
    </div>
  );
};

export default LineProperties;
