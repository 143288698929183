import React, { useEffect } from 'react';
import { Ring, Transformer } from 'react-konva';

const RingShape = ({ 
  id, 
  x, 
  y, 
  innerRadius, 
  outerRadius, 
  fill, 
  stroke, 
  strokeWidth,
  draggable,
  selected, 
  onSelect, 
  onChange, 
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

 

  useEffect(() => {
    if (selected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  return (
    <>
      <Ring 
        ref={shapeRef}
        id={id}
        x={x} 
        y={y} 
        innerRadius={innerRadius} 
        outerRadius={outerRadius} 
        fill={fill} 
        draggable={draggable}
        stroke={stroke}
        strokeWidth={strokeWidth}
        shadowBlur={selected ? 10 : 0} // Apply shadow effect when selected
        shadowColor={selected ? 'black' : null} // The shadow color when selected
        onDragEnd={e => {
          onChange({ id, type: 'ring', x: e.target.x(), y: e.target.y(), innerRadius, outerRadius, fill, stroke, strokeWidth, draggable });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            id,
            type: 'ring',
            x: node.x(),
            y: node.y(),
            innerRadius: Math.max(5, node.innerRadius() * scaleX),
            outerRadius: Math.max(10, node.outerRadius() * scaleY),
            fill,
            stroke,
            strokeWidth,
            draggable
          });
        }}
        onClick={onSelect}
        onTap={onSelect}
      />
      {selected && <Transformer ref={trRef} />}
    </>
  );
};

export default RingShape;
