import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/root.reducer';

const store = configureStore({
  reducer: rootReducer,
  // you can add more options here, like middleware
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
