import React, { useState, useEffect,useMemo, memo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useDrop } from 'react-dnd';

import CellTemplate from '../CellTemplates/CellTemplate';
import RectangleShape from '../Shapes/Rectangle/RectangleShape';
import CircleShape from '../Shapes/Circle/CircleShape';
import ArcShape from '../Shapes/Arc/ArcShape';
import ArrowShape from '../Shapes/Arrow/ArrowShape';
import ImageShape from '../Shapes/Image/ImageShape';
import TextShape from '../Shapes/Text/TextShape';
import LineShape from '../Shapes/Line/LineShape';
import TagShape from '../Shapes/Tag/TagShape';
import StarShape from '../Shapes/Star/StarShape';
import RingShape from '../Shapes/Ring/RingShape';


const CELL_SIZE_REDUCTION_FACTOR = 0.25;
const CELL_HEIGHT = 100; // The size of each cell in the grid

const GridItem = memo(function GridItem({ index, cell, assignAdTemplate, shapesLayerRef, setShapesLayerRef, cell_width, cell_height, spanWidth, spanHeight, originalTemplateWidth, originalTemplateHeight, isSelected, onClick}) {
  const [borderStyle, setBorderStyle] = useState('1px dashed #ccc'); // New state variable

  useEffect(() => {
    if (cell) {
      setBorderStyle('none');
    } else {
      setBorderStyle('1px dashed #ccc');
    }
  }, [cell]);

  useEffect(() => {
    if (isSelected) {
      setBorderStyle('1px dashed #808080'); // Highlight selected cells
    } else if (cell_width ===0 || cell) {
      setBorderStyle('none');
    } else {
      setBorderStyle('1px dashed #ccc');
    }
  }, [cell, isSelected]);

  useEffect(() => {
    if(cell_width === 0) {
      setBorderStyle('none');
    } else if (cell_height === 0) {
      setBorderStyle('none');
    }
  }, [cell_width, cell_height]);
  
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'template',
    drop: (item, monitor) => {
      assignAdTemplate(index, item.id);
      //setBorderStyle('none'); // Set isTemplateDropped to true when a template is dropped
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  // Memoize the scaled shapes to avoid recalculating them on every render
  const scaledShapes = useMemo(() => {
    if (!cell) return [];
    return cell.map(shape => scaleShape(shape, cell_width, cell_height));
  }, [cell, cell_width, cell_height]);

  function scaleShape(shape, cellWidth, cellHeight) {

    const scale_x = cellWidth / originalTemplateWidth;
    const scale_y = cellHeight / originalTemplateHeight;

    const scaledShape = {...shape};
    // For all shapes
    if (shape.x) scaledShape.x *= scale_x;
    if (shape.y) scaledShape.y *= scale_y;
    
    if (shape.strokeWidth) scaledShape.strokeWidth *= scale_y;
  
    // For shapes with width and height (rectangle, image)
    if (shape.width) scaledShape.width *= scale_x;
    if (shape.height) scaledShape.height *= scale_y;

    // For circular shapes (circle, arc)
    if (shape.radius) scaledShape.radius *= scale_x;

    // For rounded rectangles
    if (shape.cornerRadius) scaledShape.cornerRadius *= scale_x;

    // For arc
    if (shape.innerRadius) scaledShape.innerRadius *= scale_x;
    if (shape.outerRadius) scaledShape.outerRadius *= scale_x;
  
    // For arrow
    if (shape.points) {
      scaledShape.points = shape.points.map(point => point * scale_x);
    }
  
    // For text
    if (shape.fontSize) {
      scaledShape.fontSize *=  scale_x;

    }  

    
    // New scaling properties for text  
    if (!shape.fontSize && shape.lineHeight) scaledShape.lineHeight *= scale_y;
    if (!shape.fontSize && shape.padding) scaledShape.padding *= scale_x;

    scaledShape.draggable = false;
    return scaledShape;
  }

  return (
    <div 
      className='grid-item'
      ref={drop}
      style={{ display: cell_width === 0 ? 'none' : 'block', width: cell_width, height: cell_height, gridColumn: 'span ' + spanWidth, gridRow: 'span ' + spanHeight, border: borderStyle, backgroundColor: isOver ? '#f0f0f0' : 'white', margin:'0' }}
      key={index}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onClick={onClick}
    >
      {scaledShapes && (
        <CellTemplate
          width={cell_width}
          height={cell_height}
          onShapesLayerReady={(shapesLayer) => setShapesLayerRef(shapesLayer)}
        >
          {scaledShapes.map((scaledShape) => {
            switch (scaledShape.type) {
              case 'rectangle':
                return <RectangleShape {...scaledShape} key={scaledShape.id} />;
              case 'circle':
                return <CircleShape {...scaledShape} key={scaledShape.id} />;
              case 'arc':
                return <ArcShape {...scaledShape} key={scaledShape.id} />;
              case 'arrow':
                return <ArrowShape {...scaledShape} key={scaledShape.id} />;
              case 'image':
                return <ImageShape {...scaledShape} key={scaledShape.id} />;
              case 'text':
                return <TextShape {...scaledShape} key={scaledShape.id} />;
              case 'line':
                return <LineShape {...scaledShape} key={scaledShape.id} />;
              case 'tag':
                return <TagShape {...scaledShape} key={scaledShape.id} />;
              case 'star':
                return <StarShape {...scaledShape} key={scaledShape.id} />;
              case 'ring':
                return <RingShape {...scaledShape} key={scaledShape.id} />;
              default:
                return null;
            }
          })}
        </CellTemplate>
      )}
    </div>
  );
});

function FlyerTemplate({ id, grid, setGrid, gridDimensions, selectedCells, setSelectedCells  }) {
  const {getAccessTokenSilently} = useAuth0();
  const [shapesLayerRef, setShapesLayerRef] = useState(null);

  const number_of_cells = gridDimensions === '4x4' ? 4 : (gridDimensions === '4x3' ? 3 : null);
  const cell_width = gridDimensions === '4x4' ? 75: 100;
//  const originalTemplateWidth = gridDimensions === '4x4' ? 300: 400; // Original size of the templates
  
  // Function to assign a template to a cell
  const assignAdTemplate = useCallback(async (cellIndex, selectedAdTemplateId) => {
    const adTemplateId = selectedAdTemplateId;

    // Fetch the template from the backend
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/templates/${adTemplateId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // If the template exists
    if (response.data.data) {
      const adTemplateData = JSON.parse(response.data.data);
      const adDimensions = response.data.ad_dimensions;
      // Update the grid
      setGrid(prevGrid => {
        const newGrid = [...prevGrid];
        
        if(adDimensions==='1x3/4') {
          newGrid[cellIndex].content = adTemplateData;
          newGrid[cellIndex].templateWidth=300;
          newGrid[cellIndex].templateHeight=400;
        } else if(adDimensions==='1x3') {
          newGrid[cellIndex].content = adTemplateData;
          newGrid[cellIndex].templateWidth=1200;
          newGrid[cellIndex].templateHeight=400;
        } else if(adDimensions==='1x3/2') {
          newGrid[cellIndex].content = adTemplateData;
          newGrid[cellIndex].templateWidth=600;
          newGrid[cellIndex].templateHeight=400;  
        } else if(adDimensions==='4x1') {
          newGrid[cellIndex].content = adTemplateData;
          newGrid[cellIndex].templateWidth=400;
          newGrid[cellIndex].templateHeight=1600;
        } else {
          newGrid[cellIndex].content = adTemplateData;
        }
        return newGrid;
      });
    } else {
      alert(`No template found with ID: ${adTemplateId}`);
    }
  }, [getAccessTokenSilently, setGrid]);

  const toggleCellSelection =  (index) => {
    setSelectedCells(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

 

  return (
    <div className="flyer-template-canvas" style={{width: ((cell_width * number_of_cells) + (24*3))}}>
      <div className="flyer-grid">
        <div id={id} style={{ display: 'grid', gridTemplateColumns: 'repeat('+ parseInt(gridDimensions.split('x')[1], 10)+', 1fr)', columnGap:'0', rowGap: '0', width: (cell_width * number_of_cells) }}> {/* Wrapper div for the grid */}
          {Array.isArray(grid) && grid.map((gridCell, index) =>  (
            <GridItem 
            key={index} 
            index={index} 
            cell={gridCell.content} 
            assignAdTemplate={assignAdTemplate} 
            shapesLayerRef={shapesLayerRef} 
            setShapesLayerRef={setShapesLayerRef}
            cell_width={gridCell.dimensions.width * CELL_SIZE_REDUCTION_FACTOR} 
            cell_height={gridCell.dimensions.height * CELL_SIZE_REDUCTION_FACTOR} 
            spanWidth={gridCell.spanWidth}
            spanHeight={gridCell.spanHeight}
            isSelected={selectedCells.includes(index)}
            onClick={() => {toggleCellSelection(index)}}
            originalTemplateWidth={gridCell.templateWidth}
            originalTemplateHeight={gridCell.templateHeight}
            />
          ))}
        </div>
      </div>
      
    </div>
  );
}

FlyerTemplate.defaultProps = {
  grid: [],
};

export default FlyerTemplate;