import React, { useState } from 'react';
import FormInputText from './stories/FormInputText';
import FormInputPhoneNumber from './stories/FormInputPhoneNumber';
import FormInputDropDown from './stories/FormInputDropDown'; // Assuming this is similar to FormInputText but for dropdowns
import FormButton from './stories/FormButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';



// Example data - replace with your actual state data
const statesByCountry = {
    USA: [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
        'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
        'American Samoa', 'Guam', 'Northern Mariana Islands', 'Puerto Rico', 'U.S. Virgin Islands', 'District of Columbia'
      ],
      Canada: [
        'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories',
        'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'
      ]
};

const StoreLocationForm = ({ onUserLocationSetup, userCountry, userOrganizationId }) => {
  const [storeLocations, setStoreLocations] = useState([
    { organization_id: 13, storeName: '', addressLine1: '', addressLine2: '', city: '', state: '', postalCode: '', country: 'USA', storePhoneNumber: '', storeWebsite: '', storeInstagram: '',  managerFirstName:'', managerLastName:'',managerEmail:'', managerPhoneNumber:''}
  ]);
  const [errors, setErrors] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();


  const handleInputChange = (index, e) => {
    const newStoreLocations = [...storeLocations];
    newStoreLocations[index][e.target.name] = e.target.value;
    setStoreLocations(newStoreLocations);
    // Reset errors for this index
    const newErrors = [...errors];
    newErrors[index] = {...newErrors[index], [e.target.name]: ''};
    setErrors(newErrors);
  };

  const addStoreLocation = () => {
    const newLocation = {
        organization_id: userOrganizationId,
        storeName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: userCountry,
        storePhoneNumber: '', // Ensure this is initialized
        storeEmail: '', // Initialize this as well
        storeWebsite: '',
        storeInstagram: '',
        managerFirstName: '',
        managerLastName: '',
        managerEmail: '',
        managerPhoneNumber: ''
    };
    setStoreLocations([...storeLocations, newLocation]);
    setErrors([...errors, {}]);
};

  const removeStoreLocation = (index) => {
    const newStoreLocations = [...storeLocations];
    newStoreLocations.splice(index, 1);
    setStoreLocations(newStoreLocations);
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{3}-\d{3}-\d{4}$/.test(phoneNumber);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const submitStoreLocation = async (location, token) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/api/retailerlocations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(location),
    });

    if (!response.ok) {
      throw new Error('Failed to submit store location');
    }

    return await response.json();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let validationErrors = storeLocations.map(location => {
      let locationErrors = {};
  
      // Add validation logic here for each location
      if (!location.storeName) locationErrors.storeName = 'Store Name is required';
      if (!location.addressLine1) locationErrors.addressLine1 = 'Address Line 1 is required';
      if (!location.city) locationErrors.city = 'City is required';
      if (!location.state) locationErrors.state = 'State is required';
      if (!location.postalCode) locationErrors.postalCode = 'Postal Code is required';
      if (!validatePhoneNumber(location.storePhoneNumber)) locationErrors.storePhoneNumber = 'Invalid phone number format';
      if (!validateEmail(location.storeEmail)) locationErrors.storeEmail = 'Invalid email format';
  
      // Phone Number Validation (US/Canada format)
      if (!/^\d{3}-\d{3}-\d{4}$/.test(location.storePhoneNumber)) {
        locationErrors.storePhoneNumber = 'Invalid phone number format (e.g., 123-456-7890)';
      }
  
      // Store Website Validation (basic URL format)
      if (location.storeWebsite && !/^https?:\/\/\S+\.\S+$/.test(location.storeWebsite)) {
        locationErrors.storeWebsite = 'Invalid website URL';
      }
  
      // Postal Code Validation (US and Canada formats)
      const usPostalCodeRegex = /^\d{5}(-\d{4})?$/;
      const caPostalCodeRegex = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;
      if (location.state && statesByCountry['USA'].includes(location.state) && !usPostalCodeRegex.test(location.postalCode)) {
        locationErrors.postalCode = 'Invalid US postal code';
      }
      if (location.state && statesByCountry['Canada'].includes(location.state) && !caPostalCodeRegex.test(location.postalCode)) {
        locationErrors.postalCode = 'Invalid Canadian postal code';
      }
  
      return locationErrors;
    });
  
    setErrors(validationErrors);
  
    // Check if any location has validation errors
  if (validationErrors.some(locationError => Object.keys(locationError).length > 0)) {
    console.error('Please correct the errors before submitting.');
    validationErrors.forEach((locationError, index) => {
      if (Object.keys(locationError).length > 0) {
        console.error(`Errors in location ${index + 1}:`, locationError);
      }
    });
    return;
  }

    try {
      const token = await getAccessTokenSilently();
  
      // Log storeLocations for debugging
      console.log('Submitting the following store locations:', storeLocations);
  
      const results = await Promise.all(storeLocations.map(location => {
        // Log each location being submitted
        return submitStoreLocation(location, token);
      }));
  
      onUserLocationSetup();
      navigate('/dashboard');
    } catch (error) {
      // Log the detailed error message
      console.error('Error submitting locations:', error.message);
      console.error('Error stack:', error.stack);
      // Optionally, display an alert to the user
      console.error('An error occurred while submitting store locations. Please try again.');
    }
  };
  
    

  return (
    <div className="div-block">
      <form className="store-location-form" onSubmit={handleSubmit}>
        {storeLocations.map((location, index) => (
          <div key={index} className='location-section'>
          <h3>Store Location {index + 1}</h3>
          <div className="form-row">
            <FormInputText
              label="Store Name"
              value={location.storeName}
              onChange={(e) => handleInputChange(index, e)}
              name="storeName"
              width="40%"
              errorMessage={errors[index]?.storeName}
            />
            </div>
            <div className="form-row">
            <FormInputText
              label="Address Line 1"
              value={location.addressLine1}
              onChange={(e) => handleInputChange(index, e)}
              name="addressLine1"
              width="40%"
              errorMessage={errors[index]?.addressLine1}
            />
            <FormInputText
              label="Address Line 2"
              value={location.addressLine2}
              onChange={(e) => handleInputChange(index, e)}
              name="addressLine2"
              width="40%"
            />
            </div>
            <div className="form-row">
            <FormInputText
              label="City"
              value={location.city}
              onChange={(e) => handleInputChange(index, e)}
              name="city"
              width="40%"
              errorMessage={errors[index]?.city}
            />
            <FormInputDropDown
              label="State"
              value={location.state}
              onChange={(e) => handleInputChange(index, e)}
              name="state"
              width="40%"
              options={statesByCountry['USA'].map(state => ({ value: state, label: state }))}
              errorMessage={errors[index]?.state}
            />
            </div>
            <div className="form-row">
            <FormInputText
              label="Postal Code"
              value={location.postalCode}
              onChange={(e) => handleInputChange(index, e)}
              name="postalCode"
              width="40%"
              errorMessage={errors[index]?.postalCode}
            />
            <FormInputPhoneNumber
              label="Store Phone Number"
              value={location.storePhoneNumber}
              onChange={(e) => handleInputChange(index, e)}
              name="storePhoneNumber"
              width="40%"
              errorMessage={errors[index]?.storePhoneNumber}
            />
            </div>
            <div className="form-row">
            <FormInputText
              label="Store Website"
              value={location.storeWebsite}
              onChange={(e) => handleInputChange(index, e)}
              width="40%"
              name="storeWebsite"
            />
            <FormInputText
                label="Store Email"
                value={location.storeEmail}
                onChange={(e) => handleInputChange(index, e)}
                name="storeEmail"
                width="40%"
                errorMessage={errors[index]?.storeEmail}
              />
            </div>
            <div className="form-row">
            <FormInputText
              label="Store Instagram Handle"
              value={location.storeInstagram}
              onChange={(e) => handleInputChange(index, e)}
              name="storeInstagram"
              width="40%"
            />
            </div>
            <div className="form-row">
              <FormInputText
                label="Store Manager First Name"
                value={location.managerFirstName}
                onChange={(e) => handleInputChange(index, e)}
                name="managerFirstName"
                width="40%"
                errorMessage={errors[index]?.managerFirstName}
              />
              <FormInputText
                label="Store Manager Last Name"
                value={location.managerLastName}
                onChange={(e) => handleInputChange(index, e)}
                name="managerLastName"
                width="40%"
                errorMessage={errors[index]?.managerLastName}
              />
            </div>
            <div className="form-row">
              <FormInputText
                label="Store Manager Email"
                value={location.managerEmail}
                onChange={(e) => handleInputChange(index, e)}
                name="managerEmail"
                width="40%"
                errorMessage={errors[index]?.managerEmail}
              />
              <FormInputPhoneNumber
                label="Store Manager Phone Number"
                value={location.managerPhoneNumber}
                onChange={(e) => handleInputChange(index, e)}
                name="managerPhoneNumber"
                width="40%"
                errorMessage={errors[index]?.managerPhoneNumber}
              />
            </div>
            {storeLocations.length > 1 && (
              <button type="button" onClick={() => removeStoreLocation(index)}>Remove</button>
            )}
          </div>
        ))}
        <div className="form-row">
            <FormButton className="Button" type="button" transparent onClick={addStoreLocation}>+ Add Another Store</FormButton>
            <FormButton className="Button" type="submit">Submit Locations</FormButton>
        </div>
      </form>
    </div>
  );
};

export default StoreLocationForm;
