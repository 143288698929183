import React, { useEffect } from 'react';
import { Arc, Transformer } from 'react-konva';

const ArcShape = ({ 
  id, 
  x, 
  y, 
  innerRadius, 
  outerRadius, 
  angle, 
  fill, 
  stroke, 
  strokeWidth, 
  cornerRadius, 
  draggable,
  selected,
  onSelect, 
  onChange
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();


  useEffect(() => {
    if (selected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  return (
    <>
      <Arc
        ref={shapeRef}
        id={id}
        x={x} 
        y={y} 
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        angle={angle}
        fill={fill} 
        draggable={draggable}
        stroke={stroke}
        strokeWidth={strokeWidth}
        cornerRadius={cornerRadius}
        shadowBlur={selected ? 10 : 0} // Apply shadow effect when selected
        shadowColor={selected ? 'black' : null} // The shadow color when selected
        onDragEnd={e => {
          onChange({ id, type: 'arc', x: e.target.x(), y: e.target.y(), innerRadius, outerRadius, angle, fill, stroke, strokeWidth, cornerRadius, draggable });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();

          node.scaleX(1);

          onChange({
            id,
            type: 'arc',
            x: node.x(),
            y: node.y(),
            innerRadius: node.innerRadius() * scaleX,
            outerRadius: node.outerRadius() * scaleX,
            angle: node.angle(),
            fill,
            stroke,
            strokeWidth,
            cornerRadius,
            draggable
          });
        }}
        onClick={onSelect}
        onTap={onSelect}
      />
      {selected && <Transformer ref={trRef} />}
    </>
  );
};

export default ArcShape;
