import React, { useState } from 'react';
import FormInputText from '../../stories/FormInputText';
import FormInputDatePicker from '../../stories/FormInputDatePicker';
import FormButton from '../../stories/FormButton';
import FlyerTemplatePreview from '../FlyerTemplates/FlyerTemplatePreview';

export function Step1({ onNext, setFlyerDetails }) {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [titleError, setTitleError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const handleNext = () => {
    let hasError = false;

    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Set to start of the day for accurate comparison


    if (title.trim() === '') {
      setTitleError('Flyer title is required!');
      hasError = true;
    } else {
      setTitleError('');
    }

    if (!startDate) {
      setStartDateError('Start date is required!');
      hasError = true;
    } else if (new Date(startDate) < today) {
      setStartDateError('Start date can\'t be in the past!');
      hasError = true;
    } else {
      setStartDateError('');
    }

    if (!endDate) {
      setEndDateError('End date is required!');
      hasError = true;
    } else if (new Date(endDate) < new Date(startDate)) {
      setEndDateError('End date must be greater than or equal to start date!');
      hasError = true;
    } else {
      setEndDateError('');
    }

    if (!hasError) {
      setFlyerDetails({ title, startDate, endDate });
      onNext();
    }
  };

  return (
    <main>
    <div className="div-block">
      <span>Enter Flyer details</span>
    </div>
      <div className="div-block">
        <div className="flyer-form">
          <FormInputText
            placeholder="Flyer Title*" 
            value={title} 
            onChange={e => setTitle(e.target.value)} 
            isRequired={true}
            width="40%"
            errorMessage={titleError}
          />
          <FormInputDatePicker
            placeholder="Start Date*"
            type="date"
            value={startDate}
            onChange={date => setStartDate(date)}
            isRequired={true} 
            errorMessage={startDateError}
          />
          <FormInputDatePicker
            placeholder="End Date*"
            type="date"
            value={endDate}
            onChange={date => setEndDate(date)}
            isRequired={true}
            errorMessage={endDateError}
          />
        </div>
        <div className="form-buttons">
          <FormButton className="Button" onClick={handleNext}>Continue</FormButton>
        </div>
      </div>
    </main>
  );
}

export function Step2({ onNext, getAccessTokenSilently, flyerTemplates, flyerDetails, setSelectedTemplate, highlightedTemplateId, setHighlightedTemplateId, setCreatedFlyer }) {
    const handleTemplateClick = (templateId) => {
        setHighlightedTemplateId(templateId);
    };
  
    const handleContinueClick = async () => {
  
        if (highlightedTemplateId == null) {
          return;
        }
        setSelectedTemplate(highlightedTemplateId);
        
        // Prepare the flyer data based on your backend's requirements
        const flyerData = {
          flyer_title: flyerDetails.title,
          start_date: flyerDetails.startDate,
          end_date: flyerDetails.endDate,
          template_id: highlightedTemplateId
        };
        
        try {
          const newFlyer = await createFlyer(flyerData, getAccessTokenSilently);
          const startDate = new Date(newFlyer.result.start_date);
          const endDate = new Date(newFlyer.result.end_date);
          setCreatedFlyer(prevFlyer => ({
            ...prevFlyer,
            ...newFlyer.result,
            start_date: startDate,
            end_date: endDate
        }));
         onNext(); // Proceed to the next step if flyer creation is successful
        } catch (error) {
          console.error('Error while creating the flyer:', error);
        }
    };
  
    return (
      <main>
      <div className="div-block">
        <span>Select a template to begin</span>
      </div>
      <div className="div-block">
        <div className='flyer-templates'>
            {flyerTemplates.map((template, index) => (
                <div className="template-preview"
                    key={index} 
                    onClick={() => handleTemplateClick(template.id)} 
                    style={{
                        border: template.id === highlightedTemplateId ? '2px solid blue' : 'none'
                    }}
                  >
                    <FlyerTemplatePreview template={template} />
                </div>
            ))}
        </div>
        <div className="form-buttons">
          <FormButton className="Button" onClick={handleContinueClick}>Create Flyer</FormButton>
        </div>
      </div>
      </main>
    );
}

export const createFlyer = async(flyerData, getAccessTokenSilently) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyers/`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(flyerData)
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
}
