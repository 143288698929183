// EmailVerificationHandler.js
import React, { useState, useEffect } from 'react';
import FormInputText from './stories/FormInputText';
import { useAuth0 } from '@auth0/auth0-react';


const EmailVerificationHandler = () => {
    const [emailToVerify, setEmailToVerify] = useState('');
    const [showResend, setShowResend] = useState(false);
    const [message, setMessage] = useState('');
    const { logout } = useAuth0();

    useEffect(() => {
            setMessage('Please verify your email address. Check your inbox for a verification email. To resend the verification email, enter your email below and press Resend.');
            setShowResend(true);
    }, [logout]);

    const handleResendEmail = async () => {
        // Check if email is empty
        if (!emailToVerify) {
            setMessage("Please enter an email address.");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/api/verification/resend-verification-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: emailToVerify }),
            });

            if (response.ok) {
                setMessage('Verification email resent. Please check your inbox.');
            } else {
                const errorData = await response.json();
                setMessage(errorData.message || 'An error occurred while resending the email.');
            }
        } catch (error) {
            console.error('Error in resending email:', error);
            setMessage('An error occurred while trying to resend the verification email.');
        }
    };

    return (
        <div className="email-verification-pending">
            {message && <p>{message}</p>}
            {showResend && (
                <div>
                    <FormInputText
                        type="email"
                        placeholder="Enter your email"
                        value={emailToVerify}
                        onChange={(e) => setEmailToVerify(e.target.value)}
                        width="30%"
                    />
                    <button className="Button" onClick={handleResendEmail}>Resend Verification Email</button>
                </div>
            )}
        </div>
    );
};

export default EmailVerificationHandler;
