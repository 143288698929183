import React from 'react';
import './ShapePropertyManager.css';
import RectangleProperties from './RectangleProperties';
import CircleProperties from './CircleProperties';
import ArcProperties from './ArcProperties';
import StarProperties from './StarProperties';
import RingProperties from './RingProperties';
import ImageProperties from './ImageProperties';
import TextProperties from './TextProperties';
import LineProperties from './LineProperties';

const ShapePropertyManager = ({ 
  shape, 
  onChange, 
  onDelete,
  onMoveShapeUp,
  onMoveShapeDown 
}) => {
  
  if (!shape) return null;

  const getShapeProperties = () => {
    switch(shape.type) {
      case 'rectangle':
        return <RectangleProperties shape={shape} onChange={onChange} />;
      case 'circle':
        return <CircleProperties shape={shape} onChange={onChange} />;
      case 'arc':
        return <ArcProperties shape={shape} onChange={onChange} />;
      case 'image':
          return <ImageProperties shape={shape} onChange={onChange} />;
      case 'star':
        return <StarProperties shape={shape} onChange={onChange} />;
      case 'ring':
        return <RingProperties shape={shape} onChange={onChange} />;
      case 'line':
          return <LineProperties shape={shape} onChange={onChange} />;  
      case 'text':
          return <TextProperties shape={shape} onChange={onChange} />;  
      default:
        return null;
    }
  };

  return (
    <div className="shape-property-buttons">
        <div className="shape-property">
        <label onClick={() => onMoveShapeUp(shape.id)} className="shape-property-label" >Move Up</label>
        </div>
        <div className="shape-property">
        <label onClick={() => onMoveShapeDown(shape.id)} className="shape-property-label" >Move Down</label>
        </div>
        <div className="shape-property">
        <label onClick={() => onDelete(shape.id)} className="shape-property-label" >Delete</label>
        </div>
        {getShapeProperties()}
    </div>
  );
};

export default ShapePropertyManager;
