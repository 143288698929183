import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';



const Sidebar = ({navItems}) => {
    const { logout} = useAuth0(); // Fetch user and isAuthenticated flag
    const { t, i18n } = useTranslation(); 

    return (
        <div className="sidebar">
            <div id="side_logo" className="sidebar-nav-div">
                <div className="sidebar-nav-logo">
                    <img src="/adgro-logo-white-p-500.png"  sizes="(max-width: 767px) 10px, 1vw"  alt="" className="sidebar-nav-logo-image"></img>
                </div>        
            </div>
            <div id="top_side_nav" className="sidebar-nav-div">
                <ul className="sidebar-nav-list sidebar-nav-list-unstyled">
                    {navItems === "DASHBOARD" && (
                        <>
                            <li id="dashboard-link" className="sidebar-nav-list-item"><Link to="/dashboard" className="sidebar-nav-list-item">{t('dashboard-nav.main-nav.dashboard')}</Link></li>
                            <li id="product-catalog-link" className="sidebar-nav-list-item sidebar-nav-list-item-expandable"><Link to="/products" className="sidebar-nav-list-item">{t('dashboard-nav.main-nav.product-catalog')}</Link></li>
                            <li id="flyers-link" className="sidebar-nav-list-item sidebar-nav-list-item-expandable"><Link to="/flyers" className="sidebar-nav-list-item">{t('dashboard-nav.main-nav.flyers')}</Link></li>
                            <li id="distribution-link" className="sidebar-nav-list-item sidebar-nav-list-item-expandable">{t('dashboard-nav.main-nav.distribution')}</li>
                            <li id="analytics-and-insights-link" className="sidebar-nav-list-item sidebar-nav-list-item-expandable">{t('dashboard-nav.main-nav.analytics')}</li>
                        </>
                    )}
                    {navItems === "PRODUCTS" && (
                        <>
                            <li id="dashboard-link" className="sidebar-nav-list-item"><Link to="/dashboard" className="sidebar-nav-list-item">❮&nbsp;{t('dashboard-nav.main-nav.back-to')}</Link></li>
                            <li className="sidebar-nav-list-item">&nbsp;</li>
                            <li id="product-catalog-link" className="sidebar-nav-list-item"><Link to="/products" className="sidebar-nav-list-item">{t('dashboard-nav.products-nav.overview')}</Link></li>
                            <li id="product-add-new-link" className="sidebar-nav-list-item"><Link to="/addproduct" className="sidebar-nav-list-item">{t('dashboard-nav.products-nav.new')}</Link></li>
                            <li id="product-add-new-link" className="sidebar-nav-list-item"><Link to="/scanproduct" className="sidebar-nav-list-item">{t('dashboard-nav.products-nav.scan')}</Link></li>
                            <li id="product-add-new-link" className="sidebar-nav-list-item"><Link to="/bulkuploadproducts" className="sidebar-nav-list-item">{t('dashboard-nav.products-nav.upload')}</Link></li>
                        </>
                    )}
                    {navItems === "FLYERS" && (
                        <>
                            <li id="flyers-link" className="sidebar-nav-list-item"><Link to="/dashboard" className="sidebar-nav-list-item">❮&nbsp;{t('dashboard-nav.main-nav.back-to')}</Link></li>
                            <li className="sidebar-nav-list-item">&nbsp;</li>
                            <li id="flyers-link" className="sidebar-nav-list-item"><Link to="/flyers" className="sidebar-nav-list-item">{t('dashboard-nav.flyers-nav.overview')}</Link></li>
                            <li id="flyers-add-new-link" className="sidebar-nav-list-item"><Link to="/addflyer" className="sidebar-nav-list-item">{t('dashboard-nav.flyers-nav.new-flyer')}</Link></li>
                            <li id="flyers-templates-link" className="sidebar-nav-list-item"><Link to="/flyertemplates" className="sidebar-nav-list-item">{t('dashboard-nav.flyers-nav.flyer-templates')}</Link></li>
                            <li id="flyers-add-new-flyertemplate-link" className="sidebar-nav-list-item"><Link to="/flyertemplatemanager" className="sidebar-nav-list-item">{t('dashboard-nav.flyers-nav.new-flyer-template')}</Link></li>
                            <li id="flyers-ad-templates-link" className="sidebar-nav-list-item"><Link to="/adtemplates" className="sidebar-nav-list-item">{t('dashboard-nav.flyers-nav.ad-templates')}</Link></li>
                            <li id="flyers-add-new-product-ad-template-link" className="sidebar-nav-list-item"><Link to="/adtemplatemanager" className="sidebar-nav-list-item">{t('dashboard-nav.flyers-nav.new-ad-template')}</Link></li>
                        </>
                    )}
                </ul>
            </div>
            <div id="mid_side_nav" className="sidebar-nav-div">
                <ul className="sidebar-nav-list sidebar-nav-list-unstyled">
                    <li id="pos-integration-link" className="sidebar-nav-list-item">{t('dashboard-nav.main-nav.invite')}</li>
                    <li id="pos-integration-link" className="sidebar-nav-list-item sidebar-nav-list-item-expandable">{t('dashboard-nav.main-nav.settings')}</li>
                    <li id="account-and-billing-link" className="sidebar-nav-list-item sidebar-nav-list-item-expandable">{t('dashboard-nav.main-nav.account')}</li>
                    <li className="sidebar-nav-list-item">&nbsp;</li>
                    <li id="logout-link" className="sidebar-nav-list-item"><Link className="sidebar-nav-list-item" onClick={() => logout({ returnTo: window.location.origin })}>{t('dashboard-nav.main-nav.logout')}</Link></li>
                </ul>
            </div>
            <div id="bottom_side_nav" className="sidebar-nav-div">
                <ul className="sidebar-nav-list sidebar-nav-list-unstyled">
                    <li id="about-nav-link" className="sidebar-nav-footer-list-item"><Link to="/about" className="sidebar-nav-footer-list-item">{t('footer.about')}</Link></li>
                    <li id="terms-nav-link" className="sidebar-nav-footer-list-item"><Link to="/terms" className="sidebar-nav-footer-list-item">{t('footer.terms')}</Link></li>
                    <li id="logout-link" className="sidebar-nav-footer-list-item"><Link to="/privacy" className="sidebar-nav-footer-list-item">{t('footer.privacy')}</Link></li>
                </ul>
            </div>
            <div className="sidebar-nav-div">
                <div className="sidebar-nav-text-block">
                    <span>{t('footer.copyright')}</span>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;