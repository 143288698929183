import React, { useEffect, useRef, useState } from 'react';
import { Image, Transformer } from 'react-konva';

const ImageShape = ({ 
  id,
  x,
  y,
  width,
  height,
  imageUrl,
  align = 'center', // default to center
  vAlign = 'middle', // default to middle
  stroke,
  strokeWidth,
  cornerRadius,
  linkedProductNumber,
  linkedProductProperty,
  draggable,
  selected,
  onClick,
  onChange,
  imageObj
 }) => {
  const shapeRef = useRef();
  const trRef = useRef();

  const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);
  const [image, setImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {

    const img = new window.Image();
    img.crossOrigin = 'anonymous';
    img.src = currentImageUrl;
    img.onload = () => {
      // Scale the image dimensions proportionally to the zoom level
      const zoomLevel = window.devicePixelRatio || 1;
      setImageDimensions({ 
        width: img.width * zoomLevel, 
        height: img.height * zoomLevel 
      });
  
      setImage(img);
    };
    
  }, [currentImageUrl, imageObj]);

  let scaledWidth, scaledHeight;
  const aspectRatio = imageDimensions.width / imageDimensions.height;

  if (aspectRatio > width / height) {
    scaledWidth = width;
    scaledHeight = width / aspectRatio;
  } else {
    scaledHeight = height;
    scaledWidth = height * aspectRatio;
  }
  
  let offsetX = 0;
  let offsetY = 0;

  if (align === 'center') {
    offsetX = (width - scaledWidth) / 2;
  } else if (align === 'right') {
    offsetX = width - scaledWidth;
  }

  if (vAlign === 'middle') {
    offsetY = (height - scaledHeight) / 2;
  } else if (vAlign === 'bottom') {
    offsetY = height - scaledHeight;
  }

  useEffect(() => {
    if (selected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  const handleDragEnd = (e) => {
    const node = shapeRef.current;
    onChange({
      id: id,
      type: 'image',
      x: node.x(),
      y: node.y(),
      width: node.width() * node.scaleX(),
      height: node.height() * node.scaleY(),
      imageUrl: imageUrl,
      align: align,
      verticalAlign: vAlign,
      stroke: stroke,
      strokeWidth: strokeWidth,
      cornerRadius: cornerRadius,
      linkedProductNumber: linkedProductNumber,
      linkedProductProperty: linkedProductProperty,
      draggable: draggable
    });
    node.scaleX(1);
    node.scaleY(1);
  };

  const handleTransformEnd = (e) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      id: id,
      type: 'image',
      x: node.x(),
      y: node.y(),
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(5, node.height() * scaleY),
      imageUrl: imageUrl,
      align: align,
      verticalAlign: vAlign,
      stroke: stroke,
      strokeWidth: strokeWidth,
      cornerRadius: cornerRadius,
      linkedProductNumber: linkedProductNumber,
      linkedProductProperty: linkedProductProperty,
      draggable: draggable
    });
  };

  useEffect(() => {
    setCurrentImageUrl(imageUrl);
}, [imageUrl]);

  return (
    <React.Fragment>

      <Image
        image={image}
        x={x + offsetX}
        y={y + offsetY}
        width={scaledWidth}
        height={scaledHeight}
        draggable={draggable}
        onClick={onClick}
        onTap={onClick}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
        ref={shapeRef}
        stroke={stroke}
        strokeWidth={strokeWidth}
        cornerRadius={cornerRadius}
        shadowBlur={selected ? 10 : 0}
        shadowColor={selected ? 'black' : null}
        linkedProductNumber={linkedProductNumber}
        linkedProductProperty={linkedProductProperty}
        crossOrigin="anonymous"
      />

      {selected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ImageShape;