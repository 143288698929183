import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FormInputText from './stories/FormInputText';
import FormInputDropDown from './stories/FormInputDropDown';
import FormButton from './stories/FormButton';
import FormInputDatePicker from './stories/FormInputDatePicker';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUpload from './stories/ImageUpload';
import axios from 'axios';



function ScanProduct() {
  const { t, i18n } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  // initialize states for each input field
  const [retailerSkuId, setRetailerSkuId] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [brand, setBrand] = useState('');
  const [variantId, setVariantId] = useState('');
  const [originalPrice, setOriginalPrice] = useState('');
  const [promoPrice, setPromoPrice] = useState('');
  const [promoStartDate, setPromoStartDate] = useState(null);
  const [promoEndDate, setPromoEndDate] = useState(null);
  const [promoType, setPromoType] = useState('');
  const [locations, setLocations] = useState('1');
  const [retailerSkuIdError, setRetailerSkuIdError] = useState('');
  const [productNameError, setProductNameError] = useState('');
  const [originalPriceError, setOriginalPriceError] = useState('');
  const [promoPriceError, setPromoPriceError] = useState('');
  const [promoStartDateError, setPromoStartDateError] = useState('');
  const [promoEndDateError, setPromoEndDateError] = useState('');
  const [promoTypeError, setPromoTypeError] = useState('');
  const [productCount, setProductCount] = useState(0);
  const [imageFile, setImageFile] = useState(null);
  const [localImageUrl, setLocalImageUrl] = useState(null);
  const fileInputRef = useRef();
  const [imageUrl, setImageUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [scanImageUrl, setScanImageUrl] = useState(null);
  const [isScanImageProcessing, setIsScanImageProcessing] = useState(null);

  const overlay = document.querySelector('.overlay');


  function showOverlay() {
      overlay.style.display = 'flex';
  }

  function hideOverlay() {
      overlay.style.display = 'none';
  }

  const handleScanImageUpload = async () => {
    const token = await getAccessTokenSilently();
    if (!imageFile) {
      console.error("No image selected for uploading");
      return;
    }
    setIsUploading(true); // set isUploading to true when image uploading starts
    // First, get the signed URL from the serve
    const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/s3/signed-url?type=product&fileName=${encodeURIComponent(imageFile.name)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data: { signedUrl } } = response;
    if(signedUrl) {
      // Upload the image file using the signed URL
      const result = await axios.put(signedUrl, imageFile, {
        headers: {
          'Content-Type': imageFile.type
        }
      });
      if (result.status === 200) {
        setScanImageUrl(signedUrl.split("?")[0]); // Set the scanImageUrl state variable with the uploaded image URL
      } else {
        console.error('Error uploading file:', result);
      }
    } else {
      console.error('Server did not return a signed URL for upload.');
    }
    setIsUploading(false); // set isUploading to false when image uploading ends
  };

  const processScanImage = async() => {
    const token = await getAccessTokenSilently();
    if(!scanImageUrl) {
      console.error("Error uploading scanned image for processing");
      return;
    }
    try {
      showOverlay();
      setIsScanImageProcessing(true);
      const response = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/api/textract/process-image`, {
        imageUrl: scanImageUrl
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setIsScanImageProcessing(false);
      console.log(response.data);
      if(response.data) {
        if(response.data.productName) setProductName(response.data.productName);
        if(response.data.productName) setProductDescription(response.data.productName);
        if(response.data.brandName) setBrand(response.data.brandName);
        if(response.data.originalPrice) setOriginalPrice(parseFloat(response.data.originalPrice)/100);
        if(response.data.promoPrice) setPromoPrice(parseFloat(response.data.promoPrice)/100);
      }
      hideOverlay();
    } catch (error) {
      setIsScanImageProcessing(false);
      console.error("Error processing scanned image:", error);
      hideOverlay();
    }
  }

  const fetchProductCount = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(process.env.REACT_APP_SERVER_DOMAIN + '/api/products/count/777', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (response.ok) {
      const data = await response.json();
      setProductCount(data.count);
    } else {
      toast.error('Failed to fetch product count', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  };

  useEffect(() => {
    fetchProductCount();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (imageFile) {
      handleScanImageUpload();
    }
  }, [imageFile]);

  const onCancel = (event) => {
    event.preventDefault();
    navigate('/products');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (retailerSkuId.trim() === '') {
      setRetailerSkuIdError('Retailer SKU ID is required!');
      return;
    }

    if (productName.trim() === '') {
      setProductNameError('Product Name is required!');
      return;
    }

    if (originalPrice.trim() === '') {
      setOriginalPriceError('Original Price is required!');
      return;
    }

    if (promoPrice.trim() !== '') {
      // Check if promo price is less than or equal to original price
      if (Number(promoPrice) > Number(originalPrice)) {
        setPromoPriceError('Promo Price should be less than or equal to Original Price!');
        return;
      }

      // Check if all promo related fields are filled
      if (!promoStartDate) {
        setPromoStartDateError('Promo start date must be filled!');
        return;
      }

      if (!promoEndDate) {
        setPromoEndDateError('Promo end date must be filled!');
        return;
      }

        // Check if promo end date is greater than or equal to promo start date
      if (promoEndDate < promoStartDate) {
        setPromoEndDateError('Promo end date must be greater than or equal to promo start date!');
        return;
      }


    // Check if promo type is not null
    if (promoType.trim() === '') {
      setPromoTypeError('Promo Type is required!');
      return;
    }

    }

    const token = await getAccessTokenSilently();

    const product = {
      retailerSkuId,
      productName,
      productDescription,
      variantId,
      originalPrice,
      promoPrice: promoPrice.trim() === '' ? null : promoPrice, // Assign null if promoPrice is empty
      promoStartDate,
      promoEndDate,
      promoType,
      locations,
      imageUrl,
      brand
    };
    const response = await fetch(process.env.REACT_APP_SERVER_DOMAIN + '/api/products/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(product),
    });

    if (response.ok) {
      toast.success('Product was successfully saved.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 30000,
        style: {
          background: '#fff',
          color: '#605dba'
        }
      });
      clearForm(event);
      fetchProductCount();

    } else {
      const errorData = await response.json();  // Assuming that the error data comes in JSON format from the server
      toast.error(`The product could not be saved. Error: ${errorData.error}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  };

  const handleRetailerSKUIDChange = (e) => {
    setRetailerSkuId(e.target.value);
    setRetailerSkuIdError('');
  };

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
    setProductNameError('');
  };

  const handleOriginalPriceChange = (e) => {
    setOriginalPrice(e.target.value);
    setOriginalPriceError('');
  };

  const handlePromoStartDateChange = (date) => {
    setPromoStartDate(date);
  };

  const handlePromoEndDateChange = (date) => {
    setPromoEndDate(date);
  };

  const clearForm = (e) => {
    e.preventDefault();

    fileInputRef.current.value = '';
    setImageFile(null);
    setLocalImageUrl(null);
    setScanImageUrl(null); 
    setImageUrl(null);
    setRetailerSkuId('');
    setProductName('');
    setProductDescription('');
    setBrand('');
    setVariantId('');
    setOriginalPrice('');
    setPromoPrice('');
    setPromoStartDate(null);
    setPromoEndDate(null);
    setPromoType('');
    setLocations('');
    setRetailerSkuIdError('');
    setProductNameError('');
    setOriginalPriceError('');
  };

  return (
    <div className="dashboard-body-wrapper">
      <div className="main-column">
      <div className="overlay">
            <div className="spinner">
          </div>
        </div>
        <div className="div-block">
          <span className='div-block-title'>Scan Product</span>
        </div>
        <div  className="div-block">
          <div className="new-product-form">
            <ToastContainer />  
            <ImageUpload 
                  ref={fileInputRef} 
                  setImageFile={setImageFile} 
                  localImageUrl={localImageUrl}
                  setLocalImageUrl={setLocalImageUrl} 
                  buttonLabel="Upload Scan"
                />
                             <div className="form-row">
                <FormButton 
                    className="Button" 
                    type="button" 
                    onClick={onCancel} 
                    transparent
                >Cancel
                </FormButton>
                <FormButton className="Button" type="button" onClick={clearForm}>
                  Clear
                </FormButton>
                <FormButton className="Button" type="button" onClick={processScanImage}>
                  Process Image
                </FormButton>
              </div>
                <form id="product-edit-form" name="product-edit-form" data-name="Product Edit Form" onSubmit={handleSubmit}>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-retailer-skuid"
                  name="edit-form-retailer-skuid"
                  width="40%"
                  placeholder="Retailer SKU ID*"
                  inputType="Natural Number"
                  value={retailerSkuId}
                  onChange={handleRetailerSKUIDChange}
                  isRequired={true}
                  errorMessage={retailerSkuIdError}
                  setErrorMessage={setRetailerSkuIdError}
                  maxLength={256}
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-product-name"
                  name="edit-form-product-name"
                  width="90%"
                  placeholder="Product Name*"
                  value={productName}
                  onChange={handleProductNameChange}
                  isRequired={true}
                  errorMessage={productNameError}
                  setErrorMessage={setProductNameError}
                  maxLength={256}
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-product-description"
                  name="edit-form-product-description"
                  width="90%"
                  placeholder="Product Description"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  maxLength={512}
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-brand"
                  name="edit-form-brand"
                  width="40%"
                  placeholder="Brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  inputType="String"
                />
                <FormInputText
                  label=""
                  id="edit-form-variant-id"
                  name="edit-form-variant-id"
                  width="40%"
                  placeholder="Variant ID"
                  value={variantId}
                  onChange={(e) => setVariantId(e.target.value)}
                  inputType="Natural Number"
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-original-price"
                  name="edit-form-original-price"
                  width="40%"
                  placeholder="Original Price*"
                  inputType="Float"
                  value={originalPrice}
                  onChange={handleOriginalPriceChange}
                  isRequired={true}
                  errorMessage={originalPriceError}
                  setErrorMessage={setOriginalPriceError}
                  maxLength={255}
                />
                <FormInputText
                  label=""
                  id="edit-form-promo-price"
                  name="edit-form-promo-price"
                  width="40%"
                  placeholder="Promo Price"
                  inputType="Float"
                  value={promoPrice}
                  onChange={(e) =>  {
                      setPromoPrice(e.target.value);
                      setPromoPriceError('');  // Clear the error when user starts typing
                  }}
                  errorMessage={promoPriceError}  // Show validation error to the user
                />
              </div>
              <div className="form-row">
                <FormInputDatePicker
                  label=""
                  placeholder="Promo Start Date (mm/dd/yyyy)"
                  value={promoStartDate}
                  onChange={(date) => {
                    handlePromoStartDateChange(date);
                    setPromoStartDateError('');  // Clear the error when user selects a date
                  }}
                  errorMessage={promoStartDateError}  // Show validation error to the user
                />
                <FormInputDatePicker
                  label=""
                  placeholder="Promo End Date (mm/dd/yyyy)"
                  value={promoEndDate}
                  onChange={(date) => {
                    handlePromoEndDateChange(date);
                    setPromoEndDateError('');  // Clear the error when user selects a date
                  }}
                  errorMessage={promoEndDateError}  // Show validation error to the user
                />
              </div>
              <div className="form-row">
                <FormInputDropDown
                  label=""
                  id="edit-form-promo-type"
                  name="edit-form-promo-type"
                  width="40%"
                  options={[
                    { value: 'PRICE_DISCOUNT', label: '$ off on Original Price' },
                    { value: 'PERCENTAGE_DISCOUNT', label: '% off on Original Price' },
                    { value: 'BOGO', label: 'Buy 1 Get 1 Free' },
                  ]}
                  value={promoType}
                  onChange={(e) => {
                    setPromoType(e.target.value);
                    setPromoTypeError('');  // Clear the error when user selects a value
                  }}
                  placeholder="Promo Type"
                  errorMessage={promoTypeError}  // Show validation error to the user
                />
                <FormInputDropDown
                  label=""
                  id="edit-form-locations"
                  name="edit-form-locations"
                  width="40%"
                  options={[
                    {value: '1', label: 'Nationwide' },
                  ]}
                  value={locations}
                  onChange={(selectedOption) => setLocations(selectedOption.value)}
                />
              </div>
              <div className="form-row">
                <FormButton className="Button" type="submit" disabled={isUploading}>
                  Save
                </FormButton>
              </div>
            </form>
            </div>
        </div>
      </div>
      <div className="right-column">
        <div className="div-block">
          <span>
            Pro Tip: Avoid adding new products when the POS Sync light is Green. Doing manual changes at the same time as
            when we are syncing with POS may lead to undesirable results.
          </span>
        </div>
        <div className="div-block">
        </div>
        <div className="div-block">
          <div className="statusMessage">Product Count: <span className='statusHighlight'>{productCount}</span></div>
          <div className="statusMessage">POS Sync: GREY</div>
          <div className="statusMessage">Team Members Online: 0</div>
        </div>
      </div>
    </div>
  );
}

export default ScanProduct;