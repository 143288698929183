import React, { forwardRef} from 'react';
import { Stage, Layer, Rect } from 'react-konva';


const CellTemplate = forwardRef(({ width, height, children, backgroundColor = "white" },ref) => {
  
  return (
    <div>
    <Stage width={width} height={height} ref={ref} >
      <Layer>
      <Rect
          id="template-background"
          width={width}
          height={height}
          fill={backgroundColor} // set fill to the color state
          listening={false}
        />
      </Layer>
      <Layer>
        {children}
      </Layer>
    </Stage>
    </div>
  );
});

export default CellTemplate;