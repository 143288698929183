import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';


const FormButton = ({ type = 'submit', className, onClick, children, disabled, transparent, backgroundColor, icon }) => {
  const buttonClass = icon ? `$ button-has-icon Button--transparent Button--noborder` : className;
  
  return (
    <div className="FormButton">
      <Button 
        type={type}
        onClick={onClick} 
        disabled={disabled} 
        transparent={transparent} 
        backgroundColor={backgroundColor}
        className={buttonClass}
      >
        <span className="button-text">{children}</span>
        {icon && <span className="button-icon">{icon}</span>}
      </Button>
    </div>
  );
};


FormButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  backgroundColor: PropTypes.string,
  icon: PropTypes.node,
};

FormButton.defaultProps = {
  type: 'submit',
  onClick: () => {},
  disabled: false,
  transparent: false,
  backgroundColor: "",
  icon: null, 
};

export default FormButton;
