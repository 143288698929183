import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import './FormCircularButton.css';  // <-- Import the CSS here

const FormCircularButton = ({ type = 'submit', onClick, children, disabled, transparent, backgroundColor }) => {
  return (
    <div className="FormCircularButton">
      <Button 
        type={type}
        onClick={onClick} 
        disabled={disabled} 
        transparent={transparent} 
        backgroundColor={backgroundColor}
      >
        {children}
      </Button>
    </div>
  );
};

FormCircularButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

FormCircularButton.defaultProps = {
  type: 'submit',
  onClick: () => {},
  disabled: false,
  transparent: false,
  backgroundColor: "",
};

export default FormCircularButton;
