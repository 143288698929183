import React, { useEffect } from 'react';
import { Line, Transformer, Rect } from 'react-konva';

const LineShape = ({
  id,
  points,
  stroke,
  strokeWidth,
  rotation = 0,
  draggable,
  selected,
  onSelect,
  onChange,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  useEffect(() => {
    if (selected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  useEffect(() => {
    if (shapeRef.current) {
      shapeRef.current.rotation(rotation);  // Update the rotation of the line
      shapeRef.current.getLayer().batchDraw();  // Redraw the layer to reflect the changes
    }
  }, [rotation]);  // This effect runs whenever the rotation prop changes

  const midX = (points[0] + points[2]) / 2;
  const midY = (points[1] + points[3]) / 2;
  const angle = (Math.atan2(points[3] - points[1], points[2] - points[0]) * 180) / Math.PI;
  const lineLength = Math.sqrt(
    Math.pow(points[2] - points[0], 2) + Math.pow(points[3] - points[1], 2)
  );


  return (
    <>
      
      <Line
        ref={shapeRef}
        id={id}
        points={points}
        stroke={stroke}
        rotation={rotation}
        strokeWidth={strokeWidth}
        draggable={draggable}
        shadowBlur={selected ? 10 : 0} // Apply shadow effect when selected
        shadowColor={selected ? 'black' : null} // The shadow color when selected
        onDragEnd={e => {
          onChange({
            id,
            type: 'line',
            points: e.target.points(),
            stroke,
            strokeWidth,
            draggable,
            rotation
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;

          onChange({
            id,
            type: 'line',
            points: node.points(),
            stroke,
            strokeWidth,
            draggable,
            rotation: node.rotation() 
          });
        }}
        onClick={onSelect}
        onTap={onSelect}
      />
      {selected && <Transformer ref={trRef}  />}
    </>
  );
};

export default LineShape;
