import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const Button = ({ type = 'submit', className, children, onClick, disabled, transparent, noBorder, backgroundColor, icon }) => {
  
  if (transparent) {
    className += " Button--transparent";
  }

  if (noBorder) {
    className += " Button--noborder";
  }

  const buttonStyle = backgroundColor ? { backgroundColor } : {};

  return (
    <button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={buttonStyle}
    >
      {icon && <span className="Button-icon">{icon}</span>}
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  backgroundColor: PropTypes.string,
  icon: PropTypes.node,
};

Button.defaultProps = {
  type: 'submit',
  onClick: () => {},
  disabled: false,
  transparent: false,
  backgroundColor: '',
  icon: null,
};

export default Button;
