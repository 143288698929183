import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormButton from './stories/FormButton';
import { useAuth0 } from '@auth0/auth0-react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {Popconfirm, message, Tooltip } from 'antd';


function formatDate(strDate) {
  const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  const date = new Date(strDate);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().substr(-2);

  return `${day} ${month} ${year}`;
}


function Flyers() {
  const [flyers, setFlyers] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  
  const navigate = useNavigate();

  const handleEditClick = (flyer) => {
    navigate('/addflyer', { state: { flyer: flyer } });
  };

  const handleDeleteClick = async (flyerId) => {
    try {
      const token = await getAccessTokenSilently();
      console.log(token);
      await axios.delete(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyers/${flyerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the deleted flyer from the state
      setFlyers(prevFlyers => prevFlyers.filter(flyer => flyer.flyer_id !== flyerId));
    } catch (error) {
      console.error("Error deleting flyer", error);
    }
  };
  

  useEffect(() => {
    const fetchFlyers = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyers/retailer/777`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }); // Adjust the endpoint if necessary
        setFlyers(response.data);
      } catch (error) {
        console.error("Error fetching flyers", error);
      }
    };

    fetchFlyers();
  }, []);

  return (
    <div className='dashboard-body-wrapper'>
      <div className='main-column'>
        <div className='div-block'>
        <span className='div-block-title'>All Flyers</span>
          <div className="products-table-div">
            <table>
              <thead>
                <tr>
                  <th>Flyer Id</th>
                  <th>Flyer Title</th> {/* Assuming you have a title field in the data */}
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {flyers.map(flyer => (
                  <tr key={flyer.flyer_id}>
                    <td>{flyer.flyer_id}</td>
                    <td>{flyer.flyer_title}</td>
                    <td>{formatDate(flyer.start_date)}</td>
                    <td>{formatDate(flyer.end_date)}</td>
                    <td>
                    <div className="flyer-buttons">
                    <EditOutlined key="edit" onClick={() => handleEditClick(flyer)} style={{ marginRight: '10px' }} />
                    <Tooltip>
                        <Popconfirm 
                          title="Are you sure to delete this template?" 
                          onConfirm={() => handleDeleteClick(flyer.flyer_id)}
                          onCancel={() => message.error('Delete Cancelled')}
                          okText="Yes" 
                          cancelText="No"
                        >
                          <DeleteOutlined key="delete" />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Flyers;
