import { createSlice } from '@reduxjs/toolkit';

const countryReducer = createSlice({
    name: 'country',
    initialState: '',
    reducers: {
        setCountry: (state, action) => action.payload,
    },
});

export const { setCountry } = countryReducer.actions;
export const selectCountry = state => state.country;

export default countryReducer.reducer;