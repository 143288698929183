import React from 'react';

const RingIcon = ({ size = 50, color = "black" }) => (
  <svg height={size} width={size}>
    <circle cx={size/2} cy={size/2} r={size/2 - 3} stroke={color} strokeWidth="5" fill="transparent" />
    <circle cx={size/2} cy={size/2} r={(size/2) - 10} stroke={color} strokeWidth="5" fill="white" />
  </svg>
);

export default RingIcon;
