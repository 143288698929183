import React from 'react';
import PropTypes from 'prop-types';
import FormInputText from './FormInputText';
import './FormInputPhoneNumber.css';

function FormInputPhoneNumber({ label, value, onChange, name, width }) {
  // This function ensures that the input is correctly formatted as xxx-xxx-xxxx
  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const numbers = value.replace(/[^\d]/g, '');
    // Group numbers and add hyphens
    return numbers.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  // Handle change event from the input field
  const handleChange = (event) => {
    // Get the value from event object
    const { value, name } = event.target;
    // Format the value
    const formattedValue = formatPhoneNumber(value);
    // Call the passed onChange handler with formatted value
    onChange({
      target: {
        name,
        value: formattedValue, // Pass the formatted value instead of the raw input
      },
    });
  };

  // Format the displayed value
  const formattedValue = formatPhoneNumber(value);

  // Style for the input field
  const inputStyle = { width };

  return (
    <div className="form-input-phone" style={{ width }}>
      <label htmlFor={name}>{label}</label>
      <FormInputText
        type="tel"
        name={name}
        value={formattedValue}
        maxLength={12}
        onChange={handleChange}
        pattern="\d{3}-\d{3}-\d{4}"
        placeholder="xxx-xxx-xxxx"
        style={inputStyle}
      />
    </div>
  );
}

FormInputPhoneNumber.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default FormInputPhoneNumber;
