// Grid.js
import React from 'react';
import { Line } from 'react-konva';

const CellTemplateGrid = ({ width, height, gap }) => {
  const verticalLines = [];
  const horizontalLines = [];
  
  for(let i = gap; i < width; i += gap) {
    verticalLines.push(<Line key={'v'+i} points={[i, 0, i, height]} stroke='grey' strokeWidth={0.5} />);
  }
  
  for(let j = gap; j < height; j += gap) {
    horizontalLines.push(<Line key={'h'+j} points={[0, j, width, j]} stroke='grey' strokeWidth={0.5} />);
  }

  return [...verticalLines, ...horizontalLines];
};

export default CellTemplateGrid;
