import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setCountry, selectCountry } from '../reducers/country.reducer';

import Button from './Button';
import './headerbar.css';
import Cookies from 'js-cookie';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MaterialUIButton from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { withAuth0, useAuth0 } from '@auth0/auth0-react';

const HeaderBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const country = useSelector(selectCountry);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(); 
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0(); // Fetch user and isAuthenticated flag

  useEffect(() => {
    const cookieCountry = Cookies.get('country');
    if (cookieCountry) {
        dispatch(setCountry(cookieCountry));
    } else {
        setOpen(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (country) {
        Cookies.set('country', country, { expires: 7 });
    }
  }, [country]);

  const handleDialogClose = (selectedCountry) => {
    dispatch(setCountry(selectedCountry));
    setOpen(false);
  };

  const handleCountryChange = (event) => {
    dispatch(setCountry(event.target.value));
  };


  return (
    
    <header>
      <div className="wrapper">
        <div>
          <a href='/'>
            <img src='/logo-no-background.svg' alt='adgro' height='32' />
          </a>
        </div>
        <div>
            <Dialog open={open} >
                <DialogTitle>Select your country</DialogTitle>
                <DialogActions>
                    <MaterialUIButton onClick={() => handleDialogClose('US')}><img src="/us.png" alt="US flag" />&nbsp;US</MaterialUIButton>
                    <MaterialUIButton onClick={() => handleDialogClose('Canada')}><img src="/ca.png" alt="Canada flag" />&nbsp;CA</MaterialUIButton>
                </DialogActions>
            </Dialog>
        </div> 
        <div className="menu-icon" onClick={() => setMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <span>&#10005;</span> : <span>&#9776;</span>}
        </div>
        <div className={`menu ${isMenuOpen ? "show" : ""}`}>
          <ul>
          <li>
            {isAuthenticated ? (
              <a href='/dashboard'>
                <b>{t('header.nav.dashboard')}</b>
              </a>
            ) : (
              <a href='/'>
                <b>{t('header.nav.home')}</b>
              </a>
            )}
          </li>
          {!isAuthenticated && (
          <li>
                <a href='/#pricing'>
                  <b>{t('header.nav.pricing')}</b>
                </a>
            </li>
            )}
            <li>
              <a href='/#signup-form'>
                <b>{t('header.nav.contact')}</b>
              </a>
            </li>
            {!isAuthenticated && (
            <li>
              <span className="menu-option">{t('header.nav.country')}</span>
              <Select className="menu-dropdown" value={country} onChange={handleCountryChange}>
                <MenuItem value={'US'}><img src="/us.png" alt="US flag" />&nbsp;US</MenuItem>
                <MenuItem value={'Canada'}><img src="/ca.png" alt="Canada flag" />&nbsp;CA</MenuItem>
              </Select>
            </li>
            )}
            <li>
              {isAuthenticated ? ( // Check if user is authenticated
                <>
                  <span className="welcome">
                  {t('header.nav.welcome')}<b>{user.name}</b>!
                  </span>
                  <Button   className="Button" size="small" onClick={() => logout({ returnTo: window.location.origin })}>
                    {t('header.nav.log-out')}
                  </Button>
                </>
              ) : (
                <>
                <Button  className="Button" size="small" onClick={() => loginWithRedirect({ redirectUri: `${window.location.origin}/dashboard` })}>
                  {t('header.nav.log-in')}
                </Button>
                <Button  className="Button" primary size="small" onClick={() => loginWithRedirect({ redirectUri: `${window.location.origin}/dashboard` })}>
                  {t('header.nav.sign-up')}
                </Button>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

HeaderBar.propTypes = {
  user: PropTypes.shape({}),
  auth0: PropTypes.shape({
    loginWithRedirect: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
};

HeaderBar.defaultProps = {
  user: null,
};

export default withAuth0(HeaderBar);