import React from 'react';
import PropTypes from 'prop-types';
import './ImageDisplay.css';

function ImageDisplay({ image, alt }) {
  return (
    <div className="image-display">
      {image ? <img src={image} alt={alt} /> : <p>No Image Available</p>}
    </div>
  );
}

ImageDisplay.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
};

ImageDisplay.defaultProps = {
  alt: 'Image Display',
};

export default ImageDisplay;