
import React from 'react';
import { useDrag } from 'react-dnd';

function FlyerTemplatePreview({ template, onTemplateDrag }) {
    const [{ isDragging }, drag] = useDrag({
        type: 'template',
        item: { id: template.id, type: 'template' },
        collect: monitor => ({
          isDragging: !!monitor.isDragging(),
        }),
      });
  
    return (
      <div className="flyer-template-preview" ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        {template.preview_image_url && <img src={template.preview_image_url} alt="flyer-template" />}
        {/* Other template data */}
      </div>
    );
}

export default FlyerTemplatePreview;
