import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import FormButton from './FormButton';
import FormInputText from './FormInputText';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './FileUpload.css';

const FileUpload = ({ fetchProductCount }) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [filename, setFilename] = useState("");
  const fileInputRef = useRef();
  const { getAccessTokenSilently } = useAuth0();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFilename(event.target.files[0].name);
    Papa.parse(event.target.files[0], {
      complete: (results) => {
        setData(results.data);
        setErrors(results.errors);
      },
      header: true,
    });
  };

  const handleSaveProductsSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_DOMAIN}/api/products/bulk-upload`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      });

  
      if (response.status === 201) {
        toast.success('Products were successfully uploaded.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 30000,
          style: {
            background: '#fff',
            color: '#605dba'
          }
        });
        clearForm();
        fetchProductCount();
      } else {
        toast.error('There was an error uploading the products.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          style: {
            background: '#fff',
            color: '#ff0000'
          }
        });
      }
    } catch (error) {
      toast.error('There was an error uploading the products.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  };

  const clearForm = () => {
    setFile(null);
    setData([]);
    setErrors([]);
    setFilename("");
    fileInputRef.current.value = "";
  }

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="file-upload">
        <input 
          id="fileInput"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{display: 'none'}}
        />
        <FormInputText 
          label=""
          value={filename}
          onChange={() => {}} // Since this is a read-only field, we don't need to handle changes
          width="90%"
          placeholder="Select the Excel (.xls, .xlsx, .csv) file you want to upload here with product and pricing information"
          isRequired={false}
          inputType="String"
        />
        <FormButton onClick={handleBrowseClick} className="Button">Bulk Upload</FormButton>
        
      {/* The data summary and table */}
      {data.length > 0 && (
        <div className="div-block">
          <div className="file-upload-notification-div">
            <div>
              {data.length} products found. {errors.length} products have error. Press Save Products to save them to the database.
            </div>
          </div>
          <div className="form-row">
            <FormButton className="Button" transparent onClick={clearForm}>Cancel</FormButton>
            <FormButton className="buButtontton" onClick={handleSaveProductsSubmit}>Save Products</FormButton>
          </div>
          <div className="products-table-div">
            <table>
                <tbody>
                    <tr>
                        <th>Retailer SKU ID</th>
                        <th>Product Name</th>
                        <th>Original Price</th>
                        <th>Promo Price</th>
                        <th>Promo Type</th>
                        <th>Promo Start Date</th>
                        <th>Promo End Date</th>
                    </tr>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.retailerSkuId}</td>
                        <td>{row.productName}</td>
                        <td>{row.originalPrice}</td>
                        <td>{row.promoPrice}</td>
                        <td>{row.promoType}</td>
                        <td>{row.promoStartDate}</td>
                        <td>{row.promoEndDate}</td>
                    </tr>
                ))}
                </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;