import React from 'react';
import PropTypes from 'prop-types';
import './FormInputDropDown.css';

function FormInputDropDown({ label, value, options, onChange, name, width, placeholder, errorMessage }) {
  return (
    <div className="form-input" style={{ width }}>
      <label htmlFor={label}>{label}</label>
      <select id={label} value={value} name={name} onChange={onChange}>
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
    </div>
  );
}

FormInputDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default FormInputDropDown;