import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [flyers, setFlyers] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleEditClick = (flyer) => {
    navigate('/addflyer', { state: { flyer: flyer } });
  };

  useEffect(() => {
    const fetchFlyers = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyers/retailer/777`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFlyers(response.data);
      } catch (error) {
        console.error("Error fetching flyers", error);
      }
    };
    fetchFlyers();
  }, [getAccessTokenSilently]);

  return (
    <div className='dashboard-body-wrapper'>
      <div className='main-column'>
        <div id="page-title-div" className="page-title-div">
          <h3 className="secondary-text">Welcome!</h3>
        </div>
        <div className='div-block'>
          <span className='div-block-title'>Analytics</span>
          <div className='analytics-summary'>
            <div className='analytics-summary-node' key="visits">
              <label className='analytics-summary-node-title'>Visits</label>
              <span className='analytics-summary-node-data'>XXX</span>
            </div>
            <div className='analytics-summary-node' key="opens">
              <label className='analytics-summary-node-title'>Opens</label>
              <span className='analytics-summary-node-data'>XXX</span>
            </div>
            <div className='analytics-summary-node' key="engagement">
              <label className='analytics-summary-node-title'>Engagement</label>
              <span className='analytics-summary-node-data'>XXX</span>
            </div>
            <div className='analytics-summary-node' key="clicks">
              <label className='analytics-summary-node-title'>Clicks</label>
              <span className='analytics-summary-node-data'>XXX</span>
            </div>
          </div>
        </div>
        <div className='div-block'>
          <span className='div-block-title'>Active Flyers</span>
          <div className="flyers-carousel">
            <div className="flyer-thumbnail-new">
              <div className="flyer-thumbnail-wrapper">
                <img className="flyer-thumbnail-img" alt="flyer thumbnail" src='/flyer-preview.png' />
                <Link to="/addflyer" className="flyer-overlay">
                  <span className="overlay-text">+ New Flyer</span>
                </Link>
              </div>
            </div>
            {flyers.map(flyer => (
              <div key={flyer.id} className="flyer-thumbnail"> {/* Add unique key here */}
                <img className="flyer-thumbnail-img" alt="flyer thumbnail" src={flyer.preview_image_url || '/flyer-preview.png'} />
                <div className="flyer-buttons">
                  <EditOutlined key="edit" onClick={() => handleEditClick(flyer)} /><EyeOutlined />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='div-block'>
          <span className='div-block-title'>What We're Reading</span>
          <div className='reading-list'>
            <div className='reading-list-node' key="canadian-grocer">
              <a href='https://canadiangrocer.com/products?eiq_7_end=40'><span className='reading-list-node-data'>Canadian Grocer: What's new on shelf</span></a>
            </div>
            <div className='reading-list-node' key="6ps-planning">
              <a href='https://www.smartinplanning.com/blog/2019/2/14/6ps-of-merchandise-planning'><span className='reading-list-node-data'>6 Ps of Merchandise Planning</span></a>
            </div>
            <div className='reading-list-node' key="back-to-school">
              <a href='https://www.grocerydive.com/news/grocers-target-back-to-school-shoppers/692096/'><span className='reading-list-node-data'>How grocers are targeting back to school shoppers</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
