import React, { forwardRef } from 'react';
import './ImageUpload.css';

const ImageUpload = forwardRef(({ setImageFile, localImageUrl, setLocalImageUrl, buttonLabel = 'Upload Image' }, ref) => {
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setLocalImageUrl(URL.createObjectURL(file)); // create an object URL for the file
  };

  return (
    <div>
    <div className="image-uploader">
      <input id="fileInput" ref={ref} type="file" accept="image/*" capture="environment" onChange={handleImageChange} />
        <label htmlFor="fileInput" className="imageUploadButton">{buttonLabel}</label>
    </div>
    <div className="image-display">
        {localImageUrl && <img src={localImageUrl} alt="Preview" className="image-preview" />} 
        </div>
        </div>
  );
});

export default ImageUpload;