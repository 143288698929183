import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import store from './store/store';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Header from './Header';
import Footer from './Footer';
import WeeklyAd from './WeeklyAd';
import Terms from './Terms';
import Privacy from './Privacy';
import Logout from './Logout';
import Welcome from './Welcome';
import Dashboard from './Dashboard';
import Products from './Products';
import AddProduct from './AddProduct';
import ScanProduct from './ScanProduct';
import BulkUploadProducts from './BulkUploadProducts';
import Flyers from './Flyers';
import AddFlyer from './AddFlyer';
import AdTemplates from './AdTemplates';
import AdTemplateManager from './AdTemplateManager';
import FlyerTemplates from './FlyerTemplates';
import FlyerTemplateManager from './FlyerTemplateManager';
import ProtectedRoute from './ProtectedRoute';
import ProtectedLayout from './ProtectedLayout';
import EmailVerificationHandler from './EmailVerificationHandler';

function MainApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const [userHasFilledProfile, setUserHasFilledProfile] = useState(false);
  const [userHasSetupLocation, setUserHasSetupLocation] = useState(false);
  const [userOrganizationId, setUserOrganizationId] = useState(null);
  const [userCountry, setUserCountry] = useState(null);

  const handleProfileFilled = () => setUserHasFilledProfile(true);
  const handleUserLocationSetup = () => setUserHasSetupLocation(true);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let error = params.get('error');
    let errorDescription = params.get('error_description');

    if (error === 'access_denied' && errorDescription.includes('Please verify your email')) {
      // Redirect to the email verification error page
      localStorage.clear();
      sessionStorage.clear();
      navigate('/email-verification-error');
    }
  }, [location, navigate]);

  const isProtectedLayout = location.pathname.startsWith('/dashboard') ||
                            location.pathname.startsWith('/products') ||
                            location.pathname.startsWith('/addproduct') ||
                            location.pathname.startsWith('/scanproduct') ||
                            location.pathname.startsWith('/bulkuploadproducts') ||
                            location.pathname.startsWith('/flyers') ||
                            location.pathname.startsWith('/addflyer') ||
                            location.pathname.startsWith('/flyertemplates') ||
                            location.pathname.startsWith('/flyertemplatemanager') ||
                            location.pathname.startsWith('/adtemplates') ||
                            location.pathname.startsWith('/adtemplatemanager');

  const isDevEnvironment = process.env.REACT_APP_ENV === 'dev';

  return (
    <div>
      {(!isProtectedLayout || !isDevEnvironment || !isAuthenticated) && <Header />}
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/weeklyad' element={<WeeklyAd />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path="/logout" element={<Logout />} />
        <Route path='/email-verification-error' element={<EmailVerificationHandler />} />
        <Route path="/welcome" element={<ProtectedRoute component={() => <Welcome onProfileFilled={handleProfileFilled} onUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation}/>}userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/dashboard" element={<ProtectedRoute component={() => <ProtectedLayout><Dashboard /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/products" element={<ProtectedRoute component={() => <ProtectedLayout><Products /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/addproduct" element={<ProtectedRoute component={() => <ProtectedLayout><AddProduct /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/scanproduct" element={<ProtectedRoute component={() => <ProtectedLayout><ScanProduct /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/bulkuploadproducts" element={<ProtectedRoute component={() => <ProtectedLayout><BulkUploadProducts /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/flyers" element={<ProtectedRoute component={() => <ProtectedLayout><Flyers /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/addflyer" element={<ProtectedRoute component={() => <ProtectedLayout><AddFlyer /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/adtemplates" element={<ProtectedRoute component={() => <ProtectedLayout><AdTemplates /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/adtemplatemanager" element={<ProtectedRoute component={() => <ProtectedLayout><AdTemplateManager /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/flyertemplates" element={<ProtectedRoute component={() => <ProtectedLayout><FlyerTemplates /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
        <Route path="/flyertemplatemanager" element={<ProtectedRoute component={() => <ProtectedLayout><FlyerTemplateManager /></ProtectedLayout>} userHasFilledProfile={userHasFilledProfile} userHasSetupLocation={userHasSetupLocation} handleProfileFilled={handleProfileFilled} handleUserLocationSetup={handleUserLocationSetup} userCountry={userCountry} setUserCountry={setUserCountry} userOrganizationId={userOrganizationId} setUserOrganizationId={setUserOrganizationId} />} />
      </Routes>
      {(!isProtectedLayout || !isDevEnvironment) && <Footer />}
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
          }}
          cacheLocation="localstorage"
          useRefreshTokens
        >
          <Router>
            <MainApp />
          </Router>
        </Auth0Provider>
      </DndProvider>
    </Provider>
  );
}

export default App;
