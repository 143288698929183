import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInputText from './stories/FormInputText';
import FormInputDropDown from './stories/FormInputDropDown';
import FormButton from './stories/FormButton';
import Button from './stories/Button';
import FormInputDatePicker from './stories/FormInputDatePicker';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUpload from './stories/ImageUpload';
import ImageDisplay from './stories/ImageDisplay';
import axios from 'axios';


const Products = () => {
  const { t, i18n } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  // initialize states for each input field
  const [retailerSkuId, setRetailerSkuId] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [brand, setBrand] = useState('');
  const [variantId, setVariantId] = useState('');
  const [originalPrice, setOriginalPrice] = useState('');
  const [promoPrice, setPromoPrice] = useState('');
  const [promoStartDate, setPromoStartDate] = useState(null);
  const [promoEndDate, setPromoEndDate] = useState(null);
  const [promoType, setPromoType] = useState('');
  const [locations, setLocations] = useState('');
  const [productId, setProductId] = useState(null);
  const [limitQty, setLimitQty] = useState(null);
  const [priceUnit, setPriceUnit] = useState('lb.');


  const [retailerSkuIdError, setRetailerSkuIdError] = useState('');
  const [productNameError, setProductNameError] = useState('');
  const [originalPriceError, setOriginalPriceError] = useState('');
  const [promoPriceError, setPromoPriceError] = useState('');
  const [promoStartDateError, setPromoStartDateError] = useState('');
  const [promoEndDateError, setPromoEndDateError] = useState('');
  const [promoTypeError, setPromoTypeError] = useState('');
  const [largeImgSrc, setLargeImgSrc] = useState('');

  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsCount, setSearchResultsCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(100);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [localImageUrl, setLocalImageUrl] = useState(null);
  const fileInputRef = useRef();
  const [imageUrl, setImageUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showRightColumn, setShowRightColumn] = useState(false);


  const searchProducts = async(e) => {  

    if (searchKeywords.trim() === '') return;
  
    try {
      const token = await getAccessTokenSilently();
  
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/products/search/${searchKeywords}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.ok) {
        const searchResults = await response.json();
        setSearchResults(searchResults);
        setSearchResultsCount(searchResults.length);
        setCurrentPage(1); // Reset current page to the first page
      } else {
        toast.error('Error occurred while searching products.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          style: {
            background: '#fff',
            color: '#ff0000'
          }
        });
      }
    } catch (error) {
      toast.error('Error occurred while searching products.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
      
  }
  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    searchProducts(event);
    clearForm(event);
  };
  
  const getCurrentProducts = () => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    return searchResults.slice(indexOfFirstProduct, indexOfLastProduct);
  };
  
  const handleSearchKeywordsChange = (e) => {
    setSearchKeywords(e.target.value);
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    // Set the state values with the selected product details
    setLargeImgSrc(product.original_img_large_src);
    setImageUrl(product.original_img_large_src);
    setRetailerSkuId(product.retailer_sku_id);
    setProductName(product.product_name);
    setProductDescription(product.product_description);
    setBrand(product.brand);
    setVariantId(product.variant_id);
    setOriginalPrice(product.original_price);
    setPromoPrice(product.promo_price || ''); // Handle null value of promo_price
    setPromoStartDate(product.promo_start_date ? new Date(product.promo_start_date) : null);
    setPromoEndDate(product.promo_end_date ? new Date(product.promo_end_date) : null);
    if(product.promo_type) {
      setPromoType(product.promo_type);
    }
    if(product.location_id) {
      setLocations(product.location_id.toString());
    }
    if(product.id){
      setProductId(product.id);
    }
    setLimitQty(product.limit_qty || null);
    setShowRightColumn(true);
    setPriceUnit(product.price_unit || 'lb.');  
  };

  const handleImageUpload = async () => {
    const token = await getAccessTokenSilently();
    if (!imageFile) {
      return;
    }
    setIsUploading(true); // set isUploading to true when image uploading starts
    // First, get the signed URL from the server
    const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/s3/signed-url?type=product&fileName=${encodeURIComponent(imageFile.name)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      },
    });
    const { data: { signedUrl } } = response;
    if (signedUrl) {
      // Upload the image file using the signed URL
      const result = await axios.put(signedUrl, imageFile, {
        headers: {
          'Content-Type': imageFile.type
        }
      });
  
      if (result.status === 200) {
        setImageUrl(signedUrl.split("?")[0]); // Set the imageUrl state variable with the uploaded image URL
      } else {
        console.error('Error uploading file:', result);
      }
    } else {
      console.error('Server did not return a signed URL for upload.');
    }
  
    setIsUploading(false); // set isUploading to false when image uploading ends
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (retailerSkuId.trim() === '') {
      setRetailerSkuIdError('Retailer SKU ID is required!');
      return;
    }
    if (productName.trim() === '') {
      setProductNameError('Product Name is required!');
      return;
    }
    if (originalPrice.trim() === '') {
      setOriginalPriceError('Original Price is required!');
      return;
    }
    if (promoPrice.trim() !== '') {
      // Check if promo price is less than or equal to original price
      if (Number(promoPrice) > Number(originalPrice)) {
        setPromoPriceError('Promo Price should be less than or equal to Original Price!');
        return;
      }
      // Check if all promo related fields are filled
      if (!promoStartDate) {
        setPromoStartDateError('Promo start date must be filled!');
        return;
      }
      if (!promoEndDate) {
        setPromoEndDateError('Promo end date must be filled!');
        return;
      }
      // Check if promo end date is greater than or equal to promo start date
      if (promoEndDate < promoStartDate) {
        setPromoEndDateError('Promo end date must be greater than or equal to promo start date!');
        return;
      }
    // Check if promo type is not null
    if (promoType.trim() === '') {
      setPromoTypeError('Promo Type is required!');
      return;
      }
    }

    const token = await getAccessTokenSilently();

    const product = {
      retailerSkuId,
      productName,
      productDescription,
      variantId,
      originalPrice,
      promoPrice: promoPrice.trim() === '' ? null : promoPrice, // Assign null if promoPrice is empty
      promoStartDate,
      promoEndDate,
      promoType,
      locations,
      imageUrl,
      productId,
      brand,
      limitQty,
      priceUnit
    };

    const response = await fetch(process.env.REACT_APP_SERVER_DOMAIN + '/api/products/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(product),
    });

    if (response.ok) {
      toast.success('Product was successfully saved.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 30000,
        style: {
          background: '#fff',
          color: '#605dba'
        }
      });
      clearForm(event);
      searchProducts(event);
 
    } else {
      toast.error('There was an error updating the product.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    try {

      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_SERVER_DOMAIN + '/api/products/' + selectedProduct.id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if(response.ok) {
        toast.success('Product was successfully deleted.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 30000,
          style: {
            background: '#fff',
            color: '#605dba'
          }
        });
        clearForm(event);
        searchProducts(event);
 
      } else {
        toast.error('Error occurred while deleting the product.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          style: {
            background: '#fff',
            color: '#ff0000'
          }
        });
      }
    } catch (error) {
      toast.error('Error occurred while deleting the product.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }

  };

  useEffect(() => {
    if (imageFile) {
      handleImageUpload();
    }
  }, [imageFile]);
  
  const promoTypeLabels = {
    'PRICE_DISCOUNT': '$ off on Original Price',
    'PERCENTAGE_DISCOUNT': '% off on Original Price',
    'BOGO': 'Buy 1 Get 1 Free',
  };

  const handleRetailerSKUIDChange = (e) => {
    setRetailerSkuId(e.target.value);
    setRetailerSkuIdError('');
  };

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
    setProductNameError('');
  };

  const handleOriginalPriceChange = (e) => {
    setOriginalPrice(e.target.value);
    setOriginalPriceError('');
  };

  const handlePromoStartDateChange = (date) => {
    setPromoStartDate(date);
  };


  const handlePromoEndDateChange = (date) => {
    setPromoEndDate(date);
  };

  const handleLimitQtyChange = (e) => {
    setLimitQty(e.target.value);
  };

  const clearForm = (e) => {
    e.preventDefault();
    setLargeImgSrc('');
    setLocalImageUrl('');
    setSelectedProduct(null);
    setRetailerSkuId('');
    setProductName('');
    setProductDescription('');
    setBrand('');
    setLimitQty(null);
    setVariantId('');
    setOriginalPrice('');
    setPromoPrice('');
    setPromoStartDate(null);
    setPromoEndDate(null);
    setPromoType('');
    setLocations('');
    setProductId(null);
    setRetailerSkuIdError('');
    setProductNameError('');
    setOriginalPriceError('');
    setShowRightColumn(false);
    setPriceUnit('lb.');
  };

  return (
    <div className='dashboard-body-wrapper'>
    <div className={`main-column ${showRightColumn ? 'hide-main' : ''}`}>
        <div className="div-block">
        <span className='div-block-title'>Begin Search to Discover and Update Products</span>
            <div className="product-search-form">
              <form id="product-search-form" name="product-search-form" data-name="Product Search Form" method="get" onSubmit={handleSearchSubmit}>
                <FormInputText id="search-keywords" name="search-keywords" maxlength="256" width="90%" placeholder="Enter SKU ID, Product Name, Brand, or part of Product Description to Search" required="" value={searchKeywords}
              onChange={handleSearchKeywordsChange}></FormInputText>
                <FormButton className="Button">Search</FormButton>
              </form>
            </div>
        </div>
        <div className="div-block">
        {searchResultsCount !== null && (
          <div className="search-results-notification">
        
            <div>{`${searchResultsCount} Products found.`}</div>
          </div>
          )}
        </div>
        <div className='div-block'>
        {searchResultsCount !== null && searchResultsCount > 0 && (
          <div className="products-table-div">
              <table>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Product Name</th>
                    <th>Original Price</th>
                    <th>Promo Price</th>
                    <th>Promo Type</th>
                    <th>Promo Start Date</th>
                  </tr>
                </thead>
                <tbody>
                {getCurrentProducts().map((product, index) => {
                  let promoStartDate = null;
                  let formattedPromoStartDate = null;

                  if (product.promo_start_date) {
                    promoStartDate = new Date(product.promo_start_date);
                    formattedPromoStartDate = promoStartDate.toLocaleDateString('en-US', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    });
                  }
                  
                  const promoTypeLabel = promoTypeLabels[product.promo_type] || product.promo_type;

                  return (
                    <tr 
                      key={index}
                      onClick={() => handleRowClick(product)} // Handle row click event
                      className={selectedProduct === product ? 'selected' : ''}
                    >
                      <td>{product.retailer_sku_id}</td>
                      <td>{product.product_name}</td>
                      <td>{product.original_price}</td>
                      <td>{product.promo_price}</td>
                      <td>{promoTypeLabel}</td>
                      <td>{formattedPromoStartDate}</td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            <div className="pagination">
              <Button 
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </Button>
              <span>{`${currentPage} / ${Math.ceil(searchResults.length / productsPerPage)}`}</span>
              <Button 
                disabled={currentPage === Math.ceil(searchResults.length / productsPerPage)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        </div>
      </div>
      <div className={`right-column ${showRightColumn ? 'show-right' : ''}`}>
      <ToastContainer />
      {selectedProduct && (

        <div className='div-block'>
        <span className='div-block-title'>Update Product Details</span>
          <div className="new-product-form">
            <form id="product-edit-form" name="product-edit-form" data-name="Product Edit Form" onSubmit={handleSubmit}>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-retailer-skuid"
                  name="edit-form-retailer-skuid"
                  width="40%"
                  placeholder="Retailer SKU ID*"
                  inputType="Natural Number"
                  value={retailerSkuId}
                  onChange={handleRetailerSKUIDChange}
                  isRequired={true}
                  errorMessage={retailerSkuIdError}
                  setErrorMessage={setRetailerSkuIdError}
                  maxLength={256}
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-product-name"
                  name="edit-form-product-name"
                  width="90%"
                  placeholder="Product Name*"
                  value={productName}
                  onChange={handleProductNameChange}
                  isRequired={true}
                  errorMessage={productNameError}
                  setErrorMessage={setProductNameError}
                  maxLength={256}
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-product-description"
                  name="edit-form-product-description"
                  width="90%"
                  placeholder="Product Description"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  maxLength={512}
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-brand"
                  name="edit-form-brand"
                  width="40%"
                  placeholder="Brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  inputType="String"
                />
                <FormInputText
                  label=""
                  id="edit-form-variant-id"
                  name="edit-form-variant-id"
                  width="40%"
                  placeholder="Variant SKU ID"
                  value={variantId}
                  onChange={(e) => setVariantId(e.target.value)}
                  inputType="Natural Number"
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-original-price"
                  name="edit-form-original-price"
                  width="40%"
                  placeholder="Original Price*"
                  inputType="Float"
                  value={originalPrice}
                  onChange={handleOriginalPriceChange}
                  isRequired={true}
                  errorMessage={originalPriceError}
                  setErrorMessage={setOriginalPriceError}
                  maxLength={255}
                />
                <FormInputText
                  label=""
                  id="edit-form-promo-price"
                  name="edit-form-promo-price"
                  width="40%"
                  placeholder="Promo Price"
                  inputType="Float"
                  value={promoPrice}
                  onChange={(e) => setPromoPrice(e.target.value)}
                />
              </div>
              <div className="form-row">
                <FormInputText
                  label=""
                  id="edit-form-limit-qty"
                  name="edit-form-limit-qty"
                  width="40%"
                  placeholder="Limit Quantity"
                  value={limitQty}
                  onChange={(e) => setLimitQty(e.target.value)}
                  inputType="Natural Number"
                />
                <FormInputDropDown
                  label=""
                  id="edit-form-price-unit"
                  name="edit-form-price-unit"
                  width="40%"
                  options={[
                    { value: 'lb.', label: 'lb.' },
                    { value: 'kg', label: 'kg' },
                    { value: 'ltr', label: 'ltr' },
                    { value: 'ea.', label: 'ea.' },
                    { value: 'pack of 2', label: 'pack of 2' },
                    { value: 'pack of 3', label: 'pack of 3' },
                    { value: 'pack of 5', label: 'pack of 5' },
                    { value: 'pack of 6', label: 'pack of 6' },
                    { value: 'pack of 8', label: 'pack of 8' },
                    { value: 'pack of 10', label: 'pack of 10' },
                    { value: 'pack of 12', label: 'pack of 12' },
                    { value: 'pack of 16', label: 'pack of 16' },
                    { value: 'pack of 20', label: 'pack of 20' },
                    { value: 'pack of 24', label: 'pack of 24' },

                  ]}
                  value={priceUnit}
                  onChange={(e) => setPriceUnit(e.target.value)}
                />
              </div>
              <div className="form-row">
              <FormInputDatePicker
                  label=""
                  placeholder="Promo Start Date (mm/dd/yyyy)"
                  value={promoStartDate}
                  onChange={(date) => {
                    handlePromoStartDateChange(date);
                    setPromoStartDateError('');  // Clear the error when user selects a date
                  }}
                  errorMessage={promoStartDateError}  // Show validation error to the user
                />
                <FormInputDatePicker
                  label=""
                  placeholder="Promo End Date (mm/dd/yyyy)"
                  value={promoEndDate}
                  onChange={(date) => {
                    handlePromoEndDateChange(date);
                    setPromoEndDateError('');  // Clear the error when user selects a date
                  }}
                  errorMessage={promoEndDateError}  // Show validation error to the user
                />
              </div>
              <div className="form-row">
                <FormInputDropDown
                  label=""
                  id="edit-form-promo-type"
                  name="edit-form-promo-type"
                  width="40%"
                  options={[
                    { value: 'PRICE_DISCOUNT', label: '$ off on Original Price' },
                    { value: 'PERCENTAGE_DISCOUNT', label: '% off on Original Price' },
                    { value: 'BOGO', label: 'Buy 1 Get 1 Free' },
                  ]}
                  value={promoType}
                  onChange={(e) => {
                    setPromoType(e.target.value);
                    setPromoTypeError('');  // Clear the error when user selects a value
                  }}
                  placeholder="Promo Type"
                  errorMessage={promoTypeError}  // Show validation error to the user
                />
                <FormInputDropDown
                  label=""
                  id="edit-form-locations"
                  name="edit-form-locations"
                  width="40%"
                  options={[
                    { value: '1', label: 'Nationwide' },
                  ]}
                  value={locations}
                  onChange={(e) => setLocations(e.target.value)}
                />
              </div>
              
              <div className="form-row">
                <ImageUpload 
                    ref={fileInputRef} 
                    setImageFile={setImageFile} 
                    localImageUrl={localImageUrl}
                    setLocalImageUrl={setLocalImageUrl} 
                  />
                {!localImageUrl && (
                  <ImageDisplay
                    image={largeImgSrc}
                    alt=""
                  />
                )}
                </div>
              <div className="form-row">
                <FormButton className="Button" type="reset" transparent onClick={clearForm}>
                  Cancel
                </FormButton>
                <FormButton className="Button" type="submit">
                  Save
                </FormButton>
                <FormButton className="Button" type="button" backgroundColor={"red"} onClick={handleDelete}>
                  Delete
                </FormButton>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default Products;