import React from 'react';

const Logout = () => {
    // You can replace this with a more sophisticated logout process, if needed
    alert('You are now logging out.');

    // Redirect user back to the home page after logout
    window.location.href = "/";

    return null;
};

export default Logout;
