import React from 'react';

const Terms = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p className="legal-paragraph">Rhombus Labs Inc. ("Rhombus Labs", "we", "our" or "us") is the owner of the adgro.app website ("Site"). This Privacy Policy ("Policy") describes how we collect, use, disclose, and protect information from and about users of the adgro.app website (referred to as "you" or "your").</p>
      <p className="legal-paragraph bold-text">PLEASE READ THIS POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. BY USING THE ADGRO.APP WEBSITE, YOU AGREE TO THE PRACTICES DESCRIBED IN THIS POLICY.</p>
      <p className="legal-paragraph">This Policy contains the following sections:
        <ul>
          <li>Scope of this Policy</li>
          <li>The Information We Collect</li>
          <li>How We Use Your Information</li>
          <li>Legal Bases for Use of Your Information</li>
          <li>Online Analytics and Advertising</li>
          <li>How We Share and Disclose Your Information</li>
          <li>Retention of Your Information</li>
          <li>Your Rights and Choices</li>
          <li>Third-Party Links and Features</li>
          <li>International Users</li>
          <li>How We Protect Your Information</li>
          <li>Changes to Our Policy</li>
          <li>Contact Information</li>
        </ul>
      </p>
      <p className="legal-paragraph"><h3>Scope of this Policy</h3>
      This Policy applies to the adgro.app website and the information collected from users of the Site. It does not cover information collected from users of the Services provided by Rhombus Labs Inc.. or any other website or service. For information regarding the use of Rhombus’ Services, please refer to Rhombus’ Privacy Policy.</p>
      <p className="legal-paragraph"><h3>The Information We Collect</h3>
      We collect certain information from or about you when you use the adgro.app website. The types of information we collect include:
      <ul>
        <li>Information You Provide to Us:</li>
        <ul>
          <li>First and last name</li>
          <li>Business contact information (email address, phone number, postal address)</li>
          <li>Company Name</li>
          <li>Number of Store Locations</li>
          <li>Username and password associated with the Site</li>
          <li>Any other information you choose to provide to us through the Site</li>
        </ul>
        <li>Information We Collect Through Automated Means:</li>
        <ul>
          <li>When you use the adgro.app website, we and our service providers may collect certain information about your device and how you use the Site. This information may include your IP address, browser type, browser language, operating system, location (inferred from your IP address), software and hardware attributes, and your interactions with the Site.</li>
          <li>We may use cookies or similar tools to collect and analyze this information. Cookies are small data files that are stored on your browser and device. You can set your browser to notify you when you receive a cookie and to accept or refuse certain cookies. However, if you choose to refuse cookies, some functionality of the Site may be restricted. For more information, please refer to our Cookie Policy.</li>
        </ul>
      </ul></p>
      <p className="legal-paragraph"><h3>How We Use Your Information</h3>
      We use the information we collect for the following purposes:
        <ul>
          <li>To provide and maintain the adgro.app website</li>
          <li>To respond to your inquiries and requests for information</li>
          <li>To improve the Site and develop new products and services</li>
          <li>To measure the effectiveness of our advertising and deliver relevant advertising to you</li>
          <li>To suggest services that may be of interest to you</li>
          <li>To secure the Site and resolve technical issues</li>
          <li>To comply with any procedures, laws, and regulations which apply to us where it is necessary for our legitimate interests or the legitimate interests of others</li>
          <li>To establish, exercise, or defend our legal rights where it is necessary for our legitimate interests or the legitimate interests of others</li>
          <li>To aggregate and de-identify information for research and marketing purposes</li>
        </ul></p>
      <p className="legal-paragraph"><h3>Legal Bases for Use of Your Information</h3>
      The legal bases for using your information as set out in this Policy are as follows:
          <ul>
            <li>We rely on various legal bases to process your information, including the need to perform a contract with you, our legitimate interests, compliance with legal obligations, and your consent when required.</li>
            <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests</li>
            <li>Where we need to comply with a legal or regulatory obligation</li>
            <li>Where we have your consent to process your information in a certain way</li>
          </ul></p>
      <p className="legal-paragraph"><h3>Online Analytics and Advertising</h3>
      <ul>
        <li>ANALYTICS. We may use third-party web analytics services (such as those of Google Analytics) on our Site or Services to collect and analyze usage information through cookies and similar tools; engage in auditing, research, or reporting; assist with fraud prevention; and provide certain features to you. To prevent Google Analytics from using your information for analytics, you may install the Google Analytics Opt-out Browser Add-on by clicking here. If you receive email from us, we may use certain analytics tools, such as clear GIFs to capture data such as when you open our message or click on any links or banners our email contains. This data allows us to gauge the effectiveness of our communications and marketing campaigns.</li>
        <li>ONLINE ADVERTISING. In using the Site or Services, we may allow select third party advertising technology partners to place cookies or other tracking technologies on the browser of your device to collect information about you as discussed above. These third parties (e.g., ad networks and ad servers such as Twitter Ads, Bing Ads, and others) may use this information to serve relevant content and advertising to you as you browse the Internet, and access their own cookies or other tracking technologies on your browser to assist in this activity. If you are interested in more information about these online advertising activities, and how you can generally control cookies from being put on your computer to deliver tailored advertising, you may visit the Network Advertising Initiative's Consumer Opt-Out link, the Digital Advertising Alliance's Consumer Opt-Out link, or Your Online Choices to opt-out of receiving tailored advertising from companies that participate in those programs. We do not control these opt-out links or whether any particular company chooses to participate in these opt-out programs. We are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms. Please note that if you use these mechanisms, you may still see advertising on our Site or Services or across the Internet, but it will not be tailored to you based on your online behavior over time.</li>
        <li>DO NOT TRACK ("DNT"). DNT is a privacy preference that web users can set in certain web browsers. We are committed to providing you with meaningful choices about the information collected on our Services through cookies and similar technologies, and that is why we provide the variety of opt-out mechanisms listed above. However, we do not currently recognize or respond to browser-initiated DNT signals.</li>
      </ul></p>
      <p className="legal-paragraph"><h3>How We Share And Disclose Your Information</h3>
      We will share your information in the following ways:
        <ul>
          <li>Service Providers: We provide access to or share your information with select third parties who help us deliver or improve our Site and Services or perform services on our behalf, including billing and payment, advertising and marketing, analytics, fraud prevention, research, data storage or processing, security, and consulting and legal services.</li>
          <li>Affiliates and Subsidiaries: We may share the information we collect within the Rhombus family of companies for the purposes outlined in this Policy.</li>
          <li>Your Company: If you are an Authorized User, we may provide your information to the company you are engaged or employed by to fulfill and enforce our applicable sales agreement with your company, and to inform your company regarding usage, support, or training needs.</li>
          <li>Protection of Rhombus and Others: By using the Site or Services, you acknowledge and agree that we may access, retain, and disclose the information we collect and maintain about you if required to do so by applicable law or in a good faith belief that such access, retention or disclosure is reasonably necessary to: (a) comply with legal process (e.g. a subpoena or court order); (b) enforce any contracts with you; (c) respond to claims that any content violates the rights of third parties; and/or (d) protect the rights, property or personal safety of Rhombus, its agents and affiliates, its users and/or the public.</li>
          <li>Business Transactions: In accordance with applicable legal obligations, your information may be provided to third parties in connection with a merger or acquisition (including transfers made as part of insolvency or bankruptcy proceedings) involving all or part of Rhombus or our assets, or as part of a corporate reorganization or stock sale or other change in corporate control or business change, including for the purpose of determining whether to proceed or continue with such transaction or business relationship.</li>
          <li>Aggregate/De-Identified Information: From time to time, we may share Aggregate/De-identified Information about use of the Site or Services or our user base with partners and others at our discretion, but such information will not identify you personally.</li>
          <li>With Your Consent or at Your Direction: In addition to the sharing described in this Policy, we may share information about you with third parties whenever you consent to or direct such sharing.</li>
        </ul></p>
        <p className="legal-paragraph"><h3>Retention Of Your Information</h3>
        We keep your information for no longer than necessary for the purposes for which it is processed. The length of time for which we retain information depends on the purposes for which we collect and use it and/or as required to comply with applicable laws.</p>
        <p className="legal-paragraph"><h3>Your Rights And Your Choices</h3>
        You have certain rights with respect to your information as further described in this section.</p>
        <p className="legal-paragraph"><h3>Your Legal Rights</h3>
        If you would like further information in relation to your legal rights under applicable law or would like to exercise any of them, please contact us using the information in the "Contact Information" section below at any time. Your local laws (e.g., if you are a citizen or resident of the European Economic Area or California) may permit you to request that we:
        <ul>
          <li>provide access to and/or a copy of certain information we hold about you</li>
          <li>update information which is out of date or incorrect</li>
          <li>delete certain information that we are holding about you</li>
          <li>restrict the way that we process and disclose certain of your information</li>
        </ul>
        Such laws may also permit you to revoke your consent to the processing of your information for certain purposes. We will consider all requests and provide our response within the time period stated by applicable law and as otherwise required by applicable law. Please note, however, that certain information may be exempt from such requests in some circumstances, which may include if we need to keep processing your information for our legitimate interests or to comply with a legal obligation. We may request you provide us with information necessary to confirm your identity before responding to your request.</p>
        <p className="legal-paragraph"><h3>Marketing Communications</h3>
        If, in accordance with applicable legal requirements, we send you marketing communications regarding our Services or the services of third parties that we believe will be interesting to you, you can ask us to stop sending such communications at any time by contacting us using the information in the "Contact Information" section below.  In our marketing email messages, you can also opt out by following the instructions located at the bottom of such emails. Please note that, regardless of your request, we may still use and share certain information as permitted by this Policy or as required by applicable law. For example, you may not opt out of certain transactional emails from us, such as those confirming your requests or providing you with updates regarding our Policy or other terms.</p>
        <p className="legal-paragraph"><h3>Third Party Links And Features</h3>
        Our Services may contain links to third-party websites. If you choose to visit these sites and use their services, please note that we are not responsible for their content or privacy practices. The collection, use, and disclosure of your information will be subject to the privacy policies of the third-party websites, and not this Policy. We urge you to read the privacy policies of these third parties.</p>
        <p className="legal-paragraph"><h3>International Users</h3>
        Your information is maintained and processed by us and our third-party service providers in Canada, the United States, and elsewhere. Thus, your information may be maintained, processed, and stored in other jurisdictions that may have different data protection laws than those in your country of residence. In the event that your information is transferred in these ways, please note that we comply with applicable legal requirements governing the transfer of information across borders. By using the Services, you agree to and acknowledge these transfers.</p>
        <p className="legal-paragraph"><h3>How We Protect Your Information</h3>
        Rhombus takes a variety of technical and organizational security measures to protect the information provided to us from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. Please keep this in mind when disclosing any information to us online.</p>
        <p className="legal-paragraph"><h3>Privacy Information For California Users</h3>
        If you are a California resident, California law requires us to provide you with some additional information regarding how we collect, use, and share your "personal information" (as defined in the California Consumer Privacy Act ("CCPA")).</p>
        <p className="legal-paragraph"><h3>Categories of Personal Information We Collect and How We Use Them</h3>
          Throughout this Policy, we discuss in detail the specific pieces of personal information we collect from and about our users. Under the CCPA, we are also required to provide you with the "categories" of personal information we collect and how we use each category of personal information. The categories we collect are:
          <table>
            <tr>
              <th>Category of Personal Information</th>
              <th>How We Use this Personal Information</th>
            </tr>
            <tr>
              <td>
              Contact Information (such as your full name, phone number, address, email address)
              </td>
              <td>
                   <li>Provide the Services and respond to your requests</li>
                  <li>Contact you with relevant information and offers</li>
                  <li>Analyze use of and improve the services</li>
                  <li>Comply with law or defend our legal rights</li>
                  <li>Security/fraud prevention</li>
                  <li>Create aggregate/de-identified information</li>            
              </td>
            </tr>
            <tr>
              <td>
                Login Information (such as your username and password)
              </td>
              <td>
                  <li>Provide the Services and respond to your requests</li>
                  <li>Comply with law or defend our legal rights</li>
                  <li>Security/fraud prevention</li>
                  <li>Create aggregate/de-identified information</li>                
              </td>
            </tr>
            <tr>
              <td>
                Professional or Employment Information (such as the name and address of the company you work for and your title)
              </td>
              <td>
                  <li>Provide the Services and respond to your requests</li>
                  <li>Contact you with relevant information and offers</li>
                  <li>Analyze use of and improve the services</li>
                  <li>Comply with law or defend our legal rights</li>
                  <li>Security/fraud prevention</li>
                  <li>Create aggregate/de-identified information</li>              
              </td>
            </tr>
            <tr>
              <td>
                Device and Online Information (such as mobile device information, IP address, and information about your interaction with the Services)
              </td>
              <td>
                  <li>Provide the Services and respond to your requests</li>
                  <li>Contact you with relevant information and offers</li>
                  <li>Analyze use of and improve the services</li>
                  <li>Comply with law or defend our legal rights</li>
                  <li>Security/fraud prevention</li>
                  <li>Create aggregate/de-identified information</li>              
              </td>
            </tr>
            <tr>
              <td>
              Geolocation data
              </td>
              <td>
                  <li>Provide the Services and respond to your requests</li>
                  <li>Contact you with relevant information and offers</li>
                  <li>Analyze use of and improve the services</li>
                  <li>Comply with law or defend our legal rights</li>
                  <li>Security/fraud prevention</li>
                  <li>Create aggregate/de-identified information</li>              
              </td>
            </tr>
            <tr>
              <td>
              Other information (any other information you choose to provide directly to us)              
              </td>
              <td>
                  <li>Provide the Services and respond to your requests</li>
                  <li>Contact you with relevant information and offers</li>
                  <li>Analyze use of and improve the services</li>
                  <li>Comply with law or defend our legal rights</li>
                  <li>Security/fraud prevention</li>
                  <li>Create aggregate/de-identified information</li>              
              </td>
            </tr>
          </table>
          Do Not Sell Rights. Please note that the CCPA sets forth certain obligations for businesses that "sell" personal information to third parties. We do not engage in such activity as defined in the CCPA and have not engaged in such activity in the past twelve months from the Effective Date of this Policy.</p>
          <p className="legal-paragraph"><h3>Your California Privacy Rights</h3>
          The CCPA allows you to make certain requests about your personal information. Specifically, the CCPA allows you to request us to:
          <ul>
            <li>Inform you about the categories of personal information we collect or disclose about you; the categories of sources of such information; the business or commercial purpose for collecting your personal information; and the categories of third parties with whom we share/disclose personal information.</li>
            <li>Provide access to and/or a copy of certain personal information we hold about you.</li>
            <li>Delete certain personal information we have about you.</li>
            <li>Provide you with information about the financial incentives that we offer to you, if any.</li>
          </ul>
          The CCPA further provides you with the right not to be discriminated against (as provided for in applicable law) for exercising your rights. Please note that certain information may be exempt from such requests under California law. For example, we need certain information to provide the Services to you. We also will take reasonable steps to verify your identity before responding to a request. If we are unable to verify you, we shall have the right, but not the obligation, to request additional information from you.
          If you would like further information regarding your legal rights under California law or would like to exercise any of them, or if you are an authorized agent making a request on a California consumer's behalf, please contact us using the contact information provided below. If you are a Customer End User, please contact the Customer who has provided your information to us for more information.</p>
          <p className="legal-paragraph"><h3>Shine the Light Disclosure</h3>
          The California "Shine the Light" law gives residents of California the right under certain circumstances to request information from us regarding the manner in which we share certain categories of personal information (as defined in the Shine the Light law) with third parties for their direct marketing purposes. We do not share your personal information with third parties for their own direct marketing purposes.</p>
          <p className="legal-paragraph"><h3>Changes To Our Policy</h3>
          We reserve the right to amend this Policy at any time to reflect changes in the law, our data collection and use practices, or advances in technology. We will make the revised Policy accessible on the Services, so you should review the Policy periodically. You can know if the Policy has changed since the last time you reviewed it by checking the "Effective Date" at the beginning of the document. If we make a material change to the Policy, you will be provided with appropriate notice in accordance with legal requirements. By continuing to use the Services, you are confirming that you have read and understood the latest version of this Policy.</p>
          <p className="legal-paragraph"><h3>Contact Information</h3>
          Rhombus welcomes your comments and questions regarding this Policy and the collection and use of your information. If you have questions or concerns, please email us at <a href="mailto:info@rhombuslabsinc.com">info@rhombuslabsinc.com</a>.</p>
          <p className="legal-paragraph">Last Updated Date: 15 May 2023</p>
    </div>
  );
};

export default Terms;