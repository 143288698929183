import React from 'react';

const TextIcon = ({ size = 50, color = "black" }) => (
  <svg width={size} height={size}>
    <text x="50%" y="50%" textAnchor="middle" stroke={color} strokeWidth="0.1" dy=".3em" style={{fontSize: size/2}}>ABC</text>
    <line x1="15%" y1="60%" x2="85%" y2="60%" stroke={color} strokeWidth="0.1" />
  </svg>
);

export default TextIcon;
