import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import FileUpload from './stories/FileUpload';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BulkUploadProducts() {
  const [productCount, setProductCount] = useState(0);
  const { getAccessTokenSilently } = useAuth0();


  const fetchProductCount = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(process.env.REACT_APP_SERVER_DOMAIN + '/api/products/count/777', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (response.ok) {
      const data = await response.json();
      setProductCount(data.count);
    } else {
      toast.error('Failed to fetch product count', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  };

  useEffect(() => {
    fetchProductCount();
  }, [getAccessTokenSilently]);

  return (
    <div className='dashboard-body-wrapper'>
    <div className="main-column">
      <div className="div-block">
      <span className='div-block-title'>Provide File to Bulk Upload Products</span>
            <ToastContainer/>
            <FileUpload 
              fetchProductCount={fetchProductCount}
            />
      </div>
    </div>
    <div className="right-column">
      <div className='div-block'>
        <span>Pro Tip: Avoid adding new products when the POS Sync light is Green. Doing manual changes at the same time as when we are syncing with POS may lead to undesirable results.</span>
      </div>
      <div className='div-block'>
      </div>
      <div className='div-block'>
      <div className="statusMessage">Product Count: <span className='statusHighlight'>{productCount}</span></div>
          <div className="statusMessage">POS Sync: GREY</div>
          <div className="statusMessage">Team Members Online: 0</div>
      </div>
      <div className="div-block">
        <span>Use this Template for uploading product data. <br/>Download</span>                   
      </div>      
    </div>
</div>
  );
}

export default BulkUploadProducts;