import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './FormInputDatePicker.css';

const FormInputDatePicker = ({ label, placeholder, value, onChange, errorMessage, width }) => {
  const [selectedDate, setSelectedDate] = useState(value);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <div className="date-wrapper" style={{ width }}>
      <label htmlFor={label}>{label}</label>
      <DatePicker 
        placeholderText={placeholder}
        selected={selectedDate} 
        onChange={handleDateChange}
        className="datepicker"
        shouldCloseOnSelect={true}
      />
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
    </div>
  );
};

export default FormInputDatePicker;
