import React, {useEffect} from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import CellTemplateGrid from './CellTemplateGrid';

function CellTemplateMaker({ width, height, onStageClick, children, backgroundColor = "white", onBackgroundColorChange, onShapesLayerReady }) {
  

  let shapesLayer = React.createRef();  // Create a reference to the shapes layer

  useEffect(() => {
      // Pass the shapesLayer to the parent when it's ready
      if (shapesLayer.current) {
          onShapesLayerReady(shapesLayer.current);
      }
  }, [shapesLayer, onShapesLayerReady]);

  

  const handleStageMouseDown = e => {
    if (e.target === e.target.getStage()) {
      onStageClick();
    }
  };

  const handleStageRightClick = e => {
    e.evt.preventDefault();
    if (e.target === e.target.getStage()) {
      const newColor = window.prompt('Please enter a color for the base rectangle:', backgroundColor);
      if (newColor) {
        onBackgroundColorChange(newColor); // Use the function passed as a prop
      }
    }
  };
  

  return (
    <div className="cell-template-maker">
    <Stage width={width} height={height} onMouseDown={handleStageMouseDown} onTouchStart={handleStageMouseDown}  onContextMenu={handleStageRightClick}>
      <Layer>
      <Rect
          id="template-background"
          width={width}
          height={height}
          fill={backgroundColor} // set fill to the color state
          listening={false}
        />
      <CellTemplateGrid width={width} height={height} gap={8} />
      </Layer>
      <Layer ref={shapesLayer}>
        {children}
      </Layer>
    </Stage>
    </div>
  );
}

export default CellTemplateMaker;