// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "US-EN", // default language, you can change it based on user's preferred language
    fallbackLng: "US-EN", // use English if the translation isn't available
    interpolation: {
      escapeValue: false // react already escapes values
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // load translations from public/locales/[lang]/translation.json
    },
  });

export default i18n;