import React from 'react';
import HeaderBar from './stories/HeaderBar';


const Header = () => {

  return (
    <HeaderBar />
  );
};

export default Header;