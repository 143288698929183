import React, { useState, useEffect } from 'react';
import CellTemplateMaker from './components/CellTemplates/CellTemplateMaker';
import RectangleShape from './components/Shapes/Rectangle/RectangleShape';
import CircleShape from './components/Shapes/Circle/CircleShape';
import ArcShape from './components/Shapes/Arc/ArcShape';
import ArrowShape from './components/Shapes/Arrow/ArrowShape';
import ImageShape from './components/Shapes/Image/ImageShape';
import TextShape from './components/Shapes/Text/TextShape';
import LineShape from './components/Shapes/Line/LineShape';
import TagShape from './components/Shapes/Tag/TagShape';
import StarShape from './components/Shapes/Star/StarShape';
import RingShape from './components/Shapes/Ring/RingShape';
import ShapePropertyManager from './components/ShapeProperties/ShapePropertyManager';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { Card, Popconfirm, message, Tooltip, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import RectangleIcon from './components/ShapeIcons/RectangleIcon';
import CircleIcon from './components/ShapeIcons/CircleIcon.js';
import ArcIcon from './components/ShapeIcons/ArcIcon';
import ImageIcon from './components/ShapeIcons/ImageIcon';
import TextIcon from './components/ShapeIcons/TextIcon';
import StarIcon from './components/ShapeIcons/StarIcon';
import RingIcon from './components/ShapeIcons/RingIcon';
import Button from './stories/Button';
import FormButton from './stories/FormButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdTemplateManager.css';
import {useLocation, useNavigate} from 'react-router-dom';


const { TabPane } = Tabs;


function AdTemplateManager() {
  const { getAccessTokenSilently } = useAuth0();

  const [templateWidth, setTemplateWidth] = useState(400);
  const [templateHeight, setTemplateHeight] = useState(400);
  const [shapes, setShapes] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [templateData, setTemplateData] = useState([[[], null], [[], null]]);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);  // Keep track of the currently loaded template
  const [backgroundColor, setBackgroundColor] = useState('white');
  const [templates, setTemplates] = useState([]);
  const [shapesLayerRef, setShapesLayerRef] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [isShapesModalVisible, setIsShapesModalVisible] = useState(false);
  const [adDimensions, setAdDimensions] = useState('1x1');

  const location = useLocation();
  const navigate = useNavigate();

  const defaultPoints = [(templateWidth/2), (templateHeight/2), (templateWidth/4), (templateHeight/4)];

  const handleAdDimensionsChange = (event) => {
    setAdDimensions(event.target.value);

    switch(event.target.value) {
      case '1x1':
        setTemplateHeight(400);
        setTemplateWidth(400);
        break;
      case '1x1&1/2':
        setTemplateHeight(400);
        setTemplateWidth(600);
        break;  
      case '1x2':
        setTemplateHeight(400);
        setTemplateWidth(800);
        break;
      case '1x3':
        setTemplateHeight(400);
        setTemplateWidth(1200);
        break;
      case '1/2x3':
        setTemplateHeight(200);
        setTemplateWidth(1200);
        break;
      case '2x1':
        setTemplateHeight(800);
        setTemplateWidth(400);
        break;
      case '3x1':
        setTemplateHeight(1200);
        setTemplateWidth(400);
        break;
      case '4x1':
        setTemplateHeight(1600);
        setTemplateWidth(400);
        break;
      case '1&1/2x1':
        setTemplateHeight(600);
        setTemplateWidth(400);
        break;
      case '1x3/4':
        setTemplateHeight(400);
        setTemplateWidth(300);
        break;
      case '1x3/2':
        setTemplateHeight(400);
        setTemplateWidth(600);
        break;
      case '2x1&1/2':
        setTemplateHeight(800);
        setTemplateWidth(600);
        break;
      default:
        break;
    }

    setShapes([]);
    setSelectedId(null);
    setTemplateData([[[], null], [[], null]]);
    setCurrentTemplateIndex(0);
    setBackgroundColor('white');
    setShapesLayerRef(null);
    setEditing(false);
    setEditingTemplateId(null);
    setIsShapesModalVisible(false);
  };

  useEffect(() => {
    if (location.state && location.state.adTemplate) {

      const loadAdTemplate = async (templateId) => {
      // Fetch the template data
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/templates/${templateId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const templateData = JSON.parse(response.data.data);
      // Update local state
      setAdDimensions(response.data.ad_dimensions);

      switch(response.data.ad_dimensions) {
        case '1x1':
          setTemplateHeight(400);
          setTemplateWidth(400);
          break;
        case '1x1&1/2':
          setTemplateHeight(400);
          setTemplateWidth(600);
          break;  
        case '1x2':
          setTemplateHeight(400);
          setTemplateWidth(800);
          break;
        case '1x3':
          setTemplateHeight(400);
          setTemplateWidth(1200);
          break;
        case '1/2x3':
          setTemplateHeight(200);
          setTemplateWidth(1200);
          break;
        case '2x1':
          setTemplateHeight(800);
          setTemplateWidth(400);
          break;
        case '3x1':
          setTemplateHeight(1200);
          setTemplateWidth(400);
          break;
        case '4x1':
          setTemplateHeight(1600);
          setTemplateWidth(400);
          break;
        case '1&1/2x1':
          setTemplateHeight(600);
          setTemplateWidth(400);
          break;
        case '1x3/4':
          setTemplateHeight(400);
          setTemplateWidth(300);
          break;
        case '1x3/2':
          setTemplateHeight(400);
          setTemplateWidth(600);
          break;
        case '2x1&1/2':
          setTemplateHeight(800);
          setTemplateWidth(600);
          break;
        default:
          break;
      }

      setShapes(templateData);
      setEditing(true);
      setEditingTemplateId(templateId);
    };
    loadAdTemplate(location.state.adTemplate.id);
    }
  }, [location]);

  const onShapesLayerReady = (shapesLayer) => {
      setShapesLayerRef(shapesLayer);
  }

  const toggleShapesModal = () => {
    setIsShapesModalVisible(!isShapesModalVisible);
  };

  const ShapesButtonModal = () => {
    return (
      <div className="shapes-modal">
                <div className="shape-button">
                  <Button  icon={<RectangleIcon width={20} height={15  } color={"#605dba"}  />} onClick={handleAddRectangle} noBorder />
                </div>
                <div className='shape-button'>
                  <Button className="shape-button" icon={<CircleIcon size={20} color={"#605dba"} />} onClick={handleAddCircle} noBorder />
                </div>
                <div className='shape-button'>
                  <Button className="shape-button" icon={<ArcIcon size={20} color={"#605dba"}/>} onClick={handleAddArc} noBorder/>
                </div>
                <div className='shape-button'>
                  <Button className="shape-button" icon={<ImageIcon size={20} color={"#605dba"} />} onClick={handleAddImage} noBorder/>
                </div>
                <div className='shape-button'>
                  <Button className="shape-button" icon={<TextIcon size={20} color={"#605dba"} />} onClick={handleAddText} noBorder/>
                </div>
                <div className='shape-button'>
                  <Button className="shape-button" icon={<StarIcon size={20} color={"#605dba"} />} onClick={handleAddStar} noBorder/>
                </div>
                <div className='shape-button'>
                  <Button className="shape-button" icon={<RingIcon size={20} color={"#605dba"} />} onClick={handleAddRing} noBorder/>
                </div>
      </div>
    );
  };

  const fetchTemplates = async () => {
    try {
      const token = await getAccessTokenSilently();
      // Use axios to fetch templates
      const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/templates/retailer/777`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }); 
      // Set the templates state
      setTemplates(response.data);
    } catch (error) {
      console.error('Failed to fetch templates:', error);
    }
  };

  const handleTemplatePreviewImageUpload = (file) => {
    return new Promise(async (resolve, reject) => {
      const token = await getAccessTokenSilently();
     
      if (!file) {
        resolve(null);
        return;
      }
    
      const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/s3/signed-url?type=template&fileName=${encodeURIComponent(file.name)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const { data: { signedUrl } } = response;
      if (signedUrl) {
        const result = await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type
          }
        });
    
        if (result.status === 200) {
          const imageUrl = signedUrl.split("?")[0];
          resolve(imageUrl); // Resolve the promise with imageUrl
        } else {
          console.error('Error uploading file:', result);
          reject('Error uploading file.');
        }
      } else {
        console.error('Server did not return a signed URL for upload.');
        reject('Server did not return a signed URL for upload.');
      }
    });
  };

  const DeleteTemplate = async (templateId) => {
  const token = await getAccessTokenSilently();
  try {
    const response = await axios.delete(`${process.env.REACT_APP_SERVER_DOMAIN}/api/templates/${templateId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if(response.status === 200) {
        // Check if the template being deleted is the same one being edited
        toast.success('Template was successfully deleted.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 30000,
          style: {
            background: '#fff',
            color: '#605dba'
          }
        }); 
      
      if (editing && templateId === editingTemplateId) {
        setEditing(false);
        setEditingTemplateId(null);
        handleCancel();
      }
      // Fetch the templates again after the operation is done
      fetchTemplates();
    }

  }  catch (error) {
    toast.error('Error occurred while deleting the template.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      style: {
        background: '#fff',
        color: '#ff0000'
      }
    });
  }
  }

  const handleDelete = async (event) => {
  event.preventDefault();

  DeleteTemplate(editingTemplateId);
  };

  const handleSave = async(event) => {
    event.preventDefault();
    saveTemplate(event);
  }

  const handleSaveAndClose = async(event) => {
  event.preventDefault();
  saveTemplate(event).then(() => {
    handleCancel();
  }).catch(error => {
    // Handle error
    console.error('An error occurred:', error);
  });
  }

  const handleCancel = () => {
     navigate('/adtemplates');
  };


  const saveTemplate = async () => {
    setSelectedId(null);

    const token = await getAccessTokenSilently();


    // Create a temporary canvas
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = templateWidth;
    tempCanvas.height = templateHeight;
    const ctx = tempCanvas.getContext('2d');

    // Draw the white rectangle
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, templateWidth, templateHeight);

    // Draw the shapes onto the canvas
    const shapesImage = new Image();
    return new Promise((resolve, reject) => {

    shapesImage.onload = async () => {
      ctx.drawImage(shapesImage, 0, 0, templateWidth, templateHeight);

      // Generate the data URL from the canvas
      const dataUrl = tempCanvas.toDataURL();
      // Creating blob from the base64
      const response1 = await fetch(dataUrl);
      const blob = await response1.blob();
      // Convert the blob into a file
      let previewImageFile = new File([blob], "preview.png", {
        type: "image/png",
      });

      // Upload image and get the URL
      const uploadedImageUrl = await handleTemplatePreviewImageUpload(previewImageFile);
      
      const shapesData = JSON.stringify(shapes);

      try {
        const url = editing ?
      `${process.env.REACT_APP_SERVER_DOMAIN}/api/templates/${editingTemplateId}` :
      `${process.env.REACT_APP_SERVER_DOMAIN}/api/templates/`;

      const method = editing ? 'patch' : 'post';

      // Post the dataUrl to the server
      const response2 = await axios[method](url, {
        data: shapesData,
        ad_dimensions: adDimensions,
        preview_image_URL: uploadedImageUrl
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });


      if(response2.status === 200){
        
        if(editing) {
          toast.success('Template was successfully updated.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 30000,
            style: {
              background: '#fff',
              color: '#605dba'
            }
          }); 
        
        } else if(!editing) {
          toast.success('Template was successfully saved.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 30000,
            style: {
              background: '#fff',
              color: '#605dba'
            }
          });
          setEditing(true);
          setEditingTemplateId(response2.data.result.id);
        }
        fetchTemplates();
        resolve();
      }
      
      } catch (error) {
        toast.error('Error occurred while saving the template.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          style: {
            background: '#fff',
            color: '#ff0000'
          }
        });
        reject();
      }

    };
    shapesImage.onerror = () => {
      console.error("Error loading shapesImage");
      // Reject the Promise if there is an error
      reject(new Error('Error loading shapesImage'));
    };
    shapesImage.src = shapesLayerRef.toDataURL();
    });
  };

  function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
      // Return if obj is not an object or is null
      return obj;
    }
  
    let copy = Array.isArray(obj) ? [] : {};
  
    for (let key in obj) {
      copy[key] = deepCopy(obj[key]);
    }
  
    return copy;
  }

  useEffect(() => {


    fetchTemplates();
  }, [getAccessTokenSilently]); // Added getAccessTokenSilently as a dependency


  useEffect(() => {
    const newTemplates = [...templateData];
    newTemplates[currentTemplateIndex] = [deepCopy(shapes), selectedId];
    setTemplateData(newTemplates);
  }, [shapes, selectedId]);
  
  // Create a separate effect for switching templates
  useEffect(() => {
    // Don't do anything if templateData array is empty
    if (templateData[0].length === 0 && templateData[1].length === 0) return;
  
    const [newShapes, newSelectedId] = templateData[currentTemplateIndex];
    setShapes(deepCopy(newShapes));
    setSelectedId(newSelectedId);
  }, [currentTemplateIndex]);
  
  const handleShapePropertyChange = (newAttrs) => {
    setShapes(shapes.map(s => {
      return s.id === newAttrs.id ? newAttrs : s;
    }));
  };

  const selectedShape = shapes.find(s => s.id === selectedId);

  const handleStageClick = () => {
    setSelectedId(null);
  };

  const handleDeleteShape = (id) => {
    setShapes(shapes.filter(shape => shape.id !== id));
  };

  const handleMoveShapeDown = (id) => {
    const index = shapes.findIndex(shape => shape.id === id);
    if (index > 0) { // If it's not the first item
      const newShapes = [...shapes]; // Create a new copy of the array
      [newShapes[index - 1], newShapes[index]] = [newShapes[index], newShapes[index - 1]]; // Swap the elements
      setShapes(newShapes); // Set the state
    }
  };
  
  const handleMoveShapeUp = (id) => {
    const index = shapes.findIndex(shape => shape.id === id);
    if (index < shapes.length - 1) { // If it's not the last item
      const newShapes = [...shapes]; // Create a new copy of the array
      [newShapes[index], newShapes[index + 1]] = [newShapes[index + 1], newShapes[index]]; // Swap the elements
      setShapes(newShapes); // Set the state
    }
  };

  const handleAddRectangle = () => {
    const id = `rectangle${shapes.length + 1}`;
    const rectangle = { 
        type: 'rectangle',
        id, 
        x: (templateWidth/2),
        y: (templateHeight/2),
        width: 50, 
        height: 50, 
        fill: "white",
        stroke: null, // Default values for new attributes
        strokeWidth: null, 
        cornerRadius: null,
        rotation: 0,
        draggable: true
    };
    
    setShapes([...shapes, rectangle]);
    setSelectedId(id);
    toggleShapesModal();
  };

  const handleAddCircle = () => {
    const id = `circle${shapes.length + 1}`;
    const circle = {
        type: 'circle',
        id,
        x: (templateWidth/2),
        y: (templateHeight/2),
        radius: 50,
        fill: "white",
        stroke: null, // Default stroke (border) value
        strokeWidth: null, // Default strokeWidth (border thickness) value
        draggable: true
    };

    setShapes([...shapes, circle]);
    setSelectedId(id);
    toggleShapesModal();
  };

  const handleAddArc = () => {
    const id = `arc${shapes.length + 1}`;
    const arc = {
      type: 'arc',
      id,
      x: (templateWidth/2),
      y: (templateHeight/2),
      innerRadius: 40,
      outerRadius: 60,
      angle: 60,
      fill: "white",
      stroke: null, // Default stroke (border) value
      strokeWidth: null, // Default strokeWidth (border thickness) value
      cornerRadius: null,
      draggable: true
    };
    setShapes([...shapes, arc]);
    setSelectedId(id);
    toggleShapesModal();
  };

  const handleAddArrow = () => {
    const id = `arrow${shapes.length + 1}`;
    const arrow = {
        type: 'arrow',
        id,
        points: [2000, 2000, 100, 100], // Change points here for a bigger arrow
        fill: "white",
        stroke: "black",
        draggable: true
    };
    setShapes([...shapes, arrow]);
    setSelectedId(id);
  };
  
  const handleAddImage = () => {
    const id = `image${shapes.length + 1}`;
    const image = {
        type: 'image',
        id,
        x: (templateWidth/2),
        y: (templateHeight/2),
        width: 100, 
        height: 100, 
        imageUrl: "/product-image-placeholder.webp", // URL of the image you want to display
        align: 'center',
        vAlign: 'middle',
        stroke: null, // Default stroke (border) value
        strokeWidth: null, // Default strokeWidth (border thickness) value
        cornerRadius: null,
        linkedProductNumber: null,
        linkedProductProperty: null,
        draggable: true
      };
    setShapes([...shapes, image]);
    setSelectedId(id);
    toggleShapesModal();
  };

  const handleAddText = () => {
    const id = `text${shapes.length + 1}`;
    const text = {
        type: 'text',
        id,
        x: (templateWidth/2),
        y: (templateHeight/2),
        width:240,
        height:16,
        text: "Some Text Here", // The text you want to display
        fontFamily: "Arial", // default value
        fontSize: 16, // default value
        fontStyle: "normal", // default value
        textDecoration: "none", // default value
        align: "left", // default value
        verticalAlign: "top", // default value
        padding: 0, // default value
        lineHeight: 1, // default value
        wrap: "word", // default value
        ellipsis: false, // default value
        fill: "#000000", // default value
        linkedProductNumber: null,
        linkedProductProperty: null,
        draggable: true,
        shadowColor: "black",
        shadowBlur: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0
      };
  
    setShapes([...shapes, text]);
    setSelectedId(id);
    toggleShapesModal();
  };

  const handleAddLine = () => {
    const id = `line${shapes.length + 1}`;
    const line = {
      type: 'line',
      id,
      points: JSON.parse(JSON.stringify(defaultPoints)),
      stroke: "black",
      strokeWidth: 4,
      draggable: true,
      rotation: 0
    };
  
    setShapes([...shapes, line]);
    setSelectedId(id);
  };

  const handleAddTag = () => {
    const id = `tag${shapes.length + 1}`;
    const tag = {
        type: 'tag',
        id,
        x: 200,    // default x-position
        y: 200,    // default y-position
        width: 50, // default width
        height: 50,// default height
        fill: 'red',// default fill color
        stroke: null, // Default stroke (border) value
        strokeWidth: null, // Default strokeWidth (border thickness) value
        cornerRadius: null,
        draggable: true
    };

    setShapes([...shapes, tag]);
    setSelectedId(id);
  };

  const handleAddStar = () => {
  const id = `star${shapes.length + 1}`;
  const star = {
    type: 'star',
    id,
    x: (templateWidth/2),
    y: (templateHeight/2),
    numPoints: 5, // default number of points
    innerRadius: 20, // default inner radius
    outerRadius: 40, // default outer radius
    fill: 'yellow',// default fill color
    stroke: null, // Default stroke (border) value
    strokeWidth: null, // Default strokeWidth (border thickness) value
    cornerRadius: null,
    draggable: true
  };

  setShapes([...shapes, star]);
  setSelectedId(id);
  toggleShapesModal();
  };

  const handleAddRing = () => {
    const id = `ring${shapes.length + 1}`;
    const ring = {
        type: 'ring',
        id,
        x: (templateWidth/2),
        y: (templateHeight/2),
        innerRadius: 20, // default inner radius
        outerRadius: 40, // default outer radius
        fill: 'blue', // default fill color
        stroke: null, // Default stroke (border) value
        strokeWidth: null, // Default strokeWidth (border thickness) value
        draggable: true

    };

    setShapes([...shapes, ring]);
    setSelectedId(id);
    toggleShapesModal();
  };

  return (
    <div className="dashboard-body-wrapper">
      <ToastContainer />
      <div className="main-column">
        <div className="div-block">
          <div className='template-maker-canvas'>
            <div className='shape-buttons'>
            <div className="shape-property">
              <label className="shape-property-label">Ad Size</label>
              <select value={adDimensions || '1x1'} onChange={handleAdDimensionsChange} style={{width: '100px'}}>
                <option value="1x1">1x1</option>
                <option value="1x3/4">1x3/4</option>
                <option value="1x3/2">1x3/2</option>
                <option value="1x2">1x2</option>
                <option value="1x3">1x3</option>
                <option value="1/2x1">1/2x1</option>
                <option value="1/2x2">1/2x2</option>
                <option value="1/2x3">1/2x3</option>
                <option value="2x1">2x1</option>
                <option value="3x1">3x1</option>
                <option value="4x1">4x1</option>
                <option value="1x1&1/2">1&1/2x1</option>
                <option value="2x1&1/2">2x1&1/2</option>
            </select>
            </div>  
            <div className="shape-property">
              <label onClick={toggleShapesModal} className="shape-property-label" >Add Shape</label>
              <div className="shape-buttons-modal-container">
                {isShapesModalVisible && <ShapesButtonModal />} 
              </div>
              <div className="shape-property">
              <label onClick={handleAddLine} className="shape-property-label" >Add Line</label>
              </div>
              <div className="shape-property">
                <label className="shape-property-label">|</label>
              </div>
            </div>
              {selectedShape && (
              <ShapePropertyManager 
              shape={selectedShape}
              onChange={handleShapePropertyChange}
              onDelete={handleDeleteShape}
              onMoveShapeUp={handleMoveShapeUp}
              onMoveShapeDown={handleMoveShapeDown}
              />
              )} 
            </div>
            <div className='template-maker-canvas-area'>
        
            <CellTemplateMaker
              width={templateWidth}
              height={templateHeight}
              onStageClick={handleStageClick}
              rectColor={backgroundColor} // Pass rectColor as a prop
              onBackgroundColorChange={setBackgroundColor}
              saveTemplate={saveTemplate}
              onShapesLayerReady={onShapesLayerReady}
            >
              {shapes.map((shape) => {
            switch(shape.type) {
              case 'rectangle':
                return (
                  <RectangleShape 
                    key={shape.id}
                    id={shape.id}
                    x={shape.x}
                    y={shape.y}
                    width={shape.width}
                    height={shape.height}
                    fill={shape.fill}
                    draggable={shape.draggable}
                    stroke={shape.stroke} // Passed new attributes to RectangleShape
                    strokeWidth={shape.strokeWidth}
                    cornerRadius={shape.cornerRadius}
                    rotation={shape.rotation}
                    selected={selectedId === shape.id}
                    onSelect={() => setSelectedId(shape.id)}
                    onChange={(newAttrs) => {
                      setShapes(shapes.map(s => {
                        return s.id === newAttrs.id ? newAttrs : s;
                      }));
                    }}
                  />
                );
              case 'circle':
                return (
                  <CircleShape 
                    key={shape.id}
                    id={shape.id}
                    x={shape.x}
                    y={shape.y}
                    radius={shape.radius}
                    fill={shape.fill}
                    draggable={shape.draggable}
                    stroke={shape.stroke} // Passed new attributes to RectangleShape
                    strokeWidth={shape.strokeWidth}
                    selected={selectedId === shape.id}
                    onSelect={() => setSelectedId(shape.id)}
                    onChange={(newAttrs) => {
                      setShapes(shapes.map(s => {
                        return s.id === newAttrs.id ? newAttrs : s;
                      }));
                    }}     
                    />
                );
                case 'arc':
                return (
                  <ArcShape
                    key={shape.id}
                    id={shape.id}
                    x={shape.x}
                    y={shape.y}
                    innerRadius={shape.innerRadius}
                    outerRadius={shape.outerRadius}
                    angle={shape.angle}
                    fill={shape.fill}
                    draggable={shape.draggable}
                    stroke={shape.stroke} // Passed new attributes to RectangleShape
                    strokeWidth={shape.strokeWidth}
                    cornerRadius={shape.cornerRadius}
                    selected={selectedId === shape.id}
                    onSelect={() => setSelectedId(shape.id)}
                    onChange={(newAttrs) => {
                      setShapes(shapes.map(s => {
                        return s.id === newAttrs.id ? newAttrs : s;
                      }));
                    }}
                    />
                );
              case 'arrow':
                return (
                  <ArrowShape
                    key={shape.id}
                    id={shape.id}
                    points={shape.points}
                    pointerLength={shape.pointerLength}
                    pointerWidth={shape.pointerWidth}
                    fill={shape.fill}
                    draggable={shape.draggable}
                    stroke={shape.stroke}
                    selected={selectedId === shape.id}
                    onSelect={() => setSelectedId(shape.id)}
                    onChange={(newAttrs) => {
                      setShapes(shapes.map(s => {
                        return s.id === newAttrs.id ? newAttrs : s;
                      }));
                    }}
                  />
                );
                case 'image':
            return (
              <ImageShape
                key={shape.id}
                id={shape.id}
                x={shape.x}
                y={shape.y}
                width={shape.width}
                height={shape.height}
                imageUrl={shape.imageUrl}
                align={shape.align}
                vAlign={shape.vAlign}
                draggable={shape.draggable}
                stroke={shape.stroke}
                strokeWidth={shape.strokeWidth}
                cornerRadius={shape.cornerRadius}
                linkedProductNumber={shape.linkedProductNumber}
                linkedProductProperty={shape.linkedProductProperty}
                selected={selectedId === shape.id}
                onClick={() => {
                  setSelectedId(shape.id);
                  }}
                onChange={(newAttrs) => {
                  setShapes(shapes.map(s => {
                    return s.id === newAttrs.id ? newAttrs : s;
                  }));
                }}
                />
            );
            case 'text':
            return (
              <TextShape
                key={shape.id}
                id={shape.id}
                x={shape.x}
                y={shape.y}
                width={shape.width}
                height={shape.height}
                text={shape.text}
                draggable={shape.draggable}
                fontFamily={shape.fontFamily}
                fontSize={shape.fontSize}
                fontStyle={shape.fontStyle}
                textDecoration={shape.textDecoration}
                align={shape.align}
                verticalAlign={shape.verticalAlign}
                padding={shape.padding}
                lineHeight={shape.lineHeight}
                wrap={shape.wrap}
                ellipsis={shape.ellipsis}
                fill={shape.fill}
                linkedProductNumber={shape.linkedProductNumber}
                linkedProductProperty={shape.linkedProductProperty}
                selected={selectedId === shape.id}
                onSelect={() => setSelectedId(shape.id)}
                onChange={(newAttrs) => {
                  setShapes(shapes.map(s => {
                    return s.id === newAttrs.id ? newAttrs : s;
                  }));
                }}
                shadowColor={shape.shadowColor}
                shadowBlur={shape.shadowBlur}
                shadowOffsetX={shape.shadowOffsetX}
                shadowOffsetY={shape.shadowOffsetY}   
              />
            );
            case 'line':
              return (
                <LineShape 
                  key={shape.id}
                  id={shape.id}
                  points={shape.points}
                  stroke={shape.stroke}
                  draggable={shape.draggable}
                  strokeWidth={shape.strokeWidth}
                  rotation={shape.rotation}
                  selected={selectedId === shape.id}
                  onSelect={() => setSelectedId(shape.id)}
                  onChange={(newAttrs) => {
                    setShapes(shapes.map(s => {
                      return s.id === newAttrs.id ? newAttrs : s;
                    }));
                  }}
                />
              );
              case 'tag':
            return (
              <TagShape
                key={shape.id}
                id={shape.id}
                x={shape.x}
                y={shape.y}
                text={shape.text}
                fill={shape.fill}
                draggable={shape.draggable}
                stroke={shape.stroke}
                strokeWidth={shape.strokeWidth}
                pointerDirection={shape.pointerDirection}
                pointerWidth={shape.pointerWidth}
                pointerHeight={shape.pointerHeight}
                selected={selectedId === shape.id}
                onSelect={() => setSelectedId(shape.id)}
                onChange={(newAttrs) => {
                const newAttrsDeepClone = JSON.parse(JSON.stringify(newAttrs));

                setShapes(shapes.map(s => {
                  return s.id === newAttrsDeepClone.id ? newAttrsDeepClone : s;
                }));
              }}
              />
            );

              case 'star':
                    return (
                      <StarShape 
                  key={shape.id}
                  id={shape.id}
                  x={shape.x}
                  y={shape.y}
                  numPoints={shape.numPoints}
                  innerRadius={shape.innerRadius}
                  outerRadius={shape.outerRadius}
                  fill={shape.fill}
                  draggable={shape.draggable}
                  selected={shape.id === selectedId}
                  stroke={shape.stroke}
                  strokeWidth={shape.strokeWidth}
                  cornerRadius={shape.cornerRadius}
                  onSelect={() => setSelectedId(shape.id)}
                  onChange={(newAttrs) => {
                      setShapes(shapes.map(s => {
                        return s.id === newAttrs.id ? newAttrs : s;
                      }));
                    }}
              />
                    );
        case 'ring':
            return (
                <RingShape 
                    key={shape.id}
                    id={shape.id}
                    x={shape.x}
                    y={shape.y}
                    innerRadius={shape.innerRadius}
                    outerRadius={shape.outerRadius}
                    fill={shape.fill}
                    draggable={shape.draggable}
                    selected={shape.id === selectedId}
                    stroke={shape.stroke} // Passed new attributes to RectangleShape
                    strokeWidth={shape.strokeWidth}
                    cornerRadius={shape.cornerRadius}
                    onSelect={() => setSelectedId(shape.id)}
                    onChange={(newAttrs) => {
                    setShapes(shapes.map(s => {
                      return s.id === newAttrs.id ? newAttrs : s;
                    }));
                    }}
                            />
                        );
                default:
                  return null;
              }
            })}
            </CellTemplateMaker>
            </div>
          </div>
          </div>
          <div className="div-block">
            <div className="form-buttons">
              <FormButton className="Button" type="button" transparent onClick={handleCancel}>
                {editing ? 'Cancel' : 'Clear'}
              </FormButton>
              <FormButton className="Button" type="button" onClick={handleSave}>
                {editing ? 'Update' : 'Save'}
              </FormButton>
              <FormButton className="Button" type="button" onClick={handleSaveAndClose}>
                {editing ? 'Update & Close' : 'Save & Close'}
              </FormButton>
              {(editing &&
                <FormButton className="Button" type="button" backgroundColor={"red"} disabled={!editing} onClick={handleDelete}>
                Delete
              </FormButton>
              )}
              
              </div>
          </div>
        </div>
    {/*  <div className="right-column">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Custom Templates" key="1">
        <div id="template-gallery" className="template-gallery">
        {templates.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((template, index) => (
        <Card 
          key={template.id} 
          className="template-preview"
          hoverable 
          cover={template.preview_image_url && <img src={template.preview_image_url} alt="template" />}
          actions={[
            <Tooltip >
            <EditOutlined key="edit" onClick={() => handleEditClick(template.id)} />
          </Tooltip>,
            <Tooltip>
              <Popconfirm 
                title="Are you sure to delete this template?" 
                onConfirm={() => DeleteTemplate(template.id)}
                onCancel={() => message.error('Delete Cancelled')}
                okText="Yes" 
                cancelText="No"
              >
                <DeleteOutlined key="delete" />
              </Popconfirm>
            </Tooltip>
          ]}
        />
))}

    </div>
    </TabPane>
    <TabPane tab="Shared Templates" key="2">
    </TabPane>

        </Tabs>
        </div> */}
      
    </div>
  );
}

export default AdTemplateManager;