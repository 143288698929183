// FormInputCheckbox.js
import React from 'react';
import PropTypes from 'prop-types';
import './FormInputCheckbox.css';

function FormInputCheckbox({ label, checked, onChange, width, name }) {
  const handleChange = (event) => {
    onChange({
      target: {
        checked: event.target.checked,
        name: event.target.name,
      },
    });
  };

  return (
    <div className="form-input-checkbox" style={{ width }}>
      <label htmlFor={name}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleChange}
        />
        {label}
      </label>
    </div>
  );
}

FormInputCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default FormInputCheckbox;

