import React, { useState, useEffect } from 'react';
import FlyerTemplate from './components/FlyerTemplates/FlyerTemplate';
import { useAuth0 } from "@auth0/auth0-react";
import { useDrag } from 'react-dnd';
import axios from 'axios';
import { Tabs } from 'antd';
import html2canvas from 'html2canvas';
import FormButton from './stories/FormButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './FlyerTemplateManager.css';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';

const { TabPane } = Tabs;

function TemplatePreview({ template }) {
  const [{ isDragging }, drag] = useDrag({
    type: 'template',
    item: {id: template.id, adDimensions: template.ad_dimensions },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div className="ad-template-preview" ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {template.preview_image_url && <img src={template.preview_image_url} alt="flyer-template" />}
      {/* Other template data */}
    </div>
  );
}

function FlyerTemplateManager() {
  const { getAccessTokenSilently } = useAuth0();
  const [templates, setTemplates] = useState([]);
  const [grid, setGrid] = useState(Array.from({ length: 12 }, () => ({
    content: null,
    dimensions: { width: 400, height: 400 },
    mergedCells: [],
    spanWidth: 1,
    spanHeight: 1,
    templateWidth: 400,
    templateHeight: 400
  })));

  const [flyerEditing, setFlyerEditing] = useState(false);
  const [editingFlyerTemplateId, setFlyerEditingTemplateId] = useState(null);
  const [pages, setPages] = useState([Array.from({ length: 12 }, () => ({
    content: null,
    dimensions: { width: 400, height: 400 },
    mergedCells: [],
    spanWidth: 1,
    spanHeight: 1,
    templateWidth: 400,
    templateHeight: 400
    }))]); // Start with one page
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // Index of the currently displayed page
  const [isAdTemplatesSearchActive, setIsAdTemplatesSearchActive] = useState(false);
  const [gridDimensions, setGridDimensions] = useState('4x3');
  const [selectedCells, setSelectedCells] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleAdTemplatesSearch = () => {
    setIsAdTemplatesSearchActive(!isAdTemplatesSearchActive);
  };

  useEffect(() => {
    if (location.state && location.state.flyerTemplate) {
        const flyerTemplateData = JSON.parse(location.state.flyerTemplate.data);
        setGrid(flyerTemplateData[0]);
        if(flyerTemplateData[0].length > 12)
        {
          setGridDimensions('4x4')
        } else {
          setGridDimensions('4x3')
        }
        setCurrentPageIndex(0);
        setPages(flyerTemplateData);
        setFlyerEditing(true);
        setFlyerEditingTemplateId(location.state.flyerTemplate.id);
      }
    }, [location]);

  useEffect(() => {
    fetchAdTemplates();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    // Function to update the current page in the pages state
    const updateCurrentPageInPagesState = () => {
      setPages(prevPages => {
        // Create a deep copy of the pages
        const updatedPages = [...prevPages];
        // Update the grid of the current page
        updatedPages[currentPageIndex] = [...grid];  
        return updatedPages;
      });
    };
    // Call the function to update the current page in the pages state
    updateCurrentPageInPagesState();
  }, [grid, currentPageIndex]);
  
  const handleGridDimensionsChange = (event) => {
    setGridDimensions(event.target.value);
    setSelectedCells([]);
    switch(event.target.value) {
      case '4x3':
        setGrid(Array.from({ length: 12 }, () => ({
          content: null,
          dimensions: { width: 400, height: 400 },
          mergedCells: [],
          spanWidth: 1,
          spanHeight: 1,
          templateWidth: 400,
          templateHeight: 400
        })));
        break;
      case '4x4':
        setGrid(Array.from({ length: 16 }, () => ({
          content: null,
          dimensions: { width: 300, height: 400 },
          mergedCells: [],
          spanWidth: 1,
          spanHeight: 1,
          templateWidth: 300,
          templateHeight: 400
        })));
        break;
      default:
        break;
    }    
  }

  const fetchAdTemplates = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/templates/retailer/777`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }); 
      setTemplates(response.data);
    } catch (error) {
      console.error('Failed to fetch templates:', error);
    }
  };

  const handleCancel = () => {
    navigate('/flyertemplates');
  };

  const handleDelete = (event) => {
    event.preventDefault();
    DeleteFlyerTemplate(editingFlyerTemplateId);
  };

  const handleSave = (event) => {
    event.preventDefault();
    saveFlyerTemplate(event);
  }

  const handleFlyerTemplatePreviewImageUpload = (file) => {
    return new Promise(async (resolve, reject) => {
      const token = await getAccessTokenSilently();
      if (!file) {
        resolve(null);
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/s3/signed-url?type=flyer-template&fileName=${encodeURIComponent(file.name)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data: { signedUrl } } = response;
      if (signedUrl) {
        const result = await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type
          }
        });
        if (result.status === 200) {
          const imageUrl = signedUrl.split("?")[0];
          resolve(imageUrl); // Resolve the promise with imageUrl
        } else {
          console.error('Error uploading file:', result);
          reject('Error uploading file.');
        }
      } else {
        console.error('Server did not return a signed URL for upload.');
        reject('Server did not return a signed URL for upload.');
      }
    });
  };
  
  const saveFlyerTemplate = async (event) => {
    event.preventDefault();
    const token = await getAccessTokenSilently();
    return new Promise(async (resolve, reject) => {
      const flyerTemplate = document.getElementById('flyer-template'); // Make sure to assign 'grid' as an id to your grid container div
      const canvas = await html2canvas(flyerTemplate);
      const imgData = canvas.toDataURL();
      // Creating blob from the base64
      const response1 = await fetch(imgData);
      const blob = await response1.blob();
      // Convert the blob into a file
      let previewImageFile = new File([blob], "preview.png", {
        type: "image/png",
      });
      // Upload image and get the URL
      const uploadedImageUrl = await handleFlyerTemplatePreviewImageUpload(previewImageFile);
      const gridData = JSON.stringify(pages); // transform your pages data to a JSON string
      try {
        const url = flyerEditing ?
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/flyertemplates/${editingFlyerTemplateId}` :
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/flyertemplates/`;
        const method = flyerEditing ? 'patch' : 'post';
        const response2 = await axios[method](url, {
          data: gridData,
          preview_image_URL: uploadedImageUrl
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if(response2.status === 200){
          if(flyerEditing) {
            toast.success('Flyer template was successfully updated.', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 30000,
              style: {
                background: '#fff',
                color: '#605dba'
              }
            });
          } else if(!flyerEditing) {
            toast.success('Flyer template was successfully saved.', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 30000,
              style: {
                background: '#fff',
                color: '#605dba'
              }
            });
            setFlyerEditing(true);
            setFlyerEditingTemplateId(response2.data.result.id);
          }
          resolve();
        }
      } catch (error) {
        toast.error('Error occurred while saving the template.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          style: {
            background: '#fff',
            color: '#ff0000'
          }
        });
        reject(new Error('Error saving template:', error));
      }
    });
  };

  const DeleteFlyerTemplate = async (flyerTemplateId) => {
    const token = await getAccessTokenSilently();
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_DOMAIN}/api/flyertemplates/${flyerTemplateId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if(response.status === 200) {
          // Check if the template being deleted is the same one being edited
          toast.success('Template was successfully deleted.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 30000,
            style: {
              background: '#fff',
              color: '#605dba'
            }
          }); 
        
        if (flyerEditing && flyerTemplateId === editingFlyerTemplateId) {
          setFlyerEditing(false);
          setFlyerEditingTemplateId(null);
          handleCancel();
        }
        // Fetch the templates again after the operation is done
      }
    }  catch (error) {
      toast.error('Error occurred while deleting the template.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        style: {
          background: '#fff',
          color: '#ff0000'
        }
      });
    }
  }

  const areCellsAdjacent = (selectedCells, gridDimensions) => {
    const rows = parseInt(gridDimensions.split('x')[0], 10);
    const cols = parseInt(gridDimensions.split('x')[1], 10);

    // Include merged cells in the selected cells
    let allSelectedCells = [...selectedCells];
    selectedCells.forEach(cell => {
        allSelectedCells = [...allSelectedCells, ...grid[cell].mergedCells];
    });
    allSelectedCells = Array.from(new Set(allSelectedCells)); // Remove duplicates

    // Find the minimum and maximum row and column indices of the selected cells
    const minRow = Math.min(...allSelectedCells.map(cell => Math.floor(cell / cols)));
    const maxRow = Math.max(...allSelectedCells.map(cell => Math.floor(cell / cols)));
    const minCol = Math.min(...allSelectedCells.map(cell => cell % cols));
    const maxCol = Math.max(...allSelectedCells.map(cell => cell % cols));

    // Check if all cells within the bounding rectangle are selected
    for (let row = minRow; row <= maxRow; row++) {
        for (let col = minCol; col <= maxCol; col++) {
            const cellIndex = row * cols + col;
            if (!allSelectedCells.includes(cellIndex)) {
                return false;
            }
        }
    }
    return true;
  };

  const mergeSelectedCells =  () => {
    const rows = parseInt(gridDimensions.split('x')[0], 10);
    const cols = parseInt(gridDimensions.split('x')[1], 10);

    // Check if there are selected cells to merge
    if (selectedCells.length <= 1) {
        alert("Select at least two cells to merge.");
        return;
    }

    if (!areCellsAdjacent(selectedCells, gridDimensions)) {
        alert("Only adjacent cells can be merged.");
        return;
    }

    // The cell with the lowest index is considered the primary cell
    const primaryCellIndex = Math.min(...selectedCells);
    const secondaryCellIndices = selectedCells.filter(index => index !== primaryCellIndex);

    setGrid(prevGrid => {
        const newGrid = [...prevGrid];

        // Update the mergedCells array of the primary cell
        newGrid[primaryCellIndex].mergedCells = Array.from(new Set([
            ...newGrid[primaryCellIndex].mergedCells,
            ...secondaryCellIndices
        ]));

        // If one of the secondary cells is a primary cell of a merged group, add its properties to the new primary cell
        secondaryCellIndices.forEach(index => {
            if (newGrid[index].mergedCells.length > 0) {
                newGrid[primaryCellIndex].mergedCells = Array.from(new Set([
                    ...newGrid[primaryCellIndex].mergedCells,
                    ...newGrid[index].mergedCells
                ]));
            }
        });

        // Reset the dimensions and span values of the primary cell
        newGrid[primaryCellIndex].dimensions.width = 0;
        newGrid[primaryCellIndex].dimensions.height = 0;
        newGrid[primaryCellIndex].spanWidth = 0;
        newGrid[primaryCellIndex].spanHeight = 0;

        // Adjust the dimensions and span values of the primary cell
        selectedCells.forEach(index => {
            // Set the content of the secondary cells to null and their dimensions to 0
            if (index !== primaryCellIndex) {
                newGrid[index].content = null;
                newGrid[index].dimensions = { width: 0, height: 0 };
                newGrid[index].mergedCells = []; // Clear mergedCells for secondary cells
                newGrid[index].spanWidth = 0;
                newGrid[index].spanHeight = 0;
            }
        });

        // Include all merged cells of the primary cell for calculations
        const allCellsToConsider = [primaryCellIndex, ...newGrid[primaryCellIndex].mergedCells, ...secondaryCellIndices];

        // Adjust the span values of the primary cell based on the direction of merging
        const minCol = Math.min(...allCellsToConsider.map(cell => cell % cols));
        const maxCol = Math.max(...allCellsToConsider.map(cell => cell % cols));
        newGrid[primaryCellIndex].spanWidth = maxCol - minCol + 1;

        const minRow = Math.min(...allCellsToConsider.map(cell => Math.floor(cell / cols)));
        const maxRow = Math.max(...allCellsToConsider.map(cell => Math.floor(cell / cols)));
        newGrid[primaryCellIndex].spanHeight = maxRow - minRow + 1;

        newGrid[primaryCellIndex].dimensions.height = 400 * newGrid[primaryCellIndex].spanHeight;

        switch(cols) {
            case 4:
                newGrid[primaryCellIndex].dimensions.width = 300 * newGrid[primaryCellIndex].spanWidth;
                break;
            default:
                newGrid[primaryCellIndex].dimensions.width = 400 * newGrid[primaryCellIndex].spanWidth;
        }
        return newGrid;
    });

    // Clear the selected cells
    setSelectedCells([]);
  };

  return (
    <div className='dashboard-body-wrapper'>
          <div className={`main-column ${isAdTemplatesSearchActive ? 'shift-left' : ''}`}>
        <div className="div-block">
        <div className='shape-buttons'>
            <div className="shape-property">
              <label className="shape-property-label" >Page Dimensions</label>
              <select value={ gridDimensions || '4x3'} onChange={handleGridDimensionsChange} style={{width: '100px'}}>
                <option value="4x3">4x3</option>
                <option value="4x4">4x4</option>
              </select>
            </div> 
            <div className="shape-property">
            <label className="shape-property-label" onClick={mergeSelectedCells} >Merge Cells</label> 
            </div>
            </div>
          <div className="flyer-template-maker">
              <FlyerTemplate id='flyer-template' grid={grid} setGrid={setGrid} gridDimensions={gridDimensions} selectedCells={selectedCells} setSelectedCells={setSelectedCells}/>
          </div>
        </div>
        <div className="page-controls">
          <button 
              onClick={() => {
                  setCurrentPageIndex(prevIndex => {
                      const newIndex = prevIndex - 1;
                      setGrid(pages[newIndex]);
                      if(pages[newIndex].length > 12)
                      {
                        setGridDimensions('4x4')
                      } else {
                        setGridDimensions('4x3')
                      }
                      return newIndex;
                  });
                  setSelectedCells([]);
              }} 
              disabled={currentPageIndex === 0}
          >Previous
          </button>
          <button 
              onClick={() => {
                  setPages(prevPages => {
                      const newPage = (Array.from({ length: 12 }, () => ({
                      content: null,
                      dimensions: { width: 400, height: 400 },
                      mergedCells: [],
                      spanWidth: 1,
                      spanHeight: 1,
                      templateWidth: 400,
                      templateHeight: 400
                    })));
                      const newPages = [...prevPages, newPage];
                      setCurrentPageIndex(newPages.length - 1); // Set to the new page
                      setGrid(newPage);
                      setGridDimensions('4x3');
                      return newPages;
                  });
                  setSelectedCells([]);
              }}
          >Add
          </button>
          <button 
              onClick={() => {
                  setPages(prevPages => {
                      const updatedPages = [...prevPages];
                      updatedPages.splice(currentPageIndex, 1);
                      const newIndex = Math.max(currentPageIndex - 1, 0);
                      setCurrentPageIndex(newIndex);
                      setGrid(updatedPages[newIndex]);
                      if(updatedPages[newIndex].length > 12)
                      {
                        setGridDimensions('4x4')
                      } else {
                        setGridDimensions('4x3')
                      }
                      return updatedPages;
                  });
                  setSelectedCells([]);
              }} 
              disabled={pages.length <= 1}
          >Delete
          </button>
          <button 
              onClick={() => {
                  setCurrentPageIndex(prevIndex => {
                      const newIndex = prevIndex + 1;
                      setGrid(pages[newIndex]);
                      if(pages[newIndex].length > 12)
                      {
                        setGridDimensions('4x4')
                      } else {
                        setGridDimensions('4x3')
                      }
                      return newIndex;
                  });
                  setSelectedCells([]);
              }} 
              disabled={currentPageIndex === pages.length - 1}
          >Next
          </button>
          <span className="page-number">Page {currentPageIndex + 1} of {pages.length}</span>
      </div>
      <div className="div-block">
        <div className="form-buttons">
            <FormButton className="Button" onClick={handleCancel} transparent>
            {flyerEditing ? 'Cancel' : 'Cancel'}
            </FormButton>
            <FormButton className="Button" onClick={handleSave}>
            {flyerEditing ? 'Update' : 'Save'}
            </FormButton>
              {(flyerEditing &&
            <FormButton className="Button" onClick={handleDelete}  backgroundColor={"red"}>
                Delete
            </FormButton>
              )}
        </div>
      </div>       
      </div>
      <div className={`right-column ${isAdTemplatesSearchActive ? 'shift-right' : ''}`}>
        <ToastContainer />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Ad Templates" key="1">
              <div className='ad-templates'>
                {templates.map((template, index) => (
                  <TemplatePreview key={index} template={template} />
                ))}
              </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
export default FlyerTemplateManager;