import React from 'react';

const ArcIcon = ({ size = 50, color = "black" }) => (
  <svg height={size} width={size}>
    <path 
      d={`M ${size/2} ${size/2} m -${(size/2) - 3}, 0 a ${(size/2) - 3},${(size/2) - 3} 0 1,1 ${(size) - 6},0`}
      stroke={color}
      strokeWidth="3"
      fill="transparent"
      transform={`rotate(240 ${size/2} ${size/2})`}
    />
  </svg>
);

export default ArcIcon;
