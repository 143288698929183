import React, { useState, useEffect, useRef } from 'react';
import FeatureCard from './stories/FeatureCard';
import './App.css';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';


const images = [
  '/ad-images/ad-image-1.png',
  '/ad-images/ad-image-2.png',
  '/ad-images/ad-image-3.png',
  '/ad-images/ad-image-4.png',
  '/ad-images/ad-image-5.png',
  '/ad-images/ad-image-1.png',
  '/ad-images/ad-image-2.png',
  '/ad-images/ad-image-3.png',
  '/ad-images/ad-image-4.png',
  '/ad-images/ad-image-5.png'
];

const Home = () => {
    const { t, i18n } = useTranslation();

    // Redux logic here
    const country = useSelector(state => state.country); // Replace with your actual selector
    const dispatch = useDispatch();

    // Map countries to languages
    const countryLanguageMap = {
    'US': 'US-EN',
    'Canada': 'CA-EN',
    // Add more mappings as necessary
    };

    // Change language when the country changes
    useEffect(() => {
      const language = countryLanguageMap[country];
      if (language) {
        i18n.changeLanguage(language);
      }
    }, [country]);

    const ImageSlider = ({images, duration = 5}) => {
      const count = images.length;
      const keyframes = `@keyframes slide {
        0% { transform: translateX(0); }
        100% { transform: translateX(-${100/count}%) }
      }`;
    
      return (
        <div className="image-slider" style={{animationDuration: `${duration}s`}}>
          <style>{keyframes}</style>
          {images.map((img, index) => 
            <div className="image-container" key={index}>
              <img src={img} alt=""/>
            </div>
          )}
          {images.map((img, index) => 
            <div className="image-container" key={index+count}>
              <img src={img} alt=""/>
            </div>
          )}
        </div>
      );
    }
    

    return ( 
    <div>
      <main>
        <section id="hero" className="hero">
          <div className="hero-container">
            <div className="hero-wrapper">
              <h1 className="hero-heading">{t('home.hero.heading')}</h1>
              <h3 className="hero-tagline secondary-text"><span className="text-gradient text-emphasize">{t('home.hero.tagline')}</span></h3>
              <p className="hero-description">{t('home.hero.description')}</p>
            </div>
            <div className="slider-container">
                <ImageSlider images={images} duration={20} />
              </div>
          </div>
        </section>
        <section id="why-choose-us" className="section background-light">
          <div className="container">
            <div className="reasons-wrapper">
              <div className="reasons-headline">
                <h2 className="primary-text">{t('home.reasons.heading')}</h2>
                <h3 className="secondary-text">{t('home.reasons.description-1')}<span className="text-gradient text-emphasize">{t('home.reasons.product')}</span>{t('home.reasons.description-2')}</h3>
              </div>
              <div  className="w-tabs">
                <div className="tabs-menu w-tab-menu">
                  <div className='tab'>
                    <a className="card w-inline-block w-tab-link">
                      <div className="step text-gradient">{t('home.reason-1.number-text')}
                      </div>
                      <div className='card-description'>
                        <h3 className="heading-4">{t('home.reason-1.heading')}</h3>
                        <div className="body-color">{t('home.reason-1.description')}
                        </div>
                      </div>
                    </a>  
                  </div>
                  <div className='tab'>
                    <a className="card w-inline-block w-tab-link">
                      <div className="step text-gradient">{t('home.reason-2.number-text')}
                      </div>
                      <div className='card-description'>
                        <h3 className="heading-4">{t('home.reason-2.heading')}</h3>
                        <div className="body-color">{t('home.reason-2.description')}
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='tab'>
                    <a className="card w-inline-block w-tab-link">
                      <div className="step text-gradient">{t('home.reason-3.number-text')}
                      </div>
                      <div className='card-description'>
                        <h3 className="heading-4">{t('home.reason-3.heading')}</h3>
                        <div className="body-color">{t('home.reason-3.description')}
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='tab'>
                    <a className="card w-inline-block w-tab-link">
                      <div className="step text-gradient">{t('home.reason-4.number-text')}
                      </div>
                      <div className='card-description'>
                        <h3 className="heading-4">{t('home.reason-4.heading')}</h3>
                        <div className="body-color">{t('home.reason-4.description')}</div>
                      </div>
                    </a>
                  </div>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <section id="pricing" className="section background-light">
          <div className="container">
            <div className="pricing-wrapper">
              <div className="pricing-headline">
                <h2 className="primary-text">{t('home.pricing.heading')}</h2>
                <h3 className="secondary-text">{t('home.pricing.description')}</h3>
              </div>


              <div className="pricing-card card shadow cc-larger">
                <div className="pricing-card-inner">
                  <h3 className="price"><span className="price-currency">{t('home.pricing.currency')}</span>{t('home.pricing.premium-plan.price')}<span className="price-duration">{t('home.pricing.premium-plan.per')}{t('home.pricing.premium-plan.unit')}</span></h3>
                  <h4>{t('home.pricing.basic-plan.title')}</h4>
                  <div className="bullet-list">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.basic-plan.no-of-locations')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div><span className="bold-text">{t('home.pricing.basic-plan.no-of-ads')}</span></div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.basic-plan.frequency-of-updates')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.basic-plan.distribution')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.basic-plan.hours-of-support')}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing-card card shadow  cc-larger recommended-pricing-card">
                <div className="pricing-card-inner ">
                  <h3 className="price recommended-pricing"><span className="price-currency">{t('home.pricing.currency')}</span>{t('home.pricing.standard-plan.price')}<span className="price-duration">{t('home.pricing.standard-plan.per')}{t('home.pricing.standard-plan.unit')}</span></h3>
                  <h4 className="recommended-pricing">{t('home.pricing.standard-plan.title')}</h4>
                  <div className="bullet-list">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.standard-plan.no-of-locations')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div><span className="bold-text">{t('home.pricing.standard-plan.no-of-ads')}</span></div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div><span className="bold-text">{t('home.pricing.standard-plan.frequency-of-updates')}</span></div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.standard-plan.distribution')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div><span className="bold-text">{t('home.pricing.standard-plan.hours-of-support')}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing-card card shadow cc-larger">
                <div className="pricing-card-inner">
                  <h3 className="price"><span className="price-currency">{t('home.pricing.currency')}</span>{t('home.pricing.premium-plan.price')}<span className="price-duration">{t('home.pricing.premium-plan.per')}{t('home.pricing.premium-plan.unit')}</span></h3>
                  <h4>{t('home.pricing.premium-plan.title')}</h4>
                  <div className="bullet-list">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.premium-plan.no-of-locations')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div><span className="bold-text">{t('home.pricing.premium-plan.no-of-ads')}</span></div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.premium-plan.frequency-of-updates')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div><span className="bold-text">{t('home.pricing.premium-plan.auto-generate')}</span></div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.premium-plan.distribution')}</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>{t('home.pricing.premium-plan.hours-of-support')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );

}

export default Home;