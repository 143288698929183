
import React, {useContext} from 'react';
import { useDrag } from 'react-dnd';
import DragContext from '../../context/DragContext';


function ProductRow({ product, promoTypeLabels, selectedProduct, isSearchActive = false }) {
    let promoStartDate = null;
    let formattedPromoStartDate = null;
    const setIsDragging = useContext(DragContext);

  
    if (product.promo_start_date) {
      promoStartDate = new Date(product.promo_start_date);
      formattedPromoStartDate = promoStartDate.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
    }
    
    const promoTypeLabel = promoTypeLabels[product.promo_type] || product.promo_type;
  
    const [{ isDragging }, drag] = useDrag({
      type: 'product',
      item: () => {
        setIsDragging(true);
        return {
        type: 'product',
        id: product.retailer_sku_id,
        originalPrice: product.original_price,
        promoStartDate: product.promo_start_date,
        promoEndDate: product.promp_end_date,
        promoType: product.promo_type,
        retailerSkuId: product.retailer_sku_id,
        variantId: product.variant_id,
        promoPrice: product.promo_price,
        inventory: product.inventory,
        cleanedImgLargeSrc: product.cleaned_img_large_src,
        cleanedImgSmallSrc: product.cleaned_img_small_src,
        name: product.product_name,
        productDescription: product.product_description,
        category: product.category,
        subCategory1: product.sub_category_1,
        subCategory2: product.sub_category_2,
        subCategory3: product.sub_category_3,
        brand: product.brand,
        limit_qty: product.limit_qty,
        priceUnit: product.price_unit
      };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => {
        setIsDragging(false);
      },
    });
  
    return (
      <tr
        ref={drag}
        className={selectedProduct === product ? 'selected' : ''}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        {!isSearchActive && (
        <>
        <td>{product.retailer_sku_id}</td>
        <td>{product.product_name}</td>
        <td>{product.original_price}</td>
        <td>{product.promo_price}</td>   
        </>    
        )}

        {isSearchActive && (
          <td><img src={product.original_img_large_src} alt='' /></td>
        )}
      </tr>
    );
  }

  export default ProductRow;
